import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import 'quill/dist/quill.snow.css';

/**
 * Container atom encapsulates a content in a white-shaded container
 * @param {{
 *      border: 'green' | 'blue' | 'orange' | 'red';
 *      onClick: (() => void) | undefined;
 *      children: React.ReactNode;
 * }} props
 */
function Container({ border, onClick, children }) {
    const containerRef = useRef();

    /**
     * Run onClick when user clicks the Card
     * @type {(event: MouseEvent) => void}
     */
    const handleClick = useCallback(
        (event) => {
            if (containerRef.current && containerRef.current.contains(event.target) && onClick) {
                onClick();
            }
        },
        [onClick],
    );

    // Add click listeners
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    return (
        <div
            ref={containerRef}
            className={classNames('bg-white rounded-md shadow-md', {
                'cursor-pointer': !!onClick,
                'border-2': border !== undefined,
                'border-smgreen-dark': border === 'green',
                'border-smblue-dark': border === 'blue',
                'border-smorange-dark': border === 'orange',
                'border-smred-dark': border === 'red',
            })}
        >
            {children}
        </div>
    );
}

Container.propTypes = {
    border: PropTypes.oneOf(['green', 'blue', 'orange', 'red']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Container.defaultProps = {
    border: undefined,
    onClick: undefined,
};

export default Container;
