import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import useToast from '../../hooks/useToast';
import Button from '../atoms/Button';
import CardToggle from '../molecules/CardToggle';
import createEducation from '../../services/createEducation';
import deleteEducation from '../../services/deleteEducation';
import updateEducation from '../../services/updateEducation';
import EducationCreateModal from '../modals/EducationCreateModal';
import EducationDeleteModal from '../modals/EducationDeleteModal';
import EducationUpdateModal from '../modals/EducationUpdateModal';
import useModal from '../../hooks/useModal';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';
import formatDate from '../../utils/formatDate';

function EducationHistory({ session, userEducation, isEducationLoading }) {
    const queryClient = useQueryClient();
    const queryKey = [session, 'userEducation'];

    const { createToast, toastElement } = useToast();
    const { createModal, removeModal } = useModal();

    // Education Refs
    const institutionNameRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const descriptionRef = useRef();
    const degreeRef = useRef();
    const areaRef = useRef();

    const educationCreateMutation = useMutation({
        mutationFn: (edu) =>
            createEducation(
                edu.institutionName,
                edu.degree,
                edu.area,
                edu.startDate,
                edu.endDate,
                edu.description,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('education-created-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Create a new education
     */
    const handleEducationCreate = () => {
        const onAccept = () => {
            if (
                !institutionNameRef.current.value ||
                !degreeRef.current.value ||
                !areaRef.current.value ||
                !startDateRef.current.value
            ) {
                return;
            }
            educationCreateMutation.mutate({
                institutionName: institutionNameRef.current.value,
                degree: degreeRef.current.value,
                area: areaRef.current.value,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
            });

            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationCreateModal
                institutionNameRef={institutionNameRef}
                degreeRef={degreeRef}
                areaRef={areaRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true,
        );
    };

    const educationUpdateMutation = useMutation({
        mutationFn: (edu) =>
            updateEducation(
                edu.educationID,
                edu.institutionName,
                edu.degree,
                edu.area,
                edu.startDate,
                edu.endDate,
                edu.description,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('education-updated-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Update Education
     */
    const handleEducationUpdate = (educationInfoObj) => {
        const onAccept = () => {
            if (
                !institutionNameRef.current.value ||
                !degreeRef.current.value ||
                !areaRef.current.value ||
                !startDateRef.current.value
            ) {
                return;
            }

            educationUpdateMutation.mutate({
                educationID: educationInfoObj.educationID?.toString(),
                institutionName: institutionNameRef.current.value,
                degree: degreeRef.current.value,
                area: areaRef.current.value,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationUpdateModal
                institutionNameRef={institutionNameRef}
                degreeRef={degreeRef}
                areaRef={areaRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
                educationInfoObj={{
                    educationID: educationInfoObj && educationInfoObj.jobID ? educationInfoObj.jobID?.toString() : '',
                    institutionName:
                        educationInfoObj && educationInfoObj.institutionName ? educationInfoObj.institutionName : '',
                    degree: educationInfoObj && educationInfoObj.degree ? educationInfoObj.degree : '',
                    area: educationInfoObj && educationInfoObj.area ? educationInfoObj.area : '',
                    startDate: educationInfoObj && educationInfoObj.startDate ? educationInfoObj.startDate : '',
                    endDate: educationInfoObj && educationInfoObj.endDate ? educationInfoObj.endDate : '',
                    description: educationInfoObj && educationInfoObj.description ? educationInfoObj.description : '',
                }}
            />,
            true,
        );
    };

    const educationDeleteMutation = useMutation({
        mutationFn: (educationID) => deleteEducation(educationID, session, i18next.language),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('education-deleted-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Delete education
     */
    const handleEducationDelete = (eduInfo) => {
        // Delete an education
        const onAccept = () => {
            removeModal();

            if (!eduInfo || !eduInfo.educationID) return;

            educationDeleteMutation.mutate(eduInfo.educationID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationDeleteModal
                institutionName={eduInfo.institutionName}
                degree={eduInfo.degree}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
        );
    };

    return (
        <>
            <div className="w-full flex items-center">
                
                {toastElement}

                <div className="w-1/6 flex items-center justify-center">
                    <Button
                        circle
                        color="blue"
                        icon={faPlus}
                        title={i18next.t('create-new')}
                        onClick={handleEducationCreate}
                    />
                </div>
                <div className="w-5/6">
                    <Banner color="white" borderColor="green">
                        {i18next.t('education')}
                    </Banner>
                </div>
            </div>
            {userEducation != null && userEducation !== false && (
                <div className="w-full flex items-center justify-center mt-1 mb-1">
                    {isEducationLoading ? (
                        <Skeleton
                            count={2}
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            className="mb-5 h-8"
                            containerClassName="w-full h-5"
                        />
                    ) : (
                        <div className="flex flex-col w-full gap-y-3">
                            {userEducation &&
                                userEducation.map((educationInfoObj) => (
                                    <div key={educationInfoObj.educationID} className="mt-1">
                                        <CardToggle
                                            size="lg"
                                            key={educationInfoObj.educationID}
                                            title={educationInfoObj.institutionName}
                                        >
                                            <div className="flex flex-wrap">
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('education-institution')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>
                                                            {educationInfoObj.institutionName}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('education-degree')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>{educationInfoObj.degree}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('education-area')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>{educationInfoObj.area}</span>
                                                    </div>
                                                </div>
                                                {educationInfoObj.description != null &&
                                                    educationInfoObj.description !== false && (
                                                        <div className="flex itefms-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                            <div className="w-2/5">
                                                                <Title size="sm">
                                                                    {i18next.t('description')}
                                                                </Title>
                                                            </div>
                                                            <div className="w-3/5">
                                                                <span>
                                                                    {
                                                                        educationInfoObj.description
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('start-date')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>
                                                            {formatDate(
                                                                educationInfoObj.startDate,
                                                                true,
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('end-date')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>
                                                            {educationInfoObj.endDate !== false
                                                                ? formatDate(
                                                                        educationInfoObj.endDate,
                                                                        true,
                                                                    )
                                                                : i18next.t('present')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() =>
                                                            handleEducationUpdate(
                                                                educationInfoObj,
                                                            )
                                                        }
                                                    >
                                                        {i18next.t('edit')}
                                                    </Button>
                                                </div>
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="red"
                                                        icon={faTrash}
                                                        onClick={() =>
                                                            handleEducationDelete({
                                                                educationID:
                                                                    educationInfoObj.educationID,
                                                                institutionName:
                                                                    educationInfoObj.institutionName,
                                                                degree: educationInfoObj.degree,
                                                            })
                                                        }
                                                    >
                                                        {i18next.t('delete')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardToggle>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

EducationHistory.propTypes = {
    session: PropTypes.string.isRequired,
    userEducation: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.shape({
                educationID: PropTypes.number.isRequired,
                institutionName: PropTypes.string.isRequired,
                degree: PropTypes.string.isRequired,
                area: PropTypes.string.isRequired,
                startDate: PropTypes.string.isRequired,
                endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
                description: PropTypes.string,
            })
        ),
    ]).isRequired,
    isEducationLoading: PropTypes.bool.isRequired,
};

export default EducationHistory;