import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import InputFile from '../atoms/InputFile';
import calculateFileHash from '../../utils/calculateFileHash';

function FileUploader({ onFileProcessed, label, isLoading, className }) { 
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const hash = await calculateFileHash(file);
                const extension = file.name.split('.').pop();

                setSelectedFile({
                    file,
                    hash,
                    extension,
                });

                onFileProcessed({
                    file,
                    hash,
                    extension,
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Error calculating file hash: ${error.message}`);
            }
        }
    };

    return (
        <div 
            className={classNames(
                "flex flex-col gap-4 p-4 border border-gray-200 rounded-md",
                className
            )}
        >
            <InputFile 
                onChange={handleFileChange} 
                multiple={false} 
                label={label} 
            />
            {selectedFile && (
                <div className="text-sm text-gray-700">
                    File Selected: {selectedFile.file.name}
                </div>
            )}
        </div>
    );
}

FileUploader.propTypes = {
    onFileProcessed: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};

FileUploader.defaultProps = {
    isLoading: false,
    className: '',
};

export default FileUploader;