import { useQuery } from '@tanstack/react-query';
import { faPhone, faPerson, faCommentsDollar, faLanguage, faIdCard, faAddressCard, faLocationDot, faGraduationCap, faSchool, faCalendar, faUsersBetweenLines, faBuilding, faSuitcase, faListCheck, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import i18next from 'i18next';
import { Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import getProfile from '../../services/getProfile';
import getUserCompetition from '../../services/getUserCompetition';
import getUserTech from '../../services/getUserTech';
import formatDate from '../../utils/formatDate';
import Link from '../atoms/Link';
import Tag from '../atoms/Tag';
import Container from '../atoms/Container';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';
import CardToggle from '../molecules/CardToggle';
import NavbarTemplate from '../templates/NavbarTemplate';

// profile page
export default function UserProfilePage() {
    const { session } = useSession();
    const { guid } = useParams();

    const userProfileQuery = useQuery({
        queryKey: [session, 'profile', guid],
        queryFn: () => getProfile(guid, session, i18next.language),
    });

    const userTechQuery = useQuery({
        queryKey: [session, 'tech', guid],
        queryFn: () => getUserTech(guid, session, i18next.language),
    });

    const userCompetitionQuery = useQuery({
        queryKey: [session, 'competition', guid],
        queryFn: () => getUserCompetition(guid, session, i18next.language),
    });


    ChartJS.register(ArcElement, Tooltip, Legend);
    const failuresPercentage =
        userProfileQuery.data?.failures && userProfileQuery.data?.successes
            ? (
                  (userProfileQuery.data.failures)
              )
            : 0;

    const successesPercentage =
        userProfileQuery.data?.failures && userProfileQuery.data?.successes
            ? (
                  (userProfileQuery.data.successes)
              )
            : 0;

    const data = {
        labels: ['Successful attempts', 'Failed attempts'],
        datasets: [
            {
                data: [successesPercentage, failuresPercentage],
                backgroundColor: ['#36A2EB', 'red'],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            }
        },
        animation: {
            animateRotate: true,
        },
    };

    const languageData = userProfileQuery.data?.language || [];
    const englishLanguage = languageData.find(lang => lang.name === 'English');
    const englishLevel = englishLanguage?.level || null;

    const jobs = userProfileQuery.data?.jobs || null;
    const education = userProfileQuery.data?.educations || null;
    const certifications = userProfileQuery.data?.certifications || null;

    return (
        <NavbarTemplate>
            <div className="w-full flex flex-col items-center">
                {((jobs == null || jobs === false) &&
                    (education == null || education === false) &&
                        (certifications == null || certifications === false)) ? null : (
                    <div className="flex flex-col w-11/12 gap-2 mb-12">
                        <div className="flex w-full mb-5">
                            <Banner borderColor='green' color="green" isLoading={userProfileQuery.isLoading}>   
                                {i18next.t('talent-history')}
                            </Banner>
                        </div>
                        <div className="flex flex-col md:flex-row md:w-full justify-evenly md:gap-x-2 gap-y-3">
                            {jobs != null && jobs !== false && (
                                <div className="w-full md:w-4/12">
                                    <div className="flex w-full">
                                        <Banner borderColor='blue' color="blue" isLoading={userProfileQuery.isLoading}>
                                            {i18next.t('work-experience')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-col w-full gap-6">
                                        {jobs &&
                                        jobs.map((job) => (
                                            <CardToggle 
                                                key={job.company}
                                                size="md"
                                                title={`${job?.title} ${i18next.t('at')} ${job?.company}`}
                                            >
                                                <div className="flex flex-row flex-wrap w-full justify-between">
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('job-title')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faSuitcase} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>
                                                                {job.title}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('job-company')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faBuilding} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>
                                                                {job.company}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('job-country')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faLocationDot} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>{job.country}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('job-salary')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faCommentsDollar} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>{job.salary}</span>&nbsp;<span>{job.currency}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('job-type')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faListCheck} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                        {job.type === 'FULL_TIME' && (
                                                            <span>{i18next.t('type-full-time')}</span>
                                                        )}
                                                        {job.type === 'PART_TIME' && (
                                                            <span>{i18next.t('type-part-time')}</span>
                                                        )}
                                                        {job.type === 'FREELANCE' && (
                                                            <span>{i18next.t('type-freelance')}</span>
                                                        )}
                                                        {job.type === 'INTERNSHIP' && (
                                                            <span>{i18next.t('type-internship')}</span>
                                                        )}
                                                        {job.type === 'APPRENTICESHIP' && (
                                                            <span>{i18next.t('type-apprenticeship')}</span>
                                                        )}
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('start-date')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faCalendar} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>{formatDate(job.startDate, true)} - </span>
                                                            <span>
                                                                {job.endDate !== false ? (
                                                                    formatDate(job.endDate, true)
                                                                ) : (
                                                                    i18next.t('present')
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {job.description != null && job.description !== false && (
                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('description')}>
                                                            <div className="w-1/12">
                                                                <FontAwesomeIcon color='gray' icon={faAddressCard} size="lg" />
                                                            </div>
                                                            <div className="w-11/12">
                                                                <span>{job.description}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </CardToggle>
                                        ))}
                                    </div>
                                </div> 
                            )}
                            {education != null && education !== false && (
                                <div className="w-full md:w-4/12">
                                    <div className="flex w-full">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('education')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-col w-full gap-6">
                                        {education &&
                                        education.map((edu) => (
                                            <CardToggle
                                                size="md"
                                                key={edu.institutionName}
                                                title={edu.institutionName}
                                            >
                                                <div className="flex flex-row flex-wrap w-full justify-between" title={i18next.t('education-institution')}>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faSchool} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">¿
                                                            {edu.institutionName}
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('education-degree')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faGraduationCap} size="lg" />
                                                        </div>
                                                        <div className="w-11/12 text-left">
                                                            {edu.degree}
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('education-area')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faUsersBetweenLines} size="lg" />
                                                        </div>
                                                        <div className="w-11/12 text-left">
                                                            {edu.area}
                                                        </div>
                                                    </div>
                                                    {edu.description != null && edu.description !== false && (
                                                        <div className="flex itefms-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('description')}>
                                                            <div className="w-1/12">
                                                                <FontAwesomeIcon color='gray' icon={faAddressCard} size="lg" />
                                                            </div>
                                                            <div className="w-11/12">
                                                                {edu.description}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('start-date')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faCalendar} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>{formatDate(edu.startDate, true)} - </span>
                                                            <span>
                                                                {edu.endDate !== false ? (
                                                                    formatDate(edu.endDate, true)
                                                                ) : (
                                                                    i18next.t('present')
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardToggle>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {certifications != null && certifications !== false && (
                                <div className="w-full md:w-4/12">
                                    <div className="flex w-full">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('certifications')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-col w-full gap-6">
                                        {certifications &&
                                        certifications.map((certi) => (
                                            <CardToggle
                                                size="md"
                                                key={certi.name}
                                                title={certi.name}
                                            >
                                                <div className="flex flex-row flex-wrap w-full justify-between">
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('certification-name')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faGraduationCap} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>
                                                                {certi.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('certification-institution')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faBuilding} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>
                                                                {certi.institutionName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('certification-issueDate')}>
                                                        <div className="w-1/12">
                                                            <FontAwesomeIcon color='gray' icon={faCalendar} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <span>{formatDate(certi.issueDate, true)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6" title={i18next.t('certification-credentialURL')}>
                                                        <div className="w-1/12">
                                                        <FontAwesomeIcon color='gray' icon={faIdCard} size="lg" />
                                                        </div>
                                                        <div className="w-11/12">
                                                            <div className="w-full">{certi.credentialURL}</div>
                                                            {certi.credentialID != null && certi.credentialID !== false && (
                                                                <div className="w-full" title={i18next.t('certification-credentialID')}>{certi.credentialID}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardToggle>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="flex flex-row flex-wrap w-full justify-around">
                    <div className="flex w-11/12 items-center justify-center mb-6">
                        <Banner borderColor='green' color="green" isLoading={userProfileQuery.isLoading}>
                            {i18next.t('community')}
                        </Banner>
                    </div>
                    <div className="flex flex-col w-full md:w-5/12 gap-y-5">
                        <div className="w-full">                
                            <Container>
                                <div className="flex flex-col justify-center items-center">
                                    <Banner borderColor='blue' color="blue" isLoading={userProfileQuery.isLoading}>
                                        {i18next.t('personal-information')}
                                    </Banner>
                                </div>
                                {userProfileQuery.isLoading ? (
                                    <div className="w-full grid grid-cols-3 gap-3 p-5">
                                        <Skeleton circle height="100%" />
                                        <Skeleton count={3} className="mb-5 h-8" />
                                        <Skeleton count={2} className="mb-5 h-8" containerClassName="pt-14" />
                                    </div>
                                ) : (
                                    <div className="flex justify-stretch items-center py-5">
                                        <div className="flex flex-wrap w-2/12 justify-center items-center gap-y-3">
                                            <div className="h-20 min-w-20">
                                                <img
                                                    alt={userProfileQuery.data?.username}
                                                    src={`https://www.gravatar.com/avatar/${userProfileQuery.data?.gravatar}?d=retro`}
                                                    className="h-auto max-w-full rounded-full"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col flex-wrap w-10/12 p-3">
                                            <div className="flex flex-wrap w-full">
                                                {userProfileQuery.data?.username != null && userProfileQuery.data?.username !== false && (
                                                    <div className="flex w-full flex-wrap items-center" title={i18next.t('username')}>
                                                        <FontAwesomeIcon color="black" icon={faUser} size="sm" />
                                                        <div className="ml-2 font-semibold">{userProfileQuery.data?.username}</div>
                                                    </div>
                                                )}
                                                <div className="flex flex-wrap w-full">
                                                    {userProfileQuery.data?.country != null && userProfileQuery.data?.country !== false && (
                                                        <div className="flex flex-wrap items-center" title={i18next.t('country')}>
                                                            <FontAwesomeIcon color='green' icon={faLocationDot} size="sm" />
                                                            <div className="ml-2"> 
                                                                {userProfileQuery.data?.country.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                                                {userProfileQuery.data?.city ? ` (${userProfileQuery.data.city})` : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex flex-wrap w-full">
                                                    {userProfileQuery.data?.firstName != null && userProfileQuery.data?.firstName !== false && (
                                                        <div className="flex flex-wrap items-center">
                                                            <FontAwesomeIcon color='green' icon={faPerson} size="sm" />
                                                            <div className="ml-2">{userProfileQuery.data?.firstName}</div>
                                                        </div>
                                                    )}
                                                    {userProfileQuery.data?.lastName != null && userProfileQuery.data?.lastName !== false && (
                                                        <div>&nbsp;{userProfileQuery.data?.lastName}</div>
                                                    )}
                                                </div>
                                                {userProfileQuery.data?.phone != null && userProfileQuery.data?.phone !== false && (
                                                    <div className="flex w-full flex-wrap items-center" title={i18next.t('phone-number')}>
                                                        <FontAwesomeIcon color="blue" icon={faPhone} size="sm" />
                                                        <div className="ml-2">{userProfileQuery.data?.phone}</div>
                                                    </div>
                                                )}
                                                {userProfileQuery.data?.email != null && userProfileQuery.data?.email !== false && (
                                                    <div className="flex w-full flex-wrap items-center" title={i18next.t('email')}> 
                                                        <FontAwesomeIcon color="blue" icon={faEnvelope} size="sm" />
                                                        <div className="ml-2">{userProfileQuery.data?.email}</div>
                                                    </div>
                                                )}
                                                <div className="flex flex-wrap">
                                                    {userProfileQuery.data?.salary != null && userProfileQuery.data?.salary !== false && (
                                                    <div className="flex w-full flex-wrap">
                                                        <div className="flex flex-wrap items-center" title={i18next.t('salary')}>
                                                            <FontAwesomeIcon color="green" icon={faCommentsDollar} size="sm" />
                                                            <div className="ml-2">{userProfileQuery.data?.salary} {userProfileQuery.data?.currency}</div>
                                                        </div>
                                                    </div>
                                                    )}
                                                    {englishLanguage && englishLevel !== null && (
                                                        <div className="flex w-full flex-wrap">
                                                            <div className="flex flex-wrap items-center" title={i18next.t('english-level')}>
                                                                <FontAwesomeIcon icon={faLanguage} size="sm" />
                                                                <div className="ml-2">{englishLevel}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {userProfileQuery.data?.biography != null && userProfileQuery.data?.biography !== false && (
                                                    <div className="flex flex-wrap items-center"> 
                                                        <div>
                                                            <FontAwesomeIcon icon={faAddressCard} size="sm" />
                                                            <span className="ml-2">{i18next.t('biography')}</span>
                                                        </div>  
                                                        <div>{userProfileQuery.data?.biography}</div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-wrap w-full gap-3 mt-3">
                                                {userProfileQuery.data?.linkedin != null && userProfileQuery.data?.linkedin !== false && (
                                                    <div className="flex flex-wrap items-center" title="LinkedIn"> 
                                                        <Link color='blue' to={userProfileQuery.data?.linkedin}>
                                                            <FontAwesomeIcon color="blue" icon={faLinkedin} size="lg" />
                                                            <span>LinkedIn</span>
                                                        </Link>
                                                    </div>
                                                )}
                                                {userProfileQuery.data?.github != null && userProfileQuery.data?.github !== false && (
                                                    <div className="flex flex-wrap items-center" title="Github"> 
                                                        <Link color="black" to={userProfileQuery.data?.github}>
                                                            <FontAwesomeIcon icon={faGithub} size="lg" />
                                                            <span>Github</span>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Container>
                        </div>
                        {userProfileQuery.isLoading && (
                            <div className="w-full mt-5 bg-white h-36">
                                <div className="flex justify-center">
                                    <Banner borderColor='blue' color="blue" isLoading={userProfileQuery.isLoading}>
                                        loading
                                    </Banner>
                                </div>
                                <div className="flex flex-row justify-evenly gap-5 p-5">
                                            <Skeleton
                                                count={2}
                                                baseColor="#e4e4e7"
                                                highlightColor="#f4f4f5"
                                                height="100%"
                                                className="mb-5 h-8"
                                                containerClassName="w-full h-5"
                                            />
                                            <Skeleton
                                                count={2}
                                                baseColor="#e4e4e7"
                                                highlightColor="#f4f4f5"
                                                height="100%"
                                                className="mb-5 h-8"
                                                containerClassName="w-full h-5"
                                            />
                                </div>
                            </div>
                        )}
                        {userProfileQuery.data?.numPubComp != null && userProfileQuery.data?.numPubComp !== false &&
                        userProfileQuery.data?.publicScore != null && userProfileQuery.data?.publicScore !== false && (
                            <div className="w-full">
                                <Container>
                                    <div className="flex justify-center">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('participation-in-competition')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-row justify-evenly p-5">
                                        {((userProfileQuery.data?.numPubComp != null && userProfileQuery.data?.numPubComp !== false) ||
                                        (userProfileQuery.data?.publicScore != null && userProfileQuery.data?.publicScore !== false)) && (
                                            <div className="w-1/4">
                                                {userProfileQuery.data?.numPubComp != null && userProfileQuery.data?.numPubComp !== false && (
                                                    <div>
                                                        <Title size="lg">{i18next.t('public-competitions')}</Title>
                                                        <Tag color="blue" size="md" isLoading={userProfileQuery.isLoading}>
                                                            {userProfileQuery.data?.numPubComp || 0}
                                                        </Tag>
                                                    </div>
                                                )}
                                                {userProfileQuery.data?.publicScore != null && userProfileQuery.data?.publicScore !== false && (
                                                    <div className="">
                                                        <Title size="lg">{i18next.t('points')}</Title>
                                                        <Tag color="green" size="md" isLoading={userProfileQuery.isLoading}>
                                                            {userProfileQuery.data?.publicScore || 0}
                                                        </Tag>
                                                    </div>
                                                )}
                                            </div>
                                        )}    
                                        {((userProfileQuery.data?.numPrivComp != null && userProfileQuery.data?.numPrivComp !== false) ||
                                        (userProfileQuery.data?.privateScore != null && userProfileQuery.data?.privateScore !== false)) && (
                                            <div className="w-1/4">
                                                {userProfileQuery.data?.numPrivComp != null && userProfileQuery.data?.numPrivComp !== false && (
                                                    <div>
                                                        <Title size="lg">{i18next.t('private-competitions')}</Title>
                                                        <Tag color="blue" size="md" isLoading={userProfileQuery.isLoading}>
                                                            {userProfileQuery.data?.numPrivComp || 0}
                                                        </Tag>
                                                    </div>   
                                                )}
                                                {userProfileQuery.data?.privateScore != null && userProfileQuery.data?.privateScore !== false && (
                                                    <div>
                                                        <Title size="lg">{i18next.t('points')}</Title>
                                                        <Tag color="green" size="md" isLoading={userProfileQuery.isLoading}>
                                                            {userProfileQuery.data?.privateScore || 0}
                                                        </Tag>
                                                    </div>
                                                )}
                                            </div>
                                        )}    
                                    </div>
                                </Container>
                            </div>
                        )}
                        {userProfileQuery.data?.failures != null && userProfileQuery.data?.successes != null && (
                        userProfileQuery.data.failures || userProfileQuery.data.successes ? (
                            <div className="w-full mb-5">
                                <Container>
                                    <div className="flex flex-col">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('statistics')}
                                        </Banner>
                                        <div className="flex-col w-full text-center p-2">
                                            {i18next.t('proportion-of-successful-challenges')}
                                        </div>
                                    </div>
                                        {userProfileQuery.data?.failures && userProfileQuery.data?.successes && (
                                            <div className="flex flex-row justify-evenly items-center p-5">
                                                <div className="w-1/4">
                                                    <Doughnut data={data} options={options} />
                                                </div>
                                                <div className="flex flex-col w-1/4 items-center">
                                                    <div className="flex flex-col justify-center items-center text-center gap-y-1">
                                                        <Title size="md">
                                                            {i18next.t('failed-attempts')}
                                                        </Title>
                                                        <Tag color="red" size="md">
                                                            {(
                                                                (userProfileQuery.data.failures /
                                                                    (userProfileQuery.data.failures +
                                                                        userProfileQuery.data.successes)) *
                                                                100
                                                            ).toFixed(2)}
                                                            %
                                                        </Tag>
                                                        <Title size="md">
                                                            {i18next.t('successful-attempts')}
                                                        </Title>
                                                        <Tag color="green" size="md">
                                                            {(
                                                                (userProfileQuery.data.successes /
                                                                    (userProfileQuery.data.failures +
                                                                        userProfileQuery.data.successes)) *
                                                                100
                                                            ).toFixed(2)}
                                                            %
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </Container>
                            </div>
                        ) : null )}
                    </div>
                    <div className="flex flex-col w-full md:w-5/12 gap-y-5">
                        {((userProfileQuery.data?.registrationDate != null && userProfileQuery.data?.registrationDate !== false) ||
                        (userProfileQuery.data?.lastLoginDate != null && userProfileQuery.data?.lastLoginDate !== false) ||
                        (userProfileQuery.data?.lastChallengeName != null && userProfileQuery.data?.lastChallengeName !== false)) && (  
                            <div className="w-full">
                                <Container>
                                    <div className="flex flex-col justify-center items-center">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('activity')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-wrap justify-around gap-6 p-6 text-center">
                                        {userProfileQuery.data?.registrationDate != null && userProfileQuery.data?.registrationDate !== false && (
                                            <div className="flex flex-col">
                                                <span>{formatDate(userProfileQuery.data?.registrationDate)}</span>
                                                <Title size="md">
                                                    {i18next.t('register-date')}
                                                </Title>
                                            </div>
                                        )}
                                        {userProfileQuery.data?.lastLoginDate != null && userProfileQuery.data?.lastLoginDate !== false && (    
                                            <div className="flex flex-col">
                                                <span>{formatDate(userProfileQuery.data?.lastLoginDate)}</span>
                                                <Title size="md">
                                                    {i18next.t('last-visit')}
                                                </Title>
                                            </div>
                                        )}
                                        {userProfileQuery.data?.lastChallengeName != null && userProfileQuery.data?.lastChallengeName !== false && (    
                                            <div className="flex flex-col">
                                                <span>{userProfileQuery.data?.lastChallengeName}</span>
                                                <Title size="md">
                                                    {i18next.t('last-challenge-solved')}
                                                </Title>
                                            </div>
                                        )}    
                                    </div>
                                </Container>
                            </div>
                        )}

                        {((userProfileQuery.data?.dislikes != null && userProfileQuery.data?.dislikes !== false) ||
                        (userProfileQuery.data?.solutions != null && userProfileQuery.data?.solutions !== false) ||
                        (userProfileQuery.data?.likes != null && userProfileQuery.data?.likes !== false)) && (                                  
                            <div className="w-full">
                                <Container>
                                    <div className="flex justify-center items-center">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('community-contributions')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-wrap justify-around gap-5 p-5 pt-7 text-center">
                                        {userProfileQuery.data?.likes != null && userProfileQuery.data?.likes !== false && (
                                            <div className="flex flex-col items-center">
                                                <Tag color="green" size="md" isLoading={userProfileQuery.isLoading}>
                                                    {userProfileQuery.data?.likes || 0}
                                                </Tag>
                                                <Title size="md">{i18next.t('likes').toUpperCase()}</Title>
                                            </div>
                                        )}  
                                        {userProfileQuery.data?.solutions != null && userProfileQuery.data?.solutions !== false && (
                                            <div className="flex flex-col items-center">
                                                <Tag color="blue" size="md" isLoading={userProfileQuery.isLoading}>
                                                    {userProfileQuery.data?.solutions || 0}
                                                </Tag>
                                                <Title size="md">
                                                    {i18next.t('solutions').toUpperCase()}
                                                </Title>
                                            </div>
                                        )}
                                        {userProfileQuery.data?.dislikes != null && userProfileQuery.data?.dislikes !== false && (  
                                            <div className="flex flex-col items-center">
                                                <Tag color="red" size="md" isLoading={userProfileQuery.isLoading}>
                                                    {userProfileQuery.data?.dislikes || 0}
                                                </Tag>
                                                <Title size="md">
                                                    {i18next.t('dislikes').toUpperCase()}
                                                </Title>
                                            </div>
                                        )}
                                    </div>
                                </Container>
                            </div>
                        )}
                        {userTechQuery.isLoading && (
                            <div className="w-full bg-white h-64">
                                <div className="flex justify-center">
                                    <Banner borderColor='blue' color="blue" isLoading={userProfileQuery.isLoading}>
                                        loading
                                    </Banner>
                                </div>
                                <div className="flex flex-row justify-evenly gap-5 p-5">
                                            <Skeleton
                                                count={5}
                                                baseColor="#e4e4e7"
                                                highlightColor="#f4f4f5"
                                                height="100%"
                                                className="mb-5 h-8"
                                                containerClassName="w-1/2 h-5"
                                            />
                                            <Skeleton
                                                count={5}
                                                baseColor="#e4e4e7"
                                                highlightColor="#f4f4f5"
                                                height="100%"
                                                className="mb-5 h-8"
                                                containerClassName="w-1/2 h-5"
                                            />
                                </div>
                            </div>
                        )}
                        {userTechQuery.data?.topTechs != null && userTechQuery.data?.topTechs !== false && (
                            <div className="w-full">
                                <Container>
                                    <div className="flex justify-center items-center mb-5">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('top-5-technologies')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-row justify-center py-2">
                                        <div className="flex flex-col items-center w-2/5">
                                            <div className="flex flex-col items-center">
                                                {userTechQuery.data?.topTechs !== null &&
                                                    userTechQuery.data?.topTechs !== false && (
                                                        userTechQuery.data.topTechs && (
                                                        <div className="flex flex-col mb-4 gap-y-8">
                                                            {userTechQuery.data.topTechs.map((topTechsObj) => (
                                                                <div
                                                                    key={topTechsObj.categoryName}
                                                                >
                                                                    <Title size="md">
                                                                        {topTechsObj.categoryName}
                                                                    </Title>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center w-2/5">
                                            <div className="flex flex-col items-center">
                                                <div className="flex flex-col mb-4 gap-y-8">
                                                    {userTechQuery.data.topTechs.map((topTechsObj) => (
                                                        <div key={topTechsObj.categoryName}>
                                                            {topTechsObj.totalScore} pts
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}    
                    </div>
                </div>
                {((userCompetitionQuery.data?.pubCompInfo == null || userCompetitionQuery.data?.pubCompInfo === false) &&
                (userCompetitionQuery.data?.privCompInfo == null || userCompetitionQuery.data?.privCompInfo === false)) ? null : (
                    <div className="flex flex-col w-11/12 gap-2 mb-4">
                        <div className="w-full flex items-center justify-center mt-8 mb-6">
                            <Banner 
                                borderColor='green' 
                                isLoading={userCompetitionQuery.isLoading} 
                                color="green"
                            >
                                {i18next.t('competitions-list')}
                            </Banner>
                        </div>
                        <div className="flex flex-col md:flex-row md:w-full md:gap-x-2">
                            {userCompetitionQuery.data?.pubCompInfo != null && userCompetitionQuery.data?.pubCompInfo !== false && (
                                <div className="w-full md:w-1/2">
                                    <div className="flex w-full mb-5">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('public-competitions-list')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-col w-full gap-6">
                                        {userCompetitionQuery.data?.pubCompInfo &&
                                            userCompetitionQuery.data?.pubCompInfo.map((pubCompInfoObj) => (
                                                <CardToggle
                                                    size="md"
                                                    key={pubCompInfoObj.competitionName}
                                                    title={pubCompInfoObj.competitionName}
                                                >
                                                    <div className="flex flex-row flex-wrap w-full justify-between p-6 gap-6">
                                                        <div className="flex flex-col">
                                                            <Title size="md">
                                                                {i18next.t('success-challenges')}
                                                            </Title>
                                                            <span className="text-black">
                                                                {pubCompInfoObj.successChallenges}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <Title size="md">
                                                                {i18next.t('total-challenges')}
                                                            </Title>
                                                            <span className="text-black">
                                                                {pubCompInfoObj.totalChallenges}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <Title size="md">
                                                                {i18next.t('total-score')}
                                                            </Title>
                                                            <span className="text-black">
                                                                {pubCompInfoObj.totalScore}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardToggle>
                                            ))}
                                    </div>
                                </div>
                            )}
                            {userCompetitionQuery.data?.privCompInfo != null && userCompetitionQuery.data?.privCompInfo !== false && (
                                <div className="w-full md:w-1/2">
                                    <div className="flex w-full mb-5">
                                        <Banner borderColor='blue' color="blue">
                                            {i18next.t('private-competitions-list')}
                                        </Banner>
                                    </div>
                                    <div className="flex flex-col w-full gap-6">
                                        {userCompetitionQuery.data?.privCompInfo &&
                                        userCompetitionQuery.data?.privCompInfo.map((privCompInfoObj) => (
                                            <CardToggle
                                                size="md"
                                                key={privCompInfoObj.competitionName}
                                                title={privCompInfoObj.competitionName}
                                            >
                                                <div className="flex flex-row flex-wrap w-full justify-between p-6 gap-6">
                                                    <div className="flex flex-col">
                                                        <Title size="md">
                                                            {i18next.t('success-challenges')}
                                                        </Title>
                                                        <span className="text-black">
                                                            {privCompInfoObj.successChallenges}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Title size="md">
                                                            {i18next.t('total-challenges')}
                                                        </Title>
                                                        <span className="text-black">
                                                            {privCompInfoObj.totalChallenges}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Title size="md">
                                                            {i18next.t('total-score')}
                                                        </Title>
                                                        <span className="text-black">
                                                            {privCompInfoObj.totalScore}
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardToggle>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {userTechQuery.data?.techStats != null && userTechQuery.data?.techStats !== false && (
                    <div className="flex flex-col w-11/12">
                        <div className="w-full mb-5">
                            <Container>
                                <div className="flex justify-center items-center">
                                    <Banner borderColor='blue' color="blue">
                                        {i18next.t('technologies-with-solved-challenges-summary')}
                                    </Banner>
                                </div>
                                <div className="flex flex-wrap flex-row py-3">
                                    {userTechQuery.data?.techStats &&
                                        userTechQuery.data?.techStats.map((techStatsObj) => (
                                            <div
                                                key={techStatsObj.categoryName}
                                                className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/6 p-4"
                                            >
                                                <div className="flex flex-col bg-white rounded-md shadow-md p-6 text-center">
                                                    <h2 className="text-md font-semibold mb-4">
                                                        {techStatsObj.categoryName}
                                                    </h2>
                                                    <p title={i18next.t('summary-description-title')}>
                                                        {techStatsObj.successChallenges} /{' '}
                                                        {techStatsObj.totalChallenges}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Container>
                        </div>
                    </div>
                )}
            </div>
        </NavbarTemplate>
    );
}
