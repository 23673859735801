/* eslint-disable no-nested-ternary */
import {
    faFileLines,
    faIdCard,
    faShieldHalved,
    faUser,
    faUserCircle,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams, Outlet } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import getUser from '../../services/getUser';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import NavbarTemplate from '../templates/NavbarTemplate';
import useCountriesQuery from '../../hooks/useCountriesQuery';

function ProfilePage() {
    const { competition } = useParams();
    const { session } = useSession();
    const { removeFeedback } = useFeedback();
    const navigate = useNavigate();

    const [indexPage, setIndexPage] = useState(competition ? 1 : 0);
    const [profile, setProfile] = useState(null);

    // Get user data
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
        staleTime: 60 * 60 * 1000,
        cacheTime: 60 * 60 * 2 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            if (data.pending) {
                navigate('/profile/security');
            }
        },
    });

    const countriesQuery = useCountriesQuery();

    useEffect(() => {
        if (userQuery.data) {
            setProfile((prevProfile) => ({
                ...prevProfile,
                ...userQuery.data,
            }));
        }
    }, [userQuery.data]);

    // Redirect to the correct page
    useEffect(() => {
        if (indexPage === 0) {
            navigate('/profile/about');
        } else if (indexPage === 1) {
            navigate('/profile/security');
        } else if (indexPage === 2) {
            navigate('/profile/professional');
        } else if (indexPage === 3) {
            navigate('/profile/history');
        }
    }, [indexPage, navigate]);

    /**
     * Change the showed page
     * @param {number} page
     */
    const changePage = (page) => {
        setIndexPage(page);
        removeFeedback();
    };

    // Handle session
    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    return (
        <NavbarTemplate>
            <div className="w-full sm:px-20 flex divide-x divide-zinc-300">
                <div className="w-1/3 flex flex-col gap-7 pr-10">
                    <div className="flex flex-col gap-5 items-center">
                        <FontAwesomeIcon icon={faUserCircle} size="6x" className="text-smgray-dark" />
                        <div className="w-full flex flex-col items-center gap-1">
                            <Title isLoading={userQuery.isLoading} size="md">
                                {profile?.username || ''}
                            </Title>
                            <Title isLoading={userQuery.isLoading} size="sm">
                                {profile?.email || ''}
                            </Title>
                        </div>
                    </div>

                    <div className="w-full flex flex-col gap-3">
                        <Button
                            isLoading={userQuery.isLoading}
                            icon={faIdCard}
                            color="white"
                            onClick={() => navigate(`/profile/details/${profile?.guid}`)}
                        >
                            {i18next.t('my-profile')}
                        </Button>
                        <Button
                            icon={faUser}
                            color={classNames(indexPage === 0 ? 'orange' : 'white')}
                            onClick={() => changePage(0)}
                        >
                            {i18next.t('about-you')}
                        </Button>
                        <Button
                            icon={faShieldHalved}
                            color={classNames(indexPage === 1 ? 'orange' : 'white')}
                            onClick={() => changePage(1)}
                        >
                            {i18next.t('security')}
                        </Button>
                        {profile?.roles.includes('talent') && (
                            <>
                                <Button
                                    icon={faUserTie}
                                    color={classNames(indexPage === 2 ? 'orange' : 'white')}
                                    onClick={() => changePage(2)}
                                >
                                    {i18next.t('professional-profile')}
                                </Button>
                                <Button
                                    icon={faFileLines}
                                    color={classNames(indexPage === 3 ? 'green' : 'white')}
                                    onClick={() => changePage(3)}
                                >
                                    {i18next.t('professional-history')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="w-2/3 flex flex-col gap-5 pl-10">
                    <Outlet 
                        context={{ 
                            profile,
                            isLoading: userQuery.isLoading,
                            countries: countriesQuery.data || {},
                            areCountriesLoading: countriesQuery.isLoading,
                        }} 
                    />
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default ProfilePage;
