import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';
import i18next from 'i18next';
import Banner from '../atoms/Banner';
import useSession from '../../hooks/useSession';
import getCertifications from '../../services/getCertifications';
import getEducation from '../../services/getEducation';
import getJobs from '../../services/getJobs';
import JobsHistory from './JobsHistory';
import EducationHistory from './EducationHistory';
import CertificationHistory from './CertificationHistory';
import Spinner from '../atoms/Spinner';

function ProfileProfessionalHistory() {

    const { session } = useSession();
    const { profile: parentProfile, isLoading, countries, areCountriesLoading } = useOutletContext();

    const [profile] = useState({
        country: parentProfile?.country || 0,
    });

    const userJobsQuery = useQuery({
        queryKey: [session, 'userJobs'],
        enabled: !!session,
        queryFn: () => getJobs(session, i18next.language),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
    });

    const userEducationQuery = useQuery({
        queryKey: [session, 'userEducation'],
        enabled: !!session,
        queryFn: () => getEducation(session, i18next.language),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
    });

    const userCertificationsQuery = useQuery({
        queryKey: [session, 'userCertifications'],
        enabled: !!session,
        queryFn: () => getCertifications(session, i18next.language),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
    });

    const isHistoryDataLoading =
        userJobsQuery.isLoading || 
        userEducationQuery.isLoading || 
        userCertificationsQuery.isLoading;

    return (
        <div className="w-full flex flex-col gap-y-2">
            <Spinner isLoading={isHistoryDataLoading} message={i18next.t('loading')} type="info" />
            <div className="w-full flex items-center justify-center mb-1">
                <Banner color="green" borderColor="green">
                    {i18next.t('talent-history')}
                </Banner>
            </div>
            <JobsHistory
                session={session}
                userJobs={userJobsQuery.data || false}
                countries={countries}
                userCountry={profile.country?.toString()}
                areCountriesLoading={areCountriesLoading || isLoading}
                areJobsLoading={userJobsQuery.isLoading}
            />
            <EducationHistory
                session={session}
                userEducation={userEducationQuery.data || false}
                isEducationLoading={userEducationQuery.isLoading}
            />
            <CertificationHistory
                session={session}
                userCertifications={userCertificationsQuery.data || false}
                areCertificationsLoading={userCertificationsQuery.isLoading}
            />
        </div>
    );

}

export default ProfileProfessionalHistory;