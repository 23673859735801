// JobUpdateModal.jsx

import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Select from '../atoms/Select';
import Title from '../atoms/Title';

/**
 * JobUpdateModal allows the user to update a job
 * @param {{
*      titleRef: React.RefObject<HTMLInputElement>;
*      selectedJobTypeRef: React.RefObject<string>;
*      companyRef: React.RefObject<HTMLInputElement>;
*      startDateRef: React.RefObject<HTMLInputElement>;
*      endDateRef: React.RefObject<HTMLInputElement>;
*      descriptionRef: React.RefObject<HTMLTextAreaElement>;
*      selectedCurrencyRef: React.RefObject<HTMLInputElement>;
*      salaryRef: React.RefObject<HTMLInputElement>;
*      selectedCountryRef: React.RefObject<string>;
*      countries: object;
*      onAccept: () => void;
*      onCancel: () => void;
* }} props
*/
function JobUpdateModal({ 
    titleRef, 
    selectedJobTypeRef, 
    companyRef,
    selectedCountryRef, 
    startDateRef, 
    endDateRef, 
    descriptionRef,
    selectedCurrencyRef, 
    salaryRef,
    jobsInfoObj,
    countries,
    onAccept, 
    onCancel 
}) {
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedJobType, setSelectedJobType] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    
    // Country
    useEffect(() => {
        setSelectedCountry(String(jobsInfoObj.country));
    }, [jobsInfoObj.country]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        selectedCountryRef.current = selectedCountry;
    }, [selectedCountry, selectedCountryRef]);

    // Job Type
    useEffect(() => {
        setSelectedJobType(jobsInfoObj.type);
    }, [jobsInfoObj.type]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        selectedJobTypeRef.current = selectedJobType;
    }, [selectedJobType, selectedJobTypeRef]);

    // Job Currency
    useEffect(() => {
        setSelectedCurrency(jobsInfoObj.currency);
    }, [jobsInfoObj.currency]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        selectedCurrencyRef.current = selectedCurrency;
    }, [selectedCurrency, selectedCurrencyRef]);


    const validate = () => {

        const validationErrors = [];

        if (!titleRef.current.value) {
            validationErrors.push(i18next.t('job-title'));
        }
        if (!selectedJobTypeRef.current) {
            validationErrors.push(i18next.t('job-type'));
        }
        if (!companyRef.current.value) {
            validationErrors.push(i18next.t('job-company'));
        }
        if (!startDateRef.current.value) {
            validationErrors.push(i18next.t('start-date'));
        }

        if (titleRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-title'));
            return false;
        }

        if (companyRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-company'));
            return false;
        }

        if (descriptionRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };
    
    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('job-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('job-title')}</Title>
                <Input
                    inputRef={titleRef}
                    color="gray"
                    type="text"
                    defaultValue={jobsInfoObj.title}
                    placeholder={i18next.t('job-ex-title')}
                    />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('job-type')}</Title>
                <Select
                    color="gray"
                    selected={selectedJobType || 'FULL_TIME'}
                    onSelect={(selected) => { setSelectedJobType(selected); }}
                    options={{
                        [null]: {
                            FULL_TIME: i18next.t('type-full-time'),
                            PART_TIME: i18next.t('type-part-time'),
                            FREELANCE: i18next.t('type-freelance'),
                            INTERNSHIP: i18next.t('type-internship'),
                            APPRENTICESHIP: i18next.t('type-apprenticeship'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('job-company')}</Title>
                <Input
                    inputRef={companyRef}
                    color="gray"
                    type="text"
                    defaultValue={jobsInfoObj.company}
                    placeholder={i18next.t('job-ex-company')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('country')}</Title>
                <Select
                    color="gray"
                    isLoading={false}
                    selected={selectedCountry} 
                    onSelect={(selected) => { setSelectedCountry(selected); }}
                    options={countries}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('job-salary')}</Title>
                <div className="w-full flex">
                    <div className="w-1/3">
                        <Input
                            inputRef={salaryRef}
                            color="gray"
                            type="number"
                            defaultValue={jobsInfoObj.salary}
                        />
                    </div>
                    <div className="w-2/3 ml-2">
                        <Select
                            color="gray"
                            selected={selectedCurrency || 'COP'}
                            onSelect={(selected) => { setSelectedCurrency(selected); }}
                            options={{
                                [null]: {
                                    COP: i18next.t('currency-colombia'),
                                    USD: i18next.t('currency-usa'),
                                    EUR: "Euro",
                                    ARS: i18next.t('currency-argentina'),
                                    CLP: i18next.t('currency-chile'),
                                    PEN: i18next.t('currency-peru'),
                                    BRL: i18next.t('currency-brazil'),
                                    GBP: i18next.t('currency-uk'),
                                    CAD: i18next.t('currency-canada'),
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('start-date')}</Title>
                <Input
                    inputRef={startDateRef}
                    color="gray"
                    type="month"
                    defaultValue={jobsInfoObj.startDate}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('end-date')}</Title>
                <Input
                    inputRef={endDateRef}
                    color="gray"
                    type="month"
                    defaultValue={jobsInfoObj.endDate}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <Input
                    inputRef={descriptionRef}
                    color="gray"
                    type="textarea"
                    defaultValue={jobsInfoObj.description}
                    placeholder={i18next.t('job-description')}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

JobUpdateModal.propTypes = {
    titleRef: PropTypes.shape().isRequired,
    selectedJobTypeRef: PropTypes.shape().isRequired,
    companyRef: PropTypes.shape().isRequired,
    selectedCountryRef: PropTypes.shape().isRequired,
    startDateRef: PropTypes.shape().isRequired,
    endDateRef: PropTypes.shape().isRequired,
    descriptionRef: PropTypes.shape().isRequired,
    selectedCurrencyRef: PropTypes.shape().isRequired,
    salaryRef: PropTypes.shape().isRequired,
    countries: PropTypes.shape().isRequired,
    jobsInfoObj: PropTypes.shape({
        jobID: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        company: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        salary: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default JobUpdateModal;