import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as NavLink } from 'react-router-dom';

/**
 * Link atom creates an internal or external link depending on the destination
 * @param {{
 *      color: 'white' | 'black' | 'blue';
 *      alt: string | undefined;
 *      to: string;
 *      children: string;
 * }} props
 */
function Link({ color, to, alt, children }) {
    // Check if is an external link
    const isExternal = /^(https?:\/\/)/.test(to);

    const linkClasses = classNames(
        'min-w-fit w-min text-sm hover:underline text-center',
        {
            'text-white': color === 'white',
            'text-blue-600': color === 'blue',
        }
    );

    if (isExternal) {
        return (
            <a
                title={alt}
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                className={linkClasses}
            >
                {children}
            </a>
        );
    }

    return (
        <NavLink title={alt} to={to} className={linkClasses}>
            {children}
        </NavLink>
    );
}

Link.propTypes = {
    color: PropTypes.oneOf(['white', 'black', 'blue']).isRequired,
    alt: PropTypes.string,
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

Link.defaultProps = {
    alt: undefined,
};

export default Link;
