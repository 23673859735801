import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import brandOrange from '../../images/brandOrange.png';
import { ReactComponent as Facebook } from '../../images/facebook.svg';
import { ReactComponent as Instagram } from '../../images/instagram.svg';
import { ReactComponent as Linkedin } from '../../images/linkedin.svg';
import { ReactComponent as Telegram } from '../../images/telegram.svg';
import { ReactComponent as Twitter } from '../../images/twitter.svg';
import { ReactComponent as Youtube } from '../../images/youtube.svg';
import { useDomainSettings } from '../../contexts/DomainSettingsContext';

function Footer() {

    const { settings } = useDomainSettings();

    const { primaryColor } = settings;

    return (
        <footer className="w-full lg:px-20 px-7 py-20 flex xl:flex-row flex-col gap-16 items-center bg-white">
            <div className="w-96 flex flex-col gap-7 justify-center items-center">
                <img alt="smartbrand" src={brandOrange} className="h-56" />
                <div className="flex gap-7">
                    <a href="https://www.youtube.com/channel/UCSWEvPGAXMDH-tm9OHTu5_g" target="_blank" rel="noreferrer">
                        <Youtube style={{ fill: '#FF0000' }} className="h-7" />
                    </a>
                    <a href="https://t.me/smartalentit" target="_blank" rel="noreferrer">
                        <Telegram style={{ fill: '#0088CC' }} className="h-7" />
                    </a>
                    <a href="https://www.linkedin.com/company/smartalent-it" target="_blank" rel="noreferrer">
                        <Linkedin style={{ fill: '#0077B5' }} className="h-7" />
                    </a>
                    <a href="https://www.instagram.com/smartalentit/?hl=es-la" target="_blank" rel="noreferrer">
                        <Instagram style={{ fill: '#E4405F' }} className="h-7" />
                    </a>
                    <a href="https://www.facebook.com/smartalentit/" target="_blank" rel="noreferrer">
                        <Facebook style={{ fill: '#1877F2' }} className="h-7" />
                    </a>
                    <a href="https://twitter.com/smartalentit?lang=es" target="_blank" rel="noreferrer">
                        <Twitter style={{ fill: '#1DA1F2' }} className="h-7" />
                    </a>
                </div>
            </div>

            <div className="flex lg:flex-row flex-col gap-16">
                <div className="w-full flex flex-col gap-7">
                    <div className="flex flex-col gap-5">
                        <h1 className="text-xl font-bold">{i18next.t('apply-to-vacancies')}</h1>
                        <p>{i18next.t('apply-to-vacancies-text')}</p>
                    </div>
                    <a
                        className="flex justify-between items-center"
                        href="https://job-seekers.smartalentit.com/jobs/Careers"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <h2 className="text-lg font-semibold">{i18next.t('what-waiting-for-apply')}</h2>
                        <div 
                            className="py-1 px-5 rounded-full"
                            style={{ backgroundColor: primaryColor }}
                        >
                            <FontAwesomeIcon icon={faArrowRight} size="lg" className="text-white" />
                        </div>
                    </a>
                </div>

                <div className="w-full flex flex-col gap-7">
                    <div className="flex flex-col gap-3">
                        <h1 className="text-xl font-bold">{i18next.t('visit-our-page')}</h1>
                        <a className="hover:underline" href="https://smartalentit.com" target="_blank" rel="noreferrer">
                            smartalentit.com
                        </a>
                    </div>

                    <div className="flex gap-7">
                        <div className="flex flex-col gap-3">
                            <h1 className="text-xl font-bold">{i18next.t('i-am-a-talent')}</h1>
                            <div className="flex flex-col gap-1">
                                <a
                                    className="hover:underline"
                                    href="https://wa.me/+573225164187"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    +57 322 516 4187
                                </a>
                                <a
                                    className="hover:underline"
                                    href="mailto:info@smartalentit.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    info@smartalentit.com
                                </a>
                                <a
                                    className="hover:underline"
                                    href="mailto:info@smartranks.co"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    info@smartranks.co
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <h1 className="text-xl font-bold">{i18next.t('i-am-a-company')}</h1>
                            <div className="flex flex-col gap-1">
                                <a
                                    className="hover:underline"
                                    href="https://wa.me/+573148618537"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    +57 314 861 8537
                                </a>
                                <a
                                    className="hover:underline"
                                    href="mailto:sales@smartalentit.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    sales@smartalentit.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
