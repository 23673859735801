import { useQuery } from '@tanstack/react-query';
import getCountries from '../services/getCountries';

function useCountriesQuery() {
    return useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
            const cachedCountries = localStorage.getItem('countries');
            if (cachedCountries) {
                return JSON.parse(cachedCountries);
            }
            const countries = await getCountries();
            localStorage.setItem('countries', JSON.stringify(countries));
            return countries;
        },
        staleTime: 24 * 60 * 60 * 1000,
        cacheTime: 7 * 24 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
}

export default useCountriesQuery;