import {
    faCheck,
    faEye,
    faForward,
    faHeart,
    faInfinity,
    faPaperPlane,
    faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import getChallenge from '../../services/getChallenge';
import getChallenges from '../../services/getChallenges';
import validateInnovationAnswer from '../../services/validateInnovationAnswer';
import isMarkdown from '../../utils/isMarkdown';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import Container from '../atoms/Container';
import Highlight from '../atoms/Highlight';
import Image from '../atoms/Image';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Markdown from '../atoms/Markdown';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Spinner from '../atoms/Spinner';

/**
 * ChallengeInnovation organism creates the view to solve a challenge with reduced functionalities
 */
function ChallengeInnovation() {
    const { visibility, competition, challenge } = useParams();
    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const navigate = useNavigate();
    const firstLoad = useRef(true);
    const queryClient = useQueryClient();
    const [inputAnswers, setInputAnswers] = useState('');
    const [checkAnswers, setCheckAnswers] = useState([]);
    const [isSending, setIsSending] = useState(false);

    // Get challenge view
    const challengeQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges', challenge],
        queryFn: () => getChallenge(competition, challenge, session, i18next.language),
    });

    // Get challenge list
    const challengesQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges'],
        queryFn: () => getChallenges(competition, session, i18next.language),
    });

    // Validate user answer
    const validateMutation = useMutation({
        mutationFn: (answer) => validateInnovationAnswer(competition, challenge, answer, session, i18next.language),
        onMutate: () => {
            setIsSending(true);
            removeFeedback();
        },
        onError: (error) => {
            setIsSending(false);
            createFeedback('error', error.messages);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries([session, 'competitions', competition, 'challenges']).then(() => {
                setIsSending(false);
                const { attempts, maxAttempts } = challengeQuery.data;
                if (data.valid === 0) {
                    createFeedback('error', i18next.t('sorry-wrong-answer'));
                } else if (data.valid === 1) {
                    createFeedback('success', i18next.t('challenge-solved'));
                } else if (maxAttempts !== -1 && attempts + 1 >= maxAttempts) {
                    createFeedback('message', `${i18next.t('answer-sent')}. ${i18next.t('no-attempts-left')}`);
                } else {
                    createFeedback('message', i18next.t('answer-sent'));
                }
            });
        },
    });

    // Load answers and status only on first load
    useEffect(() => {
        if (challengeQuery.data?.answerType === 'text' || challengeQuery.data?.answerType === 'open') {
            setInputAnswers(challengeQuery.data.answers[0].data || '');
        } else if (challengeQuery.data?.answerType === 'unique' || challengeQuery.data?.answerType === 'multiple') {
            setCheckAnswers(
                challengeQuery.data.answers.map((answer) => ({
                    key: answer.guid,
                    checked: answer.checked,
                    value: isMarkdown(answer.data) ? (
                        <Markdown>{answer.data}</Markdown>
                    ) : (
                        <Highlight>{answer.data}</Highlight>
                    ),
                })),
            );
        }

        if (firstLoad.current && challengeQuery.data) {
            const { solved, attempts, maxAttempts } = challengeQuery.data;
            if (solved) {
                createFeedback('message', i18next.t('challenge-already-solved'));
            } else if (maxAttempts !== -1 && attempts >= maxAttempts) {
                createFeedback('message', i18next.t('no-more-attempts'));
            }

            firstLoad.current = false;
        }
    }, [challengeQuery.data, createFeedback]);

    // Reset values on change challenge
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            removeFeedback();
            firstLoad.current = true;
            setInputAnswers('');
            setCheckAnswers([]);
        };
    }, [removeFeedback, competition, challenge]);

    /**
     * Run validate with the structured answer
     * @param {React.FormEvent} e
     */
    const handleSubmitAnswer = (e) => {
        e.preventDefault();

        const type = challengeQuery.data.answerType;
        const answers = [...challengeQuery.data.answers];

        if (type === 'text' || type === 'open') {
            if (!inputAnswers) {
                createFeedback('warn', i18next.t('not-defined-an-answer'));
                return;
            }

            answers[0].data = inputAnswers;
        } else if (type === 'unique' || type === 'multiple') {
            if (checkAnswers.filter((checkbox) => checkbox.checked).length === 0) {
                createFeedback('warn', i18next.t('not-defined-an-answer'));
                return;
            }

            checkAnswers.forEach((checkbox) => {
                const index = answers.findIndex((answer) => answer.guid === checkbox.key);
                answers[index].checked = checkbox.checked;
            });
        }

        validateMutation.mutate(answers);
    };

    /**
     * Go to the next challenge
     */
    const handleNextChallenge = () => {
        const currentIndex = challengesQuery.data.findIndex((ichallenge) => ichallenge.guid === challenge);
        const nextIndex = (currentIndex + 1) % challengesQuery.data.length;
        navigate(`/competitions/${visibility}/${competition}/challenges/${challengesQuery.data[nextIndex].guid}`);
    };

    // Challenge error
    if (challengeQuery.error) {
        createFeedback(challengeQuery.error.context, challengeQuery.error.messages, true);
        return <Navigate to={`/competitions/${visibility}`} />;
    }

    // Choose answer type
    let nextChallenge = challengesQuery.isLoading;
    if (challengeQuery.data) {
        if (challengeQuery.data.solved === null) {
            nextChallenge = challengeQuery.data.sent;
        } else {
            nextChallenge = challengeQuery.data.solved;
        }
    }

    // Choose answer form
    let answerForm;
    if (challengeQuery.isLoading) {
        answerForm = (
            <Container>
                <div className="w-full p-5">
                    <Checkbox isLoading color="green" unique values={[]} onCheck={() => {}} />
                </div>
            </Container>
        );
    } else if (
        challengeQuery.data?.solved ||
        (challengeQuery.data?.maxAttempts !== -1 && challengeQuery.data?.attempts >= challengeQuery.data?.maxAttempts)
    ) {
        answerForm = null;
    } else if (challengeQuery.data?.answerType === 'text') {
        answerForm = (
            <Input
                color="white"
                type="text"
                focus
                placeholder={i18next.t('type-your-answer')}
                value={inputAnswers}
                onType={(value) => setInputAnswers(value)}
            />
        );
    } else if (challengeQuery.data?.answerType === 'open') {
        answerForm = (
            <Input
                color="white"
                type="textarea"
                focus
                placeholder={i18next.t('type-your-answer')}
                value={inputAnswers}
                onType={(value) => setInputAnswers(value)}
            />
        );
    } else if (challengeQuery.data?.answerType === 'unique' || challengeQuery.data?.answerType === 'multiple') {
        answerForm = (
            <Container>
                <div className="w-full p-5">
                    <Checkbox
                        useKeys
                        color="green"
                        unique={challengeQuery.data.answerType === 'unique'}
                        values={checkAnswers}
                        onCheck={(values) => setCheckAnswers(values)}
                    />
                </div>
            </Container>
        );
    }

    return (
        <div className="w-full flex flex-col gap-10">
            <Spinner isLoading={isSending} />
            <div className="w-full flex flex-col gap-5">
                <div className="w-full flex gap-5 items-center">
                    <Title size="xl" isLoading={challengeQuery.isLoading}>
                        {challengeQuery.data?.name || ''}
                    </Title>
                </div>

                {challengeQuery.data && (
                    <div className="w-full flex flex-wrap gap-5 items-center select-none">
                        {challengeQuery.data.solved && (
                            <div className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faCheck} className="text-smgreen-dark" />
                                <p className="font-semibold">{i18next.t('solved')}</p>
                            </div>
                        )}

                        {challengeQuery.data.sent && (
                            <div className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faPaperPlane} className="text-smblue-dark" />
                                <p className="font-semibold">{i18next.t('sent')}</p>
                            </div>
                        )}

                        <div
                            title={i18next.t('attempts-remaining')}
                            className="min-w-fit w-min flex gap-2 items-center"
                        >
                            <FontAwesomeIcon icon={faHeart} className="text-smred-dark" />
                            {challengeQuery.data.maxAttempts === -1 ? (
                                <FontAwesomeIcon icon={faInfinity} />
                            ) : (
                                <p className="font-semibold">
                                    {challengeQuery.data.attempts} / {challengeQuery.data.maxAttempts}
                                </p>
                            )}
                        </div>

                        {challengeQuery.data.score && (
                            <div title={i18next.t('score')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faStar} className="text-smyellow-dark" />
                                <p className="font-semibold">{challengeQuery.data.score} pts</p>
                            </div>
                        )}

                        {challengeQuery.data.views !== null && (
                            <div title={i18next.t('views')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faEye} className="text-smgray-dark" />
                                <p className="font-semibold">{challengeQuery.data.views}</p>
                            </div>
                        )}
                    </div>
                )}
                <Container>
                    {challengeQuery.isLoading ? (
                        <div className="w-full p-5">
                            <Skeleton width={300} className="mb-5" />
                            <Skeleton count={3} className="mb-2" />
                            <Skeleton width={500} />
                        </div>
                    ) : (
                        <div className="w-full p-5 flex flex-col gap-5">
                            {isMarkdown(challengeQuery.data.description) ? (
                                <Markdown>{challengeQuery.data.description}</Markdown>
                            ) : (
                                <Highlight>{challengeQuery.data.description}</Highlight>
                            )}
                            {challengeQuery.data.clarifications !== null && challengeQuery.data.clarifications.trim() && (
                                <div className='mt-5'>
                                    <Tag color="green" size="lg">{i18next.t('clarifications')}</Tag>
                                    <div className="mt-2 p-2 bg-lime-50 rounded">
                                        {isMarkdown(challengeQuery.data.clarifications) ? (
                                            <Markdown>{challengeQuery.data.clarifications}</Markdown>
                                        ) : (
                                            <Highlight>{challengeQuery.data.clarifications}</Highlight>
                                        )}
                                    </div>
                                </div>
                            )}
                            {challengeQuery.data.attachments.length > 0 && (
                                <div className="w-1/3 flex flex-col gap-2">
                                    {challengeQuery.data.attachments.map((attachment) => {
                                        const key = `attachment:${attachment.name}`;
                                        if (/(png|jpg|jpeg|gif)/.test(attachment.extension)) {
                                            return <Image key={key} alt={attachment.name} src={attachment.path} />;
                                        }

                                        return (
                                            <Link key={key} color="black" to={attachment.path}>
                                                {`${attachment.name}.${attachment.extension}`}
                                            </Link>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </Container>
                <div className="w-full flex flex-col gap-5">
                    {answerForm}
                    {feedbackElement}

                    <div className="w-full flex justify-between items-center">
                        <div className="flex gap-3 items-center">
                            {answerForm && (
                                <Button
                                    isLoading={
                                        challengeQuery.isFetching ||
                                        challengesQuery.isFetching ||
                                        validateMutation.isLoading
                                    }
                                    color={nextChallenge ? 'white' : 'green'}
                                    icon={faPaperPlane}
                                    onClick={handleSubmitAnswer}
                                >
                                    {i18next.t('send-answer')}
                                </Button>
                            )}

                            {nextChallenge && (
                                <Button
                                    isLoading={challengesQuery.isLoading}
                                    color="green"
                                    icon={faForward}
                                    onClick={handleNextChallenge}
                                >
                                    {i18next.t('next-challenge')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChallengeInnovation;