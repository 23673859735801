/**
 * getCompanyDetails gets the company details for a specific user in a competition
 * @method GET
 * @param {string} competitionGUID
 * @param {string} userGUID
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      email: string;
*      userName: string;
*      guid: string;
*      company: {
*          idType: string;
*          idNumber: string;
*          tradename: string;
*          url: string;
*          linkedIn: string;
*          address: string;
*          phone: string;
*          year: number;
*          area: string;
*          size: string;
*          country: string;
*          city: string;
*      };
*      model: {
*          description: string;
*          difference: string;
*          creator: boolean;
*          product: string;
*          patent: {
*              name: string;
*              description: string;
*              link: string;
*          };
*          incomePrevious: number;
*          incomeLast: number;
*          level: string;
*          cagr: number;
*          cac: number;
*          ltv: number;
*          churnRate: number;
*          clients: number;
*          time: number;
*          profit: number;
*      };
*      document: {
*          commercialRegistry: {
*              name: string;
*              description: string;
*              link: string;
*          };
*          representativeID: {
*              name: string;
*              description: string;
*              link: string;
*          };
*          formalizedCountry: string;
*      };
*      representative: {
*          name: string;
*          idNumber: string;
*          phone: string;
*          email: string;
*          role: string;
*          country: string;
*          city: string;
*          linkedIn: string;
*      };
*      team: {
*          founder: string;
*          fullTeam: string;
*          workTeam: string;
*          teamInfo: {
*              name: string;
*              description: string;
*              link: string;
*          };
*      };
*      answers: {
*          question: string;
*          answer: string;
*      }[];
* }>}
*/
export default async function getCompanyDetails(competitionGUID, userGUID, jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${competitionGUID}/innovation/${userGUID}`;
       const method = 'GET';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'Authorization': jwt,
       };

       const response = await fetch(url, { method, headers });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}