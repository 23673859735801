import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

/**
 * Form template positions any content in the center of the screen
 * @param {{ children: React.ReactNode, maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'full' }} props
 */
function FormTemplate({ children, maxWidth }) {
    const containerClasses = classNames(
        'w-full m-auto flex flex-col gap-2',
        {
            'max-w-[600px]': !maxWidth || maxWidth === 'md', // Default retrocompatible behavior
            'max-w-[400px]': maxWidth === 'sm',
            'max-w-[800px]': maxWidth === 'lg',
            'max-w-[1000px]': maxWidth === 'xl',
            'max-w-full': maxWidth === 'full',
        }
    );

    return (
        <>
            <div className="min-h-screen flex flex-col">
                <Navbar />
                <div className={containerClasses}>{children}</div>
            </div>
            <Footer />
        </>
    );
}

FormTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
};

FormTemplate.defaultProps = {
    maxWidth: undefined,
};

export default FormTemplate;