/**
 * getCompanyDataHome gets the relevant data from companies to show at the company home page
 * @method POST
 * @param {string} language
 * @returns {Promise<{
 *      competition: number,
 *      companies: number,
 *      prizes: string[],
 *      }[];
 * }>}
 */
export default async function getCompanyDataHome(competition, companies, prizes, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/domain/home/company`;
        const method = 'POST';
        const body = { competition, companies, prizes };
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
        };

        const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
