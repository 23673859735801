import classNames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/**
 * Banner atom creates a stylized banner for section titles
 * @param {{
 *      color: 'white' | 'green' | 'blue' | 'orange' | 'red';
 *      borderColor: 'white' | 'green' | 'blue' | 'orange' | 'red';
 *      isLoading: boolean;
 *      children: string;
 * }} props
 */

function Banner({ color, borderColor, isLoading, children }) {
    return (
        <div
            className={classNames(
                'py-2 px-4 mx-auto text-center select-none',
                'font-semibold text-lg shadow-md rounded-md',
                {
                    'text-black bg-white': color === 'white',
                    'text-black bg-smgreen': color === 'green',
                    'text-black bg-smblue': color === 'blue',
                    'text-black bg-smorange': color === 'orange',
                    'text-black bg-smred': color === 'red',
                },
                {
                    'border-1 border-white': borderColor === 'white',
                    'border-1 border-smgreen-dark': borderColor === 'green',
                    'border-1 border-smblue-dark': borderColor === 'blue',
                    'border-1 border-smorange-dark': borderColor === 'orange',
                    'border-1 border-smred-dark': borderColor === 'red',
                },
            )}
            style={{ width: '100%' }}
        >
            {isLoading ? (
                <Skeleton
                    baseColor="#e4e4e7"
                    highlightColor="#f4f4f5"
                    height="100%"
                    style={{ width: '100%' }}
                    containerClassName={classNames('flex flex-shrink-0')}
                />
            ) : (
                <h2 className="text-lg font-semibold">{children}</h2>
            )}
        </div>
    );
}

Banner.propTypes = {
    color: PropTypes.oneOf(['white', 'green', 'blue', 'orange', 'red']),
    borderColor: PropTypes.oneOf(['white', 'green', 'blue', 'orange', 'red']).isRequired,
    isLoading: PropTypes.bool,
    children: PropTypes.string.isRequired,
};

Banner.defaultProps = {
    color: undefined,
    isLoading: false,
};

export default Banner;
