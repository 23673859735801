/**
 * registerCompany creates a company user with all required details
 * @method POST
 * @param {string} email - The email address of the company user.
 * @param {string} password - The account password (minimum length enforced by backend).
 * @param {string} tradename - The tradename of the company.
 * @param {string} nit - The company's identification number.
 * @param {string} url - The public URL of the company (website).
 * @param {string} linkedin - The company's LinkedIn URL.
 * @param {string} address - The company's address.
 * @param {string} phone - The company's phone number.
 * @param {string} year - The year the company was founded or formalized.
 * @param {string} area - The area/sector of the company.
 * @param {string|number} country - The country identifier for the company.
 * @param {string} size - The company size category (e.g., '1-10', '10-50', etc.).
 * 
 * @param {string} representativeName - The representative's full name.
 * @param {string|number} representativeIdentificationNumber - The representative's ID number.
 * @param {string} representativePhone - The representative's phone number.
 * @param {string} representativeEmail - The representative's email address.
 * @param {string} role - The representative's role in the company.
 * @param {string|number} representativeCountry - The country associated with the representative.
 * @param {string} representativeCity - The city associated with the representative.
 * @param {string} representativeLinkedIn - The representative's LinkedIn URL.
 * 
 * @param {string} language - The language code for localization (e.g., 'en', 'es').
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function registerCompany(
   email, 
   password, 
   tradename, 
   nit, 
   url, 
   linkedin, 
   address, 
   phone, 
   year, 
   area, 
   country,
   city,
   size,
   representativeName,
   representativeIdentificationNumber,
   representativePhone,
   representativeEmail,
   role,
   representativeCountry,
   representativeCity,
   representativeLinkedIn,
   language
) {
  try {
       const apiUrl = `${process.env.REACT_APP_APP_URL}/api/v1/user/company`;
       const method = 'POST';
       const body = {
           email, 
           password, 
           tradename, 
           nit, 
           url, 
           linkedin, 
           address, 
           phone, 
           year, 
           area, 
           country,
           city,
           size,
           representativeName,
           representativeIdentificationNumber,
           representativePhone,
           representativeEmail,
           role,
           representativeCountry,
           representativeCity,
           representativeLinkedIn
       };

       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'User-Agent': window.navigator.userAgent,
       };

       const response = await fetch(apiUrl, { method, headers, body: JSON.stringify(body) });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}