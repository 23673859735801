import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import innovationPrograms from '../../images/innovationPrograms.png';
import prizes from '../../images/prizes.png';
import registeredButtonImage from '../../images/registeredButtonImage.png';
import registeredCompanies from '../../images/registeredCompanies.png';
import getCompanyDataHome from '../../services/getCompanyDataHome';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

function CompanyHomePage() {
    // Get company data home
    const dataQuery = useQuery({
        queryKey: ['companyData'],
        queryFn: () => getCompanyDataHome(i18next.language),
    });

    // const activeUsersCount = dataQuery.data?.activeUsers || 0;

    return (
        <div className="w-full">
            <Navbar />
            <div className="w-full flex lg:flex-row-reverse flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10">
                <img alt="banner" src={registeredCompanies} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('registered-companies-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('registered-companies-description')}</p>
                    <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                        {dataQuery.isLoading ? (
                            <Skeleton width={150} height={40} className="bg-gray-200" />
                        ) : (
                            <>
                                {/* <p className="text-5xl font-bold">{activeUsersCount.toLocaleString()}</p> */}
                                <p className="text-5xl font-bold">23</p>
                                <p className="font-semibold my-auto">{i18next.t('registered-companies-count')}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full flex lg:flex-row flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10 bg-white">
                <img alt="banner" src={innovationPrograms} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('number-of-programs-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('number-of-programs-description')}</p>

                    <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                        {dataQuery.isLoading ? (
                            <Skeleton width={150} height={40} className="bg-gray-200" />
                        ) : (
                            <>
                                {/* <p className="text-5xl font-bold">{activeUsersCount.toLocaleString()}</p> */}
                                <p className="text-5xl font-bold">+68</p>
                                <p className="font-semibold my-auto">{i18next.t('number-of-programs-count')}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full flex lg:flex-row-reverse flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10">
                <img alt="banner" src={prizes} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('list-of-prizes-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('list-of-prizes-description')}</p>
                    <div className="flex md:flex-row flex-col gap-10 justify-between md:items-center">
                        <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                            {dataQuery.isLoading ? (
                                <Skeleton width={150} height={40} className="bg-gray-200" />
                            ) : (
                                <p className="text-5xl font-bold">
                                    {/* +{(dataQuery.data?.publicCompetitions || 0).toLocaleString()} */}
                                    <p className="text-5xl font-bold">+80</p>
                                </p>
                            )}
                            <p className="font-semibold my-auto">{i18next.t('list-of-prizes-count')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex lg:flex-row flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10 bg-white">
                <img alt="banner" src={registeredButtonImage} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('registration-companies-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('registration-companies-description')}</p>

                    <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                        <Link
                            to="/competitions/public"
                            className="md:min-w-fit md:w-min w-full flex gap-5 py-3 px-10 items-center justify-center bg-orange-700 shadow-lg shadow-orange-700/30 text-white rounded-full select-none"
                        >
                            <FontAwesomeIcon icon={faEye} size="lg" />
                            <p className="font-semibold">{i18next.t('inscription-button')}</p>
                        </Link>
                    </div>
                </div>
            </div>
            /
            <Footer />
        </div>
    );
}

export default CompanyHomePage;
