/**
 * validateCompanyDetails validates if the company has all the required information completed
 * @method GET
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{ complete: boolean }>}
 */
export default async function validateCompanyDetails(jwt, language) {
  try {
      const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/validate/company`;
      const method = 'GET';
      const headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: jwt,
      };

      const response = await fetch(url, { method, headers });
      const data = await response.json();

      if (!response.ok) {
          const errorData = { controlled: true, data };
          throw errorData;
      }
      return data;
  } catch (error) {
      if (error.controlled) {
          throw error.data;
      }

      const errorData = { status: 500, messages: [error.message], context: 'error' };
      throw errorData;
  }
}