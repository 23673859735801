import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import useSession from '../../hooks/useSession';
import Select from '../atoms/Select';
import Input from '../atoms/Input';
import Title from '../atoms/Title';
import Button from '../atoms/Button';
import useFeedback from '../../hooks/useFeedback';
import validateCity from '../../services/validateCity';
import getProfessionalProfile from '../../services/getProfessionalProfile';
import UpdateProfessionalProfile from '../../services/UpdateProfesionalProfile';

function ProfessionalProfileForm() {
    
    const { session } = useSession();
    const queryClient = useQueryClient();
    const { createFeedback, feedbackElement } = useFeedback();

    const englishLevelOrder = ['BASIC', 'MEDIUM', 'HIGH', 'NATIVE', 'UNKNOWN'];

    const [professionalProfile, setProfessionalProfile] = useState({
        salary: null,
        currency: null,
        englishLevel: null,
        city: null,
    });

    // Fetch Professional Profile
    const professionalProfileQuery = useQuery({
        queryKey: [session, 'professionalProfile'],
        queryFn: () => getProfessionalProfile(session, i18next.language),
        enabled: !!session,
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
    });

    // Load Professional Profile data
    useEffect(() => {
        if (professionalProfileQuery.data) {
            setProfessionalProfile((prev) => ({
                ...prev,
                ...professionalProfileQuery.data,
            }));
        }
    }, [professionalProfileQuery.data]);

    // Update Professional Profile
    const updateProfessionalProfileMutation = useMutation({
        mutationFn: ({ salary, englishLevel, currency, city }) => {
            const user = { salary, englishLevel, currency, city };
            return UpdateProfessionalProfile(user, session, i18next.language);
        },
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('professional-profile-updated'));
            queryClient.invalidateQueries([session, 'professionalProfile']);
        },
    });

    // Handle form submission
    const handleUpdateProfessionalUser = async () => {
        
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        const { salary, englishLevel, city } = professionalProfile;
        let { currency } = professionalProfile;

        currency = currency || 'COP';

        const missingFields = [];

        if (!englishLevel || !englishLevelOrder.includes(englishLevel)) {
            missingFields.push(i18next.t('english-level'));
        }

        if (!salary || isNaN(salary)) {
            missingFields.push(i18next.t('salary'));
        }

        if (!currency || !['COP', 'USD', 'EUR', 'ARS', 'CLP', 'PEN', 'BRL', 'GBP', 'CAD'].includes(currency)) {
            missingFields.push(i18next.t('currency'));
        }

        if (missingFields.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${missingFields.join(', ')}`;
            createFeedback('warn', errorMessage);
            return;
        }

        const originalCity = professionalProfileQuery.data?.city || '';
        if (city !== originalCity && city !== '') {
            createFeedback('message', i18next.t('city-validating'));

            try {
                const cityValidationResult = await validateCity(professionalProfile.city);
                if (cityValidationResult.context === 'error') {
                    createFeedback('warn', i18next.t('city-validation-error'));
                    return;
                }
            } catch (error) {
                createFeedback('error', i18next.t('city-validation-error'));
                return;
            }
        }

        updateProfessionalProfileMutation.mutate({ salary, englishLevel, currency, city });
    };

    return (
        <div className="w-full flex flex-col gap-5">
            {feedbackElement}
            <Title size="md">{i18next.t('english-level')}</Title>
            <Select
                isLoading={professionalProfileQuery.isLoading}
                color="white"
                selected={professionalProfile.englishLevel || '_UNKNOWN'}
                onSelect={(selected) =>
                    setProfessionalProfile({ ...professionalProfile, englishLevel: selected })
                }
                options={{
                    [null]: {
                        BASIC: i18next.t('basic'),
                        MEDIUM: i18next.t('medium'),
                        HIGH: i18next.t('high'),
                        NATIVE: i18next.t('native'),
                        UNKNOWN: i18next.t('none'),
                    },
                }}
                order={englishLevelOrder}
            />

            <Title size="md">{i18next.t('salary')}</Title>
            <Input
                isLoading={professionalProfileQuery.isLoading}
                color="white"
                type="number"
                value={professionalProfile.salary?.toString() || ''}
                onType={(value) => setProfessionalProfile({ ...professionalProfile, salary: value })}
            />

            <Title size="md">{i18next.t('currency')}</Title>
            <Select
                isLoading={professionalProfileQuery.isLoading}
                color="white"
                selected={professionalProfile.currency || 'COP'}
                onSelect={(selected) =>
                    setProfessionalProfile({ ...professionalProfile, currency: selected })
                }
                options={{
                    [null]: {
                        COP: i18next.t('currency-colombia'),
                        USD: i18next.t('currency-usa'),
                        EUR: '(EUR) Euro',
                        ARS: i18next.t('currency-argentina'),
                        CLP: i18next.t('currency-chile'),
                        PEN: i18next.t('currency-peru'),
                        BRL: i18next.t('currency-brazil'),
                        GBP: i18next.t('currency-uk'),
                        CAD: i18next.t('currency-canada'),
                    },
                }}
            />

            <Title size="md">{i18next.t('city')}</Title>
            <Input
                isLoading={professionalProfileQuery.isLoading}
                color="white"
                type="text"
                value={professionalProfile.city || ''}
                onType={(value) => setProfessionalProfile({ ...professionalProfile, city: value })}
            />

            <Button
                isLoading={professionalProfileQuery.isLoading || updateProfessionalProfileMutation.isLoading}
                color="green"
                icon={faPaperPlane}
                onClick={handleUpdateProfessionalUser}
            >
                {i18next.t('update-professional-profile')}
            </Button>
        </div>
    );
}

export default ProfessionalProfileForm;