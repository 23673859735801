import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import getCategories from '../../services/getCategories';
import Search from '../molecules/Search';
import Competitions from '../organisms/Competitions';
import NavbarTemplate from '../templates/NavbarTemplate';

/**
 * Competitions page shows the list of public or private competitions
 */
function CompetitionsPage() {
    const { visibility: originalVisibility } = useParams();
    const { session } = useSession();

    // It will be adjusted when the subdomains are available
    // Detect domain directly
    const { hostname } = window.location;
    const isLocalhost = hostname.includes('localhost');
    const isEstratekDomain = hostname === 'estratek.smartranks.co' || originalVisibility === 'innovation';

    // Adjust domain and visibility
    let domain;
    if (isEstratekDomain) {
        domain = 'estratek.smartranks.co';
    } else if (isLocalhost) {
        if (hostname.split('.')[0] !== 'localhost') {
            domain = `${hostname.split('.')[0]}.smartranks.co`;
        } else {
            domain = 'smartranks.co';
        }
    } else if (hostname.split('.').length > 2) {
        domain = hostname;
    } else {
        domain = 'smartranks.co';
    }

    // ToDo: Eliminar este adjustVisibility una vez funcionen los subdominios
    const adjustedVisibility = isEstratekDomain ? 'all' : originalVisibility;

    const [criteria, setCriteria] = useState({ name: '', status: [], categories: [], domain });
    const [filters, setFilters] = useState();

    // Get categories and update filters
    const categoriesQuery = useQuery({
        queryKey: ['categories'],
        queryFn: () => getCategories(i18next.language),
        enabled: !isEstratekDomain,
    });

    // Load categories for filters
    useEffect(() => {
        if (!isEstratekDomain && categoriesQuery.data) {
            const { categories } = categoriesQuery.data;
            const categoriesFilter = {
                key: 'categories',
                unique: false,
                title: i18next.t('categories'),
                checkboxes: categories.map(({ id, name }) => ({ key: `${id}`, value: name, checked: false })),
            };

            setFilters([categoriesFilter]);
        }
    }, [categoriesQuery.data, isEstratekDomain]);

    // Update criteria on change filters
    useEffect(() => {
        if (!isEstratekDomain && filters) {
            const categories = filters
                .find((filter) => filter.key === 'categories')
                .checkboxes.filter((checkbox) => checkbox.checked)
                .map((checkbox) => parseInt(checkbox.key, 10));

            setCriteria((prev) => ({ ...prev, categories }));
        }
    }, [filters, isEstratekDomain]);

    /**
     * Update criteria on click search
     * @param {string} input
     */
    const handleSearch = (input) => {
        setCriteria((prev) => ({ ...prev, name: input }));
    };

    // Handle session
    if ((originalVisibility === 'private' || originalVisibility === 'history' || originalVisibility === 'innovation') 
        && !session) {
        return <Navigate to="/auth/sign-in" />;
    }

    return (
        <NavbarTemplate>
            <div className="w-full flex 2xl:flex-row flex-col-reverse sm:gap-10 gap-5">
                <Competitions visibility={adjustedVisibility} criteria={{ ...criteria, domain }} />
                {!isEstratekDomain && (
                    <div className="2xl:max-w-md w-full flex flex-col gap-5">
                        <Search
                            isLoading={categoriesQuery.isLoading}
                            placeholder={i18next.t('search-competitions')}
                            filters={filters}
                            onFilter={(newFilters) => setFilters(newFilters)}
                            onSearch={handleSearch}
                        />
                    </div>
                )}
            </div>
        </NavbarTemplate>
    );
}

export default CompetitionsPage;