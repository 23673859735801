/* eslint-disable no-nested-ternary */
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

/**
 * Input atom creates an input/textarea with a loading state
 * @param {{
 *      isLoading: boolean;
 *      inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement> | undefined;
 *      color?: 'gray' | 'white';
 *      type: 'text' | 'number' | 'email' | 'password' | 'date' | 'month' | 'textarea' | 'datetime-local'; 
 *      complete: 'nickname' | 'username' | 'current-password' | 'new-password' | 'off';
 *      focus: boolean;
 *      placeholder: string | undefined;
 *      defaultValue: string | undefined;
 *      value: string | undefined;
 *      onType: ((value: string) => void) | undefined;
 *      readOnly: boolean;
 *      required: boolean | undefined;
 *      label: string | undefined;
 *      labelColor: 'gray' | 'red' | 'green' | 'blue' | 'orange' | 'purple' | 'black';
 *      labelSize: 'xs' | 'sm' | 'md' | 'lg';
 * }} props
 */
function Input({ 
    isLoading,
    inputRef,
    color,
    type,
    complete,
    focus,
    placeholder,
    defaultValue, 
    value,
    onType,
    readOnly,
    required,
    label,
    labelColor,
    labelSize   
}) {
    const [reveal, setReveal] = useState(false);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState(false);

    const handleBlur = () => {
        if (required) {
            setTouched(true);
            setError(!value || value.trim() === '');
        }
    };

    const handleChange = (e) => {
        if (onType) {
            onType(e.target.value);
        }
        
        if (required && touched) {
            setError(!e.target.value || e.target.value.trim() === '');
        }
    };

    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                borderRadius={6}
                containerClassName="w-full flex h-10"
            />
        );
    }

    
    const labelClasses = classNames(
        labelColor === 'gray' ? 'text-gray-700' :
        labelColor === 'red' ? 'text-red-500' :
        labelColor === 'green' ? 'text-smgreen-dark' :
        labelColor === 'blue' ? 'text-smblue-dark' :
        labelColor === 'orange' ? 'text-smorange-dark' :
        labelColor === 'purple' ? 'text-smpurple-dark' : 'text-black',

        labelSize === 'xs' ? 'text-xs' :
        labelSize === 'sm' ? 'text-sm' :
        labelSize === 'md' ? 'text-base' :
        labelSize === 'lg' ? 'text-lg' : 'text-base'
    );

    return (
        <div className="w-full">
            {label && (
                <p className={classNames(
                    labelClasses,
                    "text-sm font-medium text-gray-700 transition-all duration-300 transform",
                    value ? "translate-y-0 opacity-100" : "translate-y-2 opacity-80",
                    focus ? "text-smgreen-dark" : ""
                )}>
                    {label}
                </p>
            )}
            <div
                className={classNames(
                    'w-full flex rounded-md items-center overflow-hidden shadow-md transition-all duration-200 border border-gray-300',
                    color === 'gray' ? 'bg-smgray shadow-inner' : 'bg-white shadow-md',
                    error && 'ring-2 ring-red-500',
                    required && !error && touched && !readOnly && value?.trim() !== '' && 'ring-2 ring-green-500',
                    "mt-2"
                )}
            >
                {type === 'textarea' ? (
                    <textarea
                        ref={inputRef}
                        autoFocus={focus}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        required={required}
                        className="w-full min-h-[100px] p-5 bg-transparent outline-none"
                    />
                ) : (
                    <input
                        ref={inputRef}
                        type={type === 'password' && reveal ? 'text' : type}
                        autoComplete={complete}
                        autoFocus={focus}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        required={required}
                        className="w-full px-5 h-10 bg-transparent text-sm outline-none"
                    />
                )}

                {type === 'password' && (
                    <button
                        type="button"
                        onClick={() => setReveal(!reveal)}
                        className="w-14 flex-shrink-0 flex justify-center items-center text-smgray-dark outline-none"
                    >
                        <FontAwesomeIcon icon={reveal ? faEye : faEyeSlash} />
                    </button>
                )}
            </div>

            {error && touched && (
                <p className="text-red-500 text-sm mt-1 transition-all duration-200">
                    {i18next.t('required-field')}
                </p>
            )}
        </div>
    );
}

Input.propTypes = {
    isLoading: PropTypes.bool,
    inputRef: PropTypes.shape(),
    color: PropTypes.oneOf(['gray', 'white']),
    type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'date', 'month', 'textarea', 'datetime-local']),
    complete: PropTypes.oneOf(['nickname', 'username', 'current-password', 'new-password', 'off']),
    focus: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    onType: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.string,
    labelColor: PropTypes.oneOf(['gray', 'red', 'green', 'blue', 'orange', 'purple', 'black']),
    labelSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

Input.defaultProps = {
    isLoading: false,
    inputRef: undefined,
    complete: 'off',
    color: 'white',
    type: 'text',
    focus: false,
    placeholder: undefined,
    defaultValue: undefined,
    value: undefined,
    readOnly: false,
    onType: undefined,
    required: undefined,
    label: undefined,
    labelColor: 'black',
    labelSize: 'md',
};

export default Input;
