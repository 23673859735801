import PropTypes from 'prop-types';
import i18next from 'i18next';
import { faBan, faPlay } from '@fortawesome/free-solid-svg-icons';
import Button from '../atoms/Button';

function StartInnovationChallenge({ onAccept, onCancel, isLoading = false }) {
    return (
        <div className="w-full max-w-lg p-6 bg-white rounded-xl shadow-lg border border-estratek-lightblue">
            <h2 className="text-2xl font-bold text-smorange-dark text-center mb-6">
                {i18next.t('innovation-challenge-title', 'Reto de Innovación Abierta')}
            </h2>
            <p className="text-estratek-black mb-6 leading-relaxed text-justify">
                {i18next.t(
                    'innovation-challenge-description',
                    'Para participar en nuestro reto de innovación abierta, es fundamental contar con la información completa de tu empresa. Si aún no has finalizado este registro, serás redirigido a una página donde podrás completar los datos necesarios. Una vez concluido este proceso, podrás compartirnos tu solución y formar parte de esta iniciativa.'
                )}
            </p>
            <div className="flex justify-end gap-4">
                <Button 
                    color="white" 
                    icon={faBan} 
                    onClick={isLoading ? undefined : onCancel}
                    isLoading={isLoading}
                >
                    {i18next.t('cancel')}
                </Button>
                <Button 
                    color="orange" 
                    icon={faPlay} 
                    onClick={isLoading ? undefined : onAccept}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    {i18next.t('start')}
                </Button>
            </div>
        </div>
    );
}

StartInnovationChallenge.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

StartInnovationChallenge.defaultProps = {
    isLoading: false,
};

export default StartInnovationChallenge;