import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import FormTemplate from '../templates/FormTemplate';
import CompanyRegister from '../organisms/CompanyRegister';

/**
 * Company Register page shows a form to create a company user
 */
function CompanyRegisterPage() {
    const { session } = useSession();

    // Handle session
    if (session) {
        return <Navigate to="/dashboard/company" />; // ToDo: falta revisar esta ruta
    }

    return (
        <FormTemplate maxWidth="xl">
            <CompanyRegister />
        </FormTemplate>
    );
}

export default CompanyRegisterPage;