import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';

/**
 * Pager molecule creates an paginator for stack content with a loading state
 * @param {{
 *      isLoading: boolean;
 *      pages: number;
 *      page: number;
 *      setPage: (page: number) => void;
 * }} props
 */

function Pager({ isLoading, pages, page, setPage }) {
    const maxPagesToShow = 10;
    const renderPageButtons = () => {
        const pageButtons = [];

        if (pages <= maxPagesToShow) {
            for (let i = 0; i < pages; i += 1) {
                pageButtons.push(
                    <Button
                        key={`pager:${i + 1}`}
                        isLoading={isLoading}
                        color={page === i ? 'orange' : 'white'}
                        circle
                        onClick={() => setPage(i)}
                    >
                        {(i + 1).toString()}
                    </Button>,
                );
            }
        } else {
            let startPage = page - Math.floor(maxPagesToShow / 2);
            if (startPage < 0) startPage = 0;
            if (startPage + maxPagesToShow > pages) startPage = pages - maxPagesToShow;

            // First page button
            if (page > 0) {
                pageButtons.push(
                    <Button
                        key="pager:first"
                        isLoading={isLoading}
                        color="gray"
                        circle
                        icon={faAnglesLeft}
                        title={i18next.t('first-page')}
                        onClick={() => setPage(0)}
                    />,
                );
            }

            // Prev page button
            if (page > 0) {
                pageButtons.push(
                    <Button
                        key="pager:prev"
                        isLoading={isLoading}
                        color="gray"
                        circle
                        icon={faAngleLeft}
                        title={i18next.t('prev-page')}
                        onClick={() => setPage(page - 1)}
                    />,
                );
            }

            // Visible pages
            for (let i = startPage; i < startPage + maxPagesToShow; i += 1) {
                pageButtons.push(
                    <Button
                        key={`pager:${i + 1}`}
                        isLoading={isLoading}
                        color={page === i ? 'orange' : 'white'}
                        circle
                        onClick={() => setPage(i)}
                    >
                        {(i + 1).toString()}
                    </Button>,
                );
            }

            // Next page button
            if (page < pages - 1) {
                pageButtons.push(
                    <Button
                        key="pager:next"
                        isLoading={isLoading}
                        color="gray"
                        circle
                        icon={faAngleRight}
                        title={i18next.t('next-page')}
                        onClick={() => setPage(page + 1)}
                    />,
                );
            }

            // Last page button
            if (page < pages - 1) {
                pageButtons.push(
                    <Button
                        key="pager:last"
                        color="gray"
                        isLoading={isLoading}
                        circle
                        icon={faAnglesRight}
                        title={i18next.t('last-page')}
                        onClick={() => setPage(pages - 1)}
                    />,
                );
            }
        }

        return pageButtons;
    };

    return <div className="flex gap-3">{renderPageButtons()}</div>;
}

Pager.propTypes = {
    isLoading: PropTypes.bool,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};

Pager.defaultProps = {
    isLoading: false,
};

export default Pager;
