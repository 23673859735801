import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTie,
  faMoneyBillWave,
  faTrophy,
  faCalendar,
  faCalendarXmark,
  faFlagCheckered,
} from '@fortawesome/free-solid-svg-icons';
import Container from '../atoms/Container';
import TruncatedText from '../atoms/TruncatedText';
import Title from '../atoms/Title';
import Tag from '../atoms/Tag';
import formatDate from '../../utils/formatDate';

/**
 * CardInnovation molecule displays a specialized card for innovation challenges.
 * @param {{
 *    name: string;
 *    description: string;
 *    status: string;
 *    owner: string[];
 *    prize: string;
 *    prizeDate: { date: string; timezone_type: number; timezone: string; };
 *    startDate: string;
 *    endDate: string;
 *    onClick?: () => void;
 *    isLoading?: boolean;
 *    maxWordsDescription?: number;
 * }} props
 */
function CardInnovation({
    name,
    description,
    status,
    owner,
    prize,
    prizeDate,
    startDate,
    endDate,
    onClick,
    isLoading,
    maxWordsDescription,
}) {

    return (
        <Container
            onClick={onClick}
        >
            <div className="flex flex-col gap-5 p-5">
                <div className="flex justify-between items-center w-full">
                <Title isLoading={isLoading} size="lg">
                    {name}
                </Title>
                <Tag isLoading={isLoading} color="blue" size="md">
                    {status}
                </Tag>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {owner && owner.length > 0 && (
                    <div className="flex items-center gap-2 text-sm">
                    <FontAwesomeIcon icon={faUserTie} className="text-smblue-dark" />
                    <span className="truncate">{owner.join(', ')}</span>
                    </div>
                )}

                {prize && (
                    <div className="flex items-center gap-2 text-sm">
                        <FontAwesomeIcon
                            icon={faMoneyBillWave}
                            className="text-smorange-dark"
                        />
                        <span>{prize}</span>
                    </div>
                )}

                {prizeDate?.date && (
                    <div className="flex items-center gap-2 text-sm">
                        <FontAwesomeIcon icon={faTrophy} className="text-smgreen-dark" />
                        <span>{formatDate(prizeDate.date)}</span>
                    </div>
                )}

                {startDate && (
                    <div className="flex items-center gap-2 text-sm">
                        <FontAwesomeIcon
                            icon={faCalendar}
                            className="text-smgray-dark"
                        />
                        <span>{formatDate(startDate)}</span>
                    </div>
                )}

                {endDate && (
                    <div className="flex items-center gap-2 text-sm">
                        <FontAwesomeIcon
                            icon={faCalendarXmark}
                            className="text-smgray-dark"
                        />
                        <span>{formatDate(endDate)}</span>
                    </div>
                )}
                </div>

                <div className="text-sm text-gray-600">
                    <TruncatedText text={description} maxWords={maxWordsDescription} />
                </div>

                {status === 'finished' && (
                    <div className="flex items-center gap-2 text-sm text-red-500">
                        <FontAwesomeIcon icon={faFlagCheckered} />
                        <span>¡Competencia finalizada!</span>
                    </div>
                )}
            </div>
        </Container>
    );
}

CardInnovation.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    owner: PropTypes.arrayOf(PropTypes.string).isRequired,
    prize: PropTypes.string.isRequired,
    prizeDate: PropTypes.shape({
        date: PropTypes.string.isRequired,
        timezone_type: PropTypes.number,
        timezone: PropTypes.string,
    }).isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    maxWordsDescription: PropTypes.number,
};

CardInnovation.defaultProps = {
    startDate: null,
    endDate: null,
    onClick: undefined,
    isLoading: false,
    maxWordsDescription: 100,
};

export default CardInnovation;