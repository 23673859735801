import i18next from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { faArrowLeftLong, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSession from '../../hooks/useSession';
import useFeedback from '../../hooks/useFeedback';
import getInnovationResults from '../../services/getInnovationResults';
import Container from '../atoms/Container';
import Title from '../atoms/Title';
import Button from '../atoms/Button';
import CardToggle from '../molecules/CardToggle';
import Spinner from '../atoms/Spinner';

function InnovationResultsPage() {
    const { guid } = useParams();
    const { session } = useSession();
    const { createFeedback, feedbackElement } = useFeedback();
    const navigate = useNavigate();

    const { data: results, isLoading, isError, isFetching } = useQuery({
        queryKey: ['innovationResults', guid, session],
        queryFn: () => getInnovationResults(guid, session, i18next.language),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
        onError: (error) => {
            createFeedback('error', error?.messages || i18next.t('error-loading-results'));
        },
    });

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Container className="max-w-4xl mx-auto">
            <Spinner isLoading={isLoading || isFetching} message={i18next.t('loading')} />

            {isError ? (
                <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                  <Title size="xl" className="text-gray-800">{i18next.t('error-loading-results')}</Title>
                  <div className="flex items-center gap-x-3" title={i18next.t('go-back')}>
                      <Button onClick={handleBack} color="gray" icon={faArrowLeftLong}>
                        {i18next.t('go-back')}
                      </Button>
                  </div>
                </div>
            ) : (
                <div className="flex flex-col gap-8">
                    <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                        <Title size="xl" className="text-gray-800">{i18next.t('results')}</Title>
                        <div className="flex items-center gap-x-3" title={i18next.t('go-back')}>
                            <Button onClick={handleBack} color="gray" icon={faArrowLeftLong}>
                                {i18next.t('go-back')}
                            </Button>
                        </div>
                    </div>

                    {results?.length > 0 ? (
                        <div className="grid gap-6 grid-cols-1">
                            {results.map((result) => (
                                <CardToggle
                                    key={result.guid}
                                    title={`${result.tradename} - ${result.score} pts`}
                                    size="lg"
                                    border={result.score >= 60 ? 'green' : 'red'}
                                    className="hover:shadow-lg transition-shadow duration-200"
                                >
                                    <div className="p-6 bg-gray-50">
                                    <p className="text-sm mb-2">
                                            <Link
                                                to={`users/${result.guid}`}
                                                className="text-blue-800 text-xl"
                                            >
                                                {result.tradename}
                                            </Link>
                                            <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500 ml-2" /> 
                                        </p>
                                        <p className="text-sm mb-2">
                                            <strong className="text-gray-700">Email:</strong>{result.email}
                                        </p>
                                        <p className="text-sm mb-2">
                                            <strong className="text-gray-700">Score:</strong> 
                                            <span className={`ml-1 font-semibold ${result.score >= 60 ? 'text-green-600' : 'text-red-600'}`}>
                                                {result.score}
                                            </span>
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <strong>Justification:</strong> 
                                            <span className="block mt-1 italic bg-white p-2 rounded border border-gray-200">
                                                {result.justification}
                                            </span>
                                        </p>
                                    </div>
                                </CardToggle>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 bg-gray-100 p-8 rounded-lg">
                          {i18next.t('no-solutions-found')}
                        </div>
                    )}
                </div>
            )}

            {feedbackElement}
        </Container>
    );
}

export default InnovationResultsPage;