import { useState, useEffect } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';
import i18next from 'i18next';
import Banner from '../atoms/Banner';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Title from '../atoms/Title';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import updatePassword from '../../services/updatePassword';
import setPassword from '../../services/setPassword';

function ProfilePasswordForm() {

    const queryClient = useQueryClient();
    const { session } = useSession();
    const { feedbackElement, createFeedback } = useFeedback();
    const { profile: parentProfile, isLoading } = useOutletContext();

    const [profile, setProfile] = useState({
        pending: false,
        password: '',
        newPassword: '',
        confirmPassword: '',
    });

    useEffect(() => {
        if (parentProfile) {
            setProfile((prev) => ({
                ...prev,
                pending: parentProfile.pending || false,
            }));
        }
    }, [parentProfile]);

    useEffect(() => {
        if (profile.pending) {
            createFeedback('message', i18next.t('set-password-continue'));
        }
    }, [profile.pending, createFeedback]);

    const handleInputChange = (field, value) => {
        setProfile((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    // Update password mutation
    const updatePasswordMutation = useMutation({
        mutationFn: () => updatePassword(profile.password, profile.newPassword, session, i18next.language),
        onMutate: () => createFeedback('message', i18next.t('loading')),
        onError: (error) => createFeedback('error', error.messages.join(', ')),
        onSuccess: () => {
            createFeedback('success', i18next.t('password-updated'));
            resetFields();
            queryClient.invalidateQueries([session, 'user']);
        },
    });

    // Set password mutation (for pending users)
    const setPasswordMutation = useMutation({
        mutationFn: () => setPassword(profile.password, session, i18next.language),
        onMutate: () => createFeedback('message', i18next.t('loading')),
        onError: (error) => createFeedback('error', error.messages.join(', ')),
        onSuccess: () => {
            createFeedback('success', i18next.t('password-set-success'));
            resetFields();
            queryClient.invalidateQueries([session, 'user']);
        },
    });


    // Helper function to reset fields
    const resetFields = () => {
        setProfile((prev) => ({
            ...prev,
            pending: parentProfile.pending || false,
            password: '',
            newPassword: '',
            confirmPassword: '',
        }));
    };

    // Handle password update
    const handleUpdatePassword = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        
        const { password, newPassword } = profile;

        if (!password || !newPassword) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        updatePasswordMutation.mutate();
    };

    // Handle set password for pending user
    const handleSetPassword = () => {
        const { password, confirmPassword } = profile;

        if (!password || !confirmPassword) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        if (password !== confirmPassword) {
            createFeedback('warn', i18next.t('passwords-not-match'));
            return;
        }

        setPasswordMutation.mutate();
    };

    return (
        <div className="w-full flex flex-col gap-5">
            {feedbackElement}

            {profile.pending ? (
                <>
                    <Banner borderColor="orange" color="orange">{i18next.t('set-password')}</Banner>
                    <div className="w-full flex flex-col gap-3">
                        <div className="w-full flex flex-col gap-2">
                            <Title size="md">{i18next.t('password')}</Title>
                            <Input
                                isLoading={isLoading || setPasswordMutation.isLoading}
                                color="white"
                                type="password"
                                complete="new-password"
                                value={profile.password}
                                onType={(value) => handleInputChange('password', value)}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Title size="md">{i18next.t('confirm-password')}</Title>
                            <Input
                                isLoading={isLoading || setPasswordMutation.isLoading}
                                color="white"
                                type="password"
                                complete="new-password"
                                value={profile.confirmPassword}
                                onType={(value) => handleInputChange('confirmPassword', value)}
                            />
                        </div>
                    </div>
                    <Button
                        isLoading={isLoading || setPasswordMutation.isLoading}
                        color="green"
                        icon={faPaperPlane}
                        onClick={handleSetPassword}
                    >
                        {i18next.t('set-password')}
                    </Button>
                </>
            ) : (
                <>
                    <Banner borderColor="orange" color="orange">{i18next.t('update-password')}</Banner>
                    <div className="w-full flex flex-col gap-3">
                        <div className="w-full flex flex-col gap-2">
                            <Title size="md">{i18next.t('current-password')}</Title>
                            <Input
                                isLoading={isLoading || updatePasswordMutation.isLoading}
                                color="white"
                                type="password"
                                complete="current-password"
                                value={profile.password}
                                onType={(value) => handleInputChange('password', value)}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Title size="md">{i18next.t('new-password')}</Title>
                            <Input
                                isLoading={isLoading || updatePasswordMutation.isLoading}
                                color="white"
                                type="password"
                                complete="new-password"
                                value={profile.newPassword}
                                onType={(value) => handleInputChange('newPassword', value)}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Title size="md">{i18next.t('confirm-password')}</Title>
                            <Input
                                isLoading={isLoading || updatePasswordMutation.isLoading}
                                color="white"
                                type="password"
                                complete="new-password"
                                value={profile.confirmPassword}
                                onType={(value) => handleInputChange('confirmPassword', value)}
                            />
                        </div>
                    </div>
                    <Button
                        isLoading={isLoading || updatePasswordMutation.isLoading}
                        color="orange"
                        icon={faPaperPlane}
                        onClick={handleUpdatePassword}
                    >
                        {i18next.t('update')}
                    </Button>
                </>
            )}
        </div>
    );
}

export default ProfilePasswordForm;