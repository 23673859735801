import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { Navigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import getChallenge from '../../services/getChallenge';
import getCompetition from '../../services/getCompetition';
import NavbarTemplate from '../templates/NavbarTemplate';
import ChallengeInnovation from '../organisms/ChallengeInnovation';
import Challenges from '../organisms/Challenges';

function ChallengeInnovationPage() {
    const { visibility, competition, challenge } = useParams();
    const { session } = useSession();
    const { createFeedback } = useFeedback();

    const competitionQuery = useQuery({
        queryKey: [session, 'competitions', competition],
        queryFn: () => getCompetition(competition, session, i18next.language),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const challengeQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges', challenge],
        queryFn: () => getChallenge(competition, challenge, session, i18next.language),
    });

    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    if (competitionQuery.error?.status === 403) {
        createFeedback('error', i18next.t('competition-is-over'), true);
        return <Navigate to="/competitions/public" />;
    }

    const isInnovation = competitionQuery?.data?.isInnovation || false;

    const routes = [
        { link: `/competitions/${visibility}`, text: i18next.t('competitions') },
        { link: `/competitions/${visibility}`, text: i18next.t(visibility) },
        {
            isLoading: competitionQuery.isLoading,
            link: `/competitions/${visibility}/${competition}`,
            text: competitionQuery.data?.name || '',
        },
        { link: `/competitions/${visibility}/${competition}`, text: i18next.t('challenges') },
        { isLoading: challengeQuery.isLoading, text: challengeQuery.data?.name || '' },
    ];

    return (
        <NavbarTemplate routes={routes} screen>
            <div className="w-full flex flex-row sm:gap-10 gap-5">
                <div className="xl:w-2/3 w-full">
                    <ChallengeInnovation />
                </div>
                <div className="w-1/3 xl:flex hidden flex-col gap-10">
                    <Challenges compact isInnovation={isInnovation} />
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default ChallengeInnovationPage;