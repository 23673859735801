import { faCheck, faChevronLeft, faChevronRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import getChallenges from '../../services/getChallenges';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Title from '../atoms/Title';

/**
 * Challenges organism creates a list for the competition challenges.
 * @param {{
 *  compact: boolean,
 *  isInnovation?: boolean
 * }} props
 */
function Challenges({ compact, isInnovation = false }) {
    const { visibility, competition, challenge } = useParams();
    const { session } = useSession();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState('');

    // Get challenge list
    const challengesQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges'],
        queryFn: () => getChallenges(competition, session, i18next.language),
    });

    // Load categories for compact mode
    useEffect(() => {
        if (compact && challenge && challengesQuery.data) {
            const newCategories = {};
            challengesQuery.data.forEach((ichallenge) => {
                const { guid, category } = ichallenge;
                if (guid === challenge) {
                    setCurrentCategory(category);
                }

                if (!newCategories[category]) {
                    newCategories[category] = [];
                }

                newCategories[category].push(ichallenge);
            });

            setCategories(newCategories);
        }
    }, [challenge, challengesQuery.data, compact]);

    /**
     * Send to the clicked challenge for compact mode
     * @param {string} guid
     */
    const handleClick = (guid) => {
        if (isInnovation) {
            navigate(`/competitions/${visibility}/${competition}/challenges/${guid}/innovation`);
        } else {
            navigate(`/competitions/${visibility}/${competition}/challenges/${guid}`);
        }
    };

    /**
     * Show the next category challenges for compact mode
     */
    const handleNextCategory = () => {
        const categoriesKeys = Object.keys(categories);
        const currentIndex = categoriesKeys.indexOf(currentCategory);
        const nextIndex = (currentIndex + 1) % categoriesKeys.length;
        setCurrentCategory(categoriesKeys[nextIndex]);
    };

    /**
     * Show the previous category challenges for compact mode
     */
    const handlePreviousCategory = () => {
        const categoriesKeys = Object.keys(categories);
        const currentIndex = categoriesKeys.indexOf(currentCategory);
        const previousIndex = (currentIndex - 1 + categoriesKeys.length) % categoriesKeys.length;
        setCurrentCategory(categoriesKeys[previousIndex]);
    };

    if (compact) {
        return (
            <div className="w-full flex flex-col gap-5">
                <div
                    className={`w-full flex items-center ${
                        Object.keys(categories).length > 1 ? 'justify-between' : 'justify-center'
                    }`}
                >
                    {Object.keys(categories).length > 1 && (
                        <Button
                            isLoading={challengesQuery.isLoading}
                            color="green"
                            icon={faChevronLeft}
                            circle
                            className="absolute left-0"
                            onClick={handlePreviousCategory}
                        />
                    )}
                    
                    <Title isLoading={challengesQuery.isLoading} size="xl">
                        {currentCategory}
                    </Title>

                    {Object.keys(categories).length > 1 && (
                        <Button
                            isLoading={challengesQuery.isLoading}
                            color="green"
                            icon={faChevronRight}
                            circle
                            className="absolute right-0"
                            onClick={handleNextCategory}
                        />
                    )}
                </div>

                <div className="w-full flex flex-col gap-3">
                    {challengesQuery.isLoading ? (
                        <Skeleton
                            count={5}
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            borderRadius={6}
                            className="mb-5"
                            containerClassName="w-full h-12"
                        />
                    ) : (
                        categories[currentCategory]?.map((ichallenge) => (
                            <Container
                                key={`challenge:${ichallenge.guid}`}
                                border={ichallenge.guid === challenge ? 'green' : undefined}
                                onClick={() => handleClick(ichallenge.guid)}
                            >
                                <div className="w-full px-5 py-3 flex gap-5 items-center justify-between">
                                    <div className="w-full flex overflow-x-auto scrollbar-hidden whitespace-nowrap">
                                        <Title size="md">{ichallenge.name}</Title>
                                    </div>

                                    <div className="min-w-fit flex gap-5 items-center select-none">
                                        {ichallenge.solved && (
                                            <div className="flex gap-3 items-center">
                                                <FontAwesomeIcon icon={faCheck} className="text-smgreen-dark" />
                                                <p className="font-semibold">{i18next.t('solved')}</p>
                                            </div>
                                        )}

                                        {ichallenge.sent && (
                                            <div className="flex gap-3 items-center">
                                                <FontAwesomeIcon icon={faPaperPlane} className="text-smblue-dark" />
                                                <p className="font-semibold">{i18next.t('sent')}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Container>
                        ))
                    )}
                </div>
            </div>
        );
    }

    return <div>{}</div>;
}

Challenges.propTypes = {
    compact: PropTypes.bool,
    isInnovation: PropTypes.bool,
};

Challenges.defaultProps = {
    compact: false,
    isInnovation: false,
};

export default Challenges;
