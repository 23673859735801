/* eslint-disable no-nested-ternary */
import { faCheck, faClockFour, faPaperPlane, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Button from '../components/atoms/Button';
import Container from '../components/atoms/Container';
import Countdown from '../components/atoms/Countdown';
import Title from '../components/atoms/Title';
import AccessCamera from '../components/modals/AccessCamera';
import FinishCompetition from '../components/modals/FinishCompetition';
import NavbarTemplate from '../components/templates/NavbarTemplate';
import useFeedback from '../hooks/useFeedback';
import useModal from '../hooks/useModal';
import useSession from '../hooks/useSession';
import finishCompetition from '../services/finishCompetition';
import getChallenges from '../services/getChallenges';
import getCompetition from '../services/getCompetition';
import getDomain from '../services/getDomain';
// import getRankings from '../services/getRankings';
import accessCamera from '../utils/accessCamera';
// import formatDate from '../utils/formatDate';
import repeat from '../utils/repeat';

// import Pager from '../components/molecules/Pager';
import Ranking from '../components/organisms/Rankings';
import Dropdown from './Dropdown';
import View from './View';

export default function Competition() {
    const { visibility, competition } = useParams();
    const { session } = useSession();
    const { createFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const [challenges, setChallenges] = useState({});
    const navigate = useNavigate();
    // const [page, setPage] = useState(0);

    // Get competition domain
    const domainQuery = useQuery({
        queryKey: [session, 'domain', competition],
        queryFn: () => getDomain(competition, session, i18next.language),
        enabled: !!session,
    });

    // Get competition view
    const competitionQuery = useQuery({
        queryKey: [session, 'competitions', competition],
        queryFn: () => getCompetition(competition, session, i18next.language),
        enabled: !!session,
        refetchOnMount: true,
    });

    // Get challenge list
    const challengesQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges'],
        queryFn: () => getChallenges(competition, session, i18next.language),
        enabled: !!session,
    });

    // Finish competition
    const finishMutation = useMutation({
        mutationFn: () => finishCompetition(competition, session, i18next.language),
        onSuccess: () => {
            navigate('/competitions/private');
        },
    });

    // Validate domain rules
    useEffect(() => {
        if (domainQuery.data) {
            // Extract rules array: if setting is an object, use its 'Rules' property; otherwise, use setting directly.
            const rules = Array.isArray(domainQuery.data.setting)
            ? domainQuery.data.setting
            : domainQuery.data.setting.Rules || [];

            // Force CAM
            (async () => {
                if (rules.includes('FORCECAM')) {
                    try {
                        await accessCamera();
                    } catch (_) {
                        createModal(<AccessCamera onCancel={() => removeModal()} />);
                        navigate(-1);
                    }
                }
            })();
        }
    }, [domainQuery.data, createModal, removeModal, navigate]);

    // Load categories
    useEffect(() => {
        if (challengesQuery.data) {
            const newCategories = {};
            challengesQuery.data.forEach((ichallenge) => {
                const { category } = ichallenge;
                if (!newCategories[category]) {
                    newCategories[category] = [];
                }

                newCategories[category].push(ichallenge);
            });

            setChallenges(newCategories);
        }
    }, [challengesQuery.data]);

    /**
     * Finish the competition
     */
    const handleFinishCompetition = () => {
        const onAccept = () => {
            finishMutation.mutate();
            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(<FinishCompetition onAccept={onAccept} onCancel={onCancel} />);
    };

    /**
     * Send to the clicked challenge
     * @param {string} challenge
     */
    const handleChallenge = (challenge) => {
        if (competitionQuery.data?.isInnovation) {
            navigate(`/competitions/${visibility}/${competition}/challenges/${challenge}/innovation`);
        } else {
            navigate(`/competitions/${visibility}/${competition}/challenges/${challenge}`);
        }
    };

    // Handle session
    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    // Competition error
    if (competitionQuery.error) {
        createFeedback(competitionQuery.error.context, competitionQuery.error.messages, true);
        return <Navigate to={`/competitions/${visibility}`} />;
    }

    // Build navbar routes
    const routes = [
        {
            link: `/competitions/${visibility}`,
            text: i18next.t('competitions'),
        },
        {
            link: `/competitions/${visibility}`,
            text: i18next.t(visibility),
        },
        {
            isLoading: competitionQuery.isLoading,
            text: competitionQuery.data?.name || '',
        },
    ];

    const isInnovation = competitionQuery.data?.isInnovation;

    return (
        <NavbarTemplate routes={routes}>
            <div className="w-full flex gap-5">
                <div className="w-full max-w-5xl mx-auto flex flex-col gap-5">
                    <div className="w-full flex items-center justify-between">
                        <Title size="xl">{competitionQuery.data?.name || ''}</Title>
                        {competitionQuery.data?.duration && (
                            <Countdown
                                size="md"
                                time={competitionQuery.data.timeRemaining}
                                autoStart
                                onFinish={() => navigate('/competitions/private')}
                            />
                        )}
                    </div>
                    {competitionQuery.data?.duration && (
                        <Button
                            isLoading={finishMutation.isLoading}
                            color="green"
                            icon={faPowerOff}
                            onClick={handleFinishCompetition}
                        >
                            {i18next.t('finish-competition')}
                        </Button>
                    )}

                    {competitionQuery.data?.description && (
                    <Container className="my-8">
                        <div className="bg-estratek-lightblue p-6 sm:p-8 rounded-xl shadow-lg">
                            <p className="text-estratek-black text-base leading-relaxed">
                                {competitionQuery.data?.description}
                            </p>
                        </div>
                    </Container>
                    )}

                    {isInnovation && competitionQuery.data?.detail && (
                    <Container className="py-8">
                        <div className="bg-estratek-lightblue shadow-lg rounded-xl overflow-hidden">
                            <div className="p-6 sm:p-8 space-y-6">
                                <div className="flex items-center gap-3">
                                    <FontAwesomeIcon icon={faCheck} className="text-estratek-green text-2xl sm:text-3xl" />
                                    <h2 className="text-2xl sm:text-3xl font-bold text-estratek-black">{i18next.t('innovation-details')}</h2>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('description')}</h3>
                                    <p className="text-estratek-black">{competitionQuery.data.detail.description}</p>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('innovation-prize')}</h3>
                                    <p className="font-semibold text-estratek-pink">{competitionQuery.data.detail.prize}</p>
                                    <p className="text-sm text-estratek-black-dark mt-1">{competitionQuery.data.detail.prizeDescription}</p>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('innovation-prizeDate')}</h3>
                                    <p className="text-estratek-black">
                                        {new Date(competitionQuery.data.detail.prizeDate.date).toLocaleString()}
                                    </p>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('innovation-metrics')}</h3>
                                    <p className="text-estratek-black">{competitionQuery.data.detail.metrics}</p>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('innovation-expectedFiles')}</h3>
                                    <p className="text-estratek-black">{competitionQuery.data.detail.expectedFiles}</p>
                                </div>

                                <div className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:bg-estratek-lightblue">
                                    <h3 className="text-lg font-semibold mb-2 text-estratek-blue">{i18next.t('innovation-additional')}</h3>
                                    <p className="text-estratek-black">{competitionQuery.data.detail.additional}</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                    )}

                    {isInnovation && (
                    <h2 className="text-2xl font-bold text-estratek-purple mb-4">
                        {i18next.t('share-your-solution')}
                    </h2>
                    )}

                    {challengesQuery.isLoading
                        ? repeat(5, <View loading views={3} />)
                        : Object.keys(challenges).map((category, i) => (
                            <Dropdown key={`dropdown:${category}`} open={i === 0} gap={3} title={category}>
                                {challenges[category].map((challenge, j) => {
                                    const columns = isInnovation
                                        ? {
                                                [i18next.t('attempts')]: challenge.attempts,
                                                [i18next.t('max-attempts')]:
                                                    challenge.maxAttempts === -1
                                                        ? i18next.t('unlimited')
                                                        : challenge.maxAttempts,
                                            }
                                        : {
                                                [i18next.t('author')]: challenge.author,
                                                [i18next.t('score')]: challenge.score ? `${challenge.score} pts` : null,
                                                [i18next.t('difficulty')]: challenge.difficulty
                                                    ? i18next.t(challenge.difficulty)
                                                    : null,
                                                [i18next.t('attempts')]: challenge.attempts,
                                                [i18next.t('max-attempts')]:
                                                    challenge.maxAttempts === -1
                                                        ? i18next.t('unlimited')
                                                        : challenge.maxAttempts,
                                            };

                                    return (
                                        <View
                                            key={`dropdown:challenge:${challenge.guid}`}
                                            title={challenge.name}
                                            onClick={() => handleChallenge(challenge.guid)}
                                            icon={
                                                <FontAwesomeIcon
                                                    size="xl"
                                                    className="text-smgreen-dark"
                                                    icon={
                                                        challenge.solved
                                                            ? faCheck
                                                            : challenge.sent
                                                            ? faPaperPlane
                                                            : faClockFour
                                                    }
                                                />
                                            }
                                            columns={columns}
                                        />
                                    );
                                })}
                            </Dropdown>
                        ))}
                </div>

                {visibility === 'public' && !competitionQuery.isLoading && <Ranking competition={competition} />}
            </div>
        </NavbarTemplate>
    );
}
