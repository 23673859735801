import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faExclamationCircle, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const toastConfig = {
    success: { bgColor: 'bg-green-500', icon: faCheckCircle },
    error: { bgColor: 'bg-red-500', icon: faExclamationCircle },
    info: { bgColor: 'bg-blue-500', icon: faInfoCircle },
    warning: { bgColor: 'bg-yellow-500', icon: faExclamationTriangle, textColor: 'text-black' },
};

function Toast({ message, type, duration, onClose }) {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            if (onClose) onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    const handleClick = () => {
        setVisible(false);
        if (onClose) onClose();
    };

    if (!visible) return null;

    const { bgColor, icon, textColor = 'text-white' } = toastConfig[type];

    return (
        <div
            role="alert"
            className={classNames(
                'fixed bottom-4 right-4 max-w-sm w-full md:max-w-md',
                'transform transition-all duration-300 ease-out',
                visible ? 'translate-y-0 opacity-100' : 'translate-y-2 opacity-0'
            )}
        >
            <div className={classNames(
                'flex items-center p-4 rounded-lg shadow-lg',
                bgColor,
                textColor
            )}>
                <div className="flex-shrink-0 mr-3">
                    <FontAwesomeIcon icon={icon} className="text-2xl" />
                </div>
                <div className="flex-grow mr-2">
                    <p className="text-sm font-medium">{message}</p>
                </div>
                <button
                    type="button"
                    className={classNames(
                        'flex-shrink-0 ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5',
                        'inline-flex items-center justify-center h-8 w-8',
                        textColor === 'text-black' ? 'hover:bg-yellow-400' : 'hover:bg-opacity-25',
                        'transition-colors duration-200'
                    )}
                    onClick={handleClick}
                    aria-label="Close"
                >
                    <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
                </button>
            </div>
        </div>
    );
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning']).isRequired,
    duration: PropTypes.number,
    onClose: PropTypes.func,
};

Toast.defaultProps = {
    duration: 5000,
    onClose: undefined,
};

export default Toast;