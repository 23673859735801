import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import i18next from 'i18next';
import Banner from '../atoms/Banner';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Title from '../atoms/Title';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import updateUser from '../../services/updateUser';

function ProfileAboutForm() {
    const { session } = useSession();
    const queryClient = useQueryClient();
    const { feedbackElement, createFeedback } = useFeedback();
    const { profile: parentProfile, isLoading, countries, areCountriesLoading } = useOutletContext();

    const [profile, setProfile] = useState(parentProfile || {
        guid: '',
        username: '',
        email: '',
        firstName: null,
        lastName: null,
        phone: null,
        birthday: null,
        sex: null,
        country: 0,
        biography: null,
        web: null,
        twitter: null,
        facebook: null,
        linkedin: null,
        github: null,
        origin: null,
    });


    useEffect(() => {
        if (parentProfile) {
            setProfile(parentProfile);
        }
    }, [parentProfile]);

    // Update user
    const updateUserMutation = useMutation({
        mutationFn: (user) => updateUser(user, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('profile-updated'));
            queryClient.invalidateQueries([session, 'user']);
        },
    });

    const updateField = (field, value) => {
        setProfile((prev) => ({ ...prev, [field]: value }));
    };

    /**
     * Update user profile
     */
    const handleUpdateUser = () => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        const newProfile = { ...profile };
        delete newProfile.password;
        delete newProfile.newPassword;
        delete newProfile.confirmPassword;

        const missingFields = [];

        if (!newProfile.firstName) {
            missingFields.push(i18next.t('first-name'));
        }

        if (!newProfile.lastName || !/^[a-zA-Z]+$/.test(newProfile.lastName)) {
            missingFields.push(i18next.t('last-name'));
        }

        if (!newProfile.phone || newProfile.phone.trim() === '') {
            missingFields.push(i18next.t('phone-number-required'));
        } else if (
            !/^(\(\+?\d{2,3}\)[*|\s|\-|.]?(([\d][*|\s|\-|.]?){6})(([\d][\s|\-|.]?){2})?|(\+?[\d][\s|\-|.]?){8}(([\d][\s|\-|.]?){2}(([\d][\s|\-|.]?){2})?)?)$/.test(
                newProfile.phone,
            )
        ) {
            missingFields.push(i18next.t('phone-number-invalid'));
        }

        if (!newProfile.country) {
            missingFields.push(i18next.t('country'));
        }

        if (!newProfile.origin || newProfile.origin.trim() === '') {
            missingFields.push(i18next.t('origin'));
        }

        if (!newProfile.birthday) {
            missingFields.push(i18next.t('birthday'));
        }

        if (missingFields.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${missingFields.join(', ')}`;
            createFeedback('warn', errorMessage);
            return;
        }

        updateUserMutation.mutate(newProfile);
    };

    return (
        <div className="w-full flex flex-col gap-5">
            <Banner borderColor="orange" color="orange">
                {i18next.t('your-profile')}
            </Banner>
            <div className="w-full max-w-4xl mx-auto p-4 space-y-6 md:space-y-8">
                {feedbackElement}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('first-name')}</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.firstName || ''}
                            required
                            onType={(value) => updateField('firstName', value)}
                        />
                    </div>
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('last-name')}</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.lastName || ''}
                            required
                            onType={(value) => updateField('lastName', value)}
                        />
                    </div>
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('phone-number')}</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.phone || ''}
                            required
                            onType={(value) => updateField('phone', value)}
                        />
                    </div>
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('gender')}</Title>
                        <Select
                            isLoading={isLoading}
                            selected={profile.sex || '_UNKNOWN'}
                            onSelect={(selected) => updateField('sex', selected)}
                            options={{
                                [null]: {
                                    _MALE: i18next.t('male'),
                                    _FEMALE: i18next.t('female'),
                                    _UNKNOWN: i18next.t('other'),
                                },
                            }}
                        />
                    </div>
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('birthday')}</Title>
                        <Input
                            required
                            isLoading={isLoading}
                            color="white"
                            type="date"
                            value={profile.birthday || ''}
                            onType={(value) => updateField('birthday', value)}
                        />
                    </div>
                    <div className="space-y-2">
                        <Title size="md">{i18next.t('country')}</Title>
                        <Select
                            isLoading={areCountriesLoading || isLoading}
                            selected={profile.country?.toString()}
                            onSelect={(selected) => updateField('country', selected)}
                            options={countries}
                            required
                        />
                    </div>
                    <div className="space-y-2 md:col-span-2">
                        <Title size="md">{i18next.t('origin')}</Title>
                        <Input
                            required
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.origin || ''}
                            onType={(value) => updateField('origin', value)}
                        />
                    </div>
                </div>
            </div>
            <Banner borderColor="orange" color="orange">{i18next.t('about-you')}</Banner>
            <div className="w-full max-w-4xl mx-auto p-4 space-y-6 md:space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                    <div className="space-y-2 md:col-span-2">
                        <Title size="md">LinkedIn</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.linkedin || ''}
                            onType={(value) => updateField('linkedin', value)}
                        />
                    </div>
                    <div className="space-y-2 md:col-span-1">
                        <Title size="md">GitHub</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.github || ''}
                            onType={(value) => updateField('github', value)}
                        />
                    </div>
                    <div className="space-y-2 md:col-span-1">
                        <Title size="md">{i18next.t('website')}</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.web || ''}
                            onType={(value) => updateField('web', value)}
                        />
                    </div>
                    <div className="space-y-2 md:col-span-1">
                        <Title size="md">Twitter</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.twitter || ''}
                            onType={(value) => updateField('twitter', value)}
                        />
                    </div>
                    <div className="space-y-2 md:col-span-1">
                        <Title size="md">Facebook</Title>
                        <Input
                            isLoading={isLoading}
                            color="white"
                            type="text"
                            value={profile.facebook || ''}
                            onType={(value) => updateField('facebook', value)}
                        />
                    </div>
                </div>
                <div className="space-y-4">
                    <Title size="md">{i18next.t('biography')}</Title>
                    <Input
                        isLoading={isLoading}
                        color="white"
                        type="textarea"
                        value={profile.biography || ''}
                        onType={(value) => updateField('biography', value)}
                    />
                </div>
                <div className="pt-6">
                    <Button
                        isLoading={areCountriesLoading || isLoading || updateUserMutation.isLoading}
                        color="orange"
                        icon={faPaperPlane}
                        onClick={handleUpdateUser}
                    >
                        {i18next.t('update')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ProfileAboutForm;