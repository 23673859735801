/**
 * getJobs gets all the job information of the logged in user
 * @method GET
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<Array<{
*       jobID: number | null;
*       title: string | null;
*       type: string | null;
*       company: string | null;
*       countryID: number | null;
*       country: string | null;
*       startDate: string | null;
*       endDate: string | null;
*       description: string | null;
*       currency: string | null;
*       salary: number | null;
* }>>}
*/
export default async function getJobs(jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/jobs`;
       const method = 'GET';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           Authorization: jwt,
       };

       const response = await fetch(url, { method, headers });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data.jobs;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}
