import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import useToast from '../../hooks/useToast';
import Button from '../atoms/Button';
import CardToggle from '../molecules/CardToggle';
import createCertification from '../../services/createCertification';
import deleteCertification from '../../services/deleteCertification';
import updateCertification from '../../services/updateCertification';
import CertificationCreateModal from '../modals/CertificationCreateModal';
import CertificationDeleteModal from '../modals/CertificationDeleteModal';
import CertificationUpdateModal from '../modals/CertificationUpdateModal';
import useModal from '../../hooks/useModal';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';
import formatDate from '../../utils/formatDate';

function CertificationHistory({ session, userCertifications, areCertificationsLoading }) {
    const queryClient = useQueryClient();
    const queryKey = [session, 'userCertifications'];

    const { createToast, toastElement } = useToast();
    const { createModal, removeModal } = useModal();

    // Certification Refs
    const institutionNameRef = useRef();
    const nameRef = useRef();
    const issueDateRef = useRef();
    const credentialIDRef = useRef();
    const credentialURLRef = useRef();

    const certificationCreateMutation = useMutation({
        mutationFn: (certi) =>
            createCertification(
                certi.name,
                certi.institutionName,
                certi.issueDate,
                certi.credentialID,
                certi.credentialURL,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('certification-created-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Create a new certification
     */
    const handleCertificationCreate = () => {
        const onAccept = () => {
            if (
                !nameRef.current.value ||
                !institutionNameRef.current.value ||
                !issueDateRef.current.value ||
                !credentialURLRef.current.value
            ) {
                return;
            }

            certificationCreateMutation.mutate({
                name: nameRef.current.value,
                institutionName: institutionNameRef.current.value,
                issueDate: issueDateRef.current.value,
                credentialID: credentialIDRef.current.value,
                credentialURL: credentialURLRef.current.value,
            });
            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationCreateModal
                nameRef={nameRef}
                institutionNameRef={institutionNameRef}
                issueDateRef={issueDateRef}
                credentialIDRef={credentialIDRef}
                credentialURLRef={credentialURLRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true,
        );
    };

    const certificationUpdateMutation = useMutation({
        mutationFn: (certi) =>
            updateCertification(
                certi.certificationID,
                certi.name,
                certi.institutionName,
                certi.issueDate,
                certi.credentialID,
                certi.credentialURL,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('certification-updated-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Update certification
     */
    const handleCertificationUpdate = (certiInfoObj) => {
        const onAccept = () => {
            if (
                !nameRef.current.value ||
                !institutionNameRef.current.value ||
                !issueDateRef.current.value ||
                !credentialURLRef.current.value
            )
                return;

            certificationUpdateMutation.mutate({
                certificationID: certiInfoObj.certificationID?.toString(),
                name: nameRef.current.value,
                institutionName: institutionNameRef.current.value,
                issueDate: issueDateRef.current.value,
                credentialID: credentialIDRef.current.value,
                credentialURL: credentialURLRef.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationUpdateModal
                nameRef={nameRef}
                institutionNameRef={institutionNameRef}
                issueDateRef={issueDateRef}
                credentialIDRef={credentialIDRef}
                credentialURLRef={credentialURLRef}
                onAccept={onAccept}
                onCancel={onCancel}
                certiInfoObj={{
                    certificationID: certiInfoObj && certiInfoObj.jobID ? certiInfoObj.jobID?.toString() : '',
                    name: certiInfoObj && certiInfoObj.name ? certiInfoObj.name : '',
                    institutionName: certiInfoObj && certiInfoObj.institutionName ? certiInfoObj.institutionName : '',
                    issueDate: certiInfoObj && certiInfoObj.issueDate ? certiInfoObj.issueDate : '',
                    credentialID: certiInfoObj && certiInfoObj.credentialID ? certiInfoObj.credentialID : '',
                    credentialURL: certiInfoObj && certiInfoObj.credentialURL ? certiInfoObj.credentialURL : '',
                }}
            />,
            true,
        );
    };

    const certificationDeleteMutation = useMutation({
        mutationFn: (certificationID) => deleteCertification(certificationID, session, i18next.language),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('certification-deleted-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    /**
     * Delete Certifications
     */
    const handleCertificationDelete = (certiInfo) => {
        // Delete a certification
        const onAccept = () => {
            removeModal();

            if (!certiInfo || !certiInfo.certificationID) return;

            certificationDeleteMutation.mutate(certiInfo.certificationID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationDeleteModal
                name={certiInfo.name}
                institutionName={certiInfo.institutionName}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
        );
    };

    return (
        <>
            <div className="w-full flex items-center">

                {toastElement}

                <div className="w-1/6 flex items-center justify-center">
                    <Button
                        circle
                        color="blue"
                        icon={faPlus}
                        title={i18next.t('create-new')}
                        onClick={handleCertificationCreate}
                    />
                </div>
                <div className="w-5/6">
                    <Banner color="white" borderColor="green">
                        {i18next.t('certifications')}
                    </Banner>
                </div>
            </div>
            {userCertifications != null && userCertifications !== false && (
                <div className="w-full flex items-center justify-center mt-1 mb-1">
                    {areCertificationsLoading ? (
                        <Skeleton
                            count={2}
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            className="mb-5 h-8"
                            containerClassName="w-full h-5"
                        />
                    ) : (
                        <div className="flex flex-col w-full gap-y-3">
                            {userCertifications &&
                                userCertifications.map((certiInfoObj) => (
                                    <CardToggle
                                        size="lg"
                                        key={certiInfoObj.certificationID}
                                        title={certiInfoObj.name}
                                    >
                                        <div className="flex flex-wrap">
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/3">
                                                    <Title size="sm">
                                                        {i18next.t('certification-name')}
                                                    </Title>
                                                </div>
                                                <div className="w-2/3">
                                                    <span>{certiInfoObj.name}</span>
                                                </div>
                                            </div>

                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/3">
                                                    <Title size="sm">
                                                        {i18next.t(
                                                            'certification-institution',
                                                        )}
                                                    </Title>
                                                </div>
                                                <div className="w-2/3">
                                                    <span>
                                                        {certiInfoObj.institutionName}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/3">
                                                    <Title size="sm">
                                                        {i18next.t(
                                                            'certification-issueDate',
                                                        )}
                                                    </Title>
                                                </div>
                                                <div className="w-2/3">
                                                    <span>
                                                        {formatDate(
                                                            certiInfoObj.issueDate,
                                                            true,
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            {certiInfoObj.credentialID != null &&
                                                certiInfoObj.credentialID !== false && (
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                        <div className="w-1/3">
                                                            <Title size="sm">
                                                                {i18next.t(
                                                                    'certification-credentialID',
                                                                )}
                                                            </Title>
                                                        </div>
                                                        <div className="w-2/3">
                                                            <span>
                                                                {certiInfoObj.credentialID}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/3">
                                                    <Title size="sm">
                                                        {i18next.t(
                                                            'certification-credentialURL',
                                                        )}
                                                    </Title>
                                                </div>
                                                <div className="w-2/3">
                                                    <span>
                                                        {certiInfoObj.credentialURL}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                            <div className="flex w-full lg:w-2/5">
                                                <Button
                                                    color="blue"
                                                    icon={faPenToSquare}
                                                    onClick={() =>
                                                        handleCertificationUpdate(
                                                            certiInfoObj,
                                                        )
                                                    }
                                                >
                                                    {i18next.t('edit')}
                                                </Button>
                                            </div>
                                            <div className="flex w-full lg:w-2/5">
                                                <Button
                                                    color="red"
                                                    icon={faTrash}
                                                    onClick={() =>
                                                        handleCertificationDelete({
                                                            certificationID:
                                                                certiInfoObj.certificationID,
                                                            name: certiInfoObj.name,
                                                            institutionName:
                                                                certiInfoObj.institutionName,
                                                        })
                                                    }
                                                >
                                                    {i18next.t('delete')}
                                                </Button>
                                            </div>
                                        </div>
                                    </CardToggle>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

CertificationHistory.propTypes = {
    session: PropTypes.string.isRequired,
    userCertifications: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.shape({
                certificationID: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                institutionName: PropTypes.string.isRequired,
                issueDate: PropTypes.string.isRequired,
                credentialID: PropTypes.string,
                credentialURL: PropTypes.string.isRequired,
            })
        ),
    ]).isRequired,
    areCertificationsLoading: PropTypes.bool.isRequired,
};

export default CertificationHistory;