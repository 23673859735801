import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import useSession from '../../hooks/useSession';
import getCompanyDetails from '../../services/getCompanyDetails';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Highlight from '../atoms/Highlight';

export default function CompanyInnovationResultsPage() {
    const { competitionGUID, userGUID } = useParams();
    const { session } = useSession();
    const navigate = useNavigate();

    const companyDetailsQuery = useQuery({
        queryKey: [session, 'companyDetails', competitionGUID, userGUID],
        queryFn: () => getCompanyDetails(competitionGUID, userGUID, session, i18next.language),
    });

    const handleBack = () => {
        navigate(-1);
    };

    if (companyDetailsQuery.isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-smorange-dark text-lg font-semibold">
                  <FontAwesomeIcon icon={faCircleNotch} size="4x" className="text-smorange-dark animate-spin" />
                </div>
            </div>
        );
    }

    if (companyDetailsQuery.isError) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-red-500 text-lg font-semibold">Error loading company details</div>
            </div>
        );
    }

    const {
        email,
        userName,
        guid,
        company,
        model,
        document,
        representative,
        team,
        answers,
    } = companyDetailsQuery.data;

    return (
      <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-8">
          {/* Header Section */}
          <div className="bg-white border-b-2 border-orange-200 overflow-hidden">
            <div className="px-4 py-5 sm:p-6 flex justify-between items-center">
              <Title size="xl">{i18next.t("results")}</Title>
              <div className="flex justify-between items-center px-4 rounded-lg">
                <div className="flex items-center gap-x-3" title={i18next.t('go-back')}>
                  <Button onClick={handleBack} color="gray" icon={faArrowLeftLong}>
                      {i18next.t('go-back')}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* General Information */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                General Information
              </Title>
              <div className="space-y-3">
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Email:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{email}</span>
                </div>
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Username:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{userName}</span>
                </div>
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">GUID:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{guid}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Company Details */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:p-6">
              <Title size="lg">
                Company Information
              </Title>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-4 mt-4">
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Tradename:</span>
                  <span className="w-2/3 text-gray-900">{company.tradename}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">ID Type:</span>
                  <span className="w-2/3 text-gray-900">{company.idType}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">ID Number:</span>
                  <span className="w-2/3 text-gray-900">{company.idNumber}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Year Established:</span>
                  <span className="w-2/3 text-gray-900">{company.year}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Area:</span>
                  <span className="w-2/3 text-gray-900">{company.area}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Size:</span>
                  <span className="w-2/3 text-gray-900">{company.size}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Address:</span>
                  <span className="w-2/3 text-gray-900">{company.address}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Phone:</span>
                  <span className="w-2/3 text-gray-900">{company.phone}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Country:</span>
                  <span className="w-2/3 text-gray-900">{company.country}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">City:</span>
                  <span className="w-2/3 text-gray-900">{company.city}</span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">Website:</span>
                  <span className="w-2/3 text-gray-900">
                    {company.url ? (
                      <a
                        href={company.url}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.url}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div className="flex gap-x-3 items-center">
                  <span className="w-1/3 font-medium text-gray-500">LinkedIn:</span>
                  <span className="w-2/3 text-gray-900">
                    {company.linkedIn ? (
                      <a
                        href={company.linkedIn}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.linkedIn}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>


          {/* Innovation Model */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                Innovation Model
              </Title>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Description:</span>
                  <span className="w-2/3 text-gray-900">{model.description}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Unique Difference:</span>
                  <span className="w-2/3 text-gray-900">{model.difference}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Product:</span>
                  <span className="w-2/3 text-gray-900">{model.product}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Level:</span>
                  <span className="w-2/3 text-gray-900">{model.level}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Creator:</span>
                  <span className="w-2/3 text-gray-900">{model.creator ? "Yes" : "No"}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Income Previous Year:</span>
                  <span className="w-2/3 text-gray-900">${model.incomePrevious}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Income Last Year:</span>
                  <span className="w-2/3 text-gray-900">${model.incomeLast}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">CAGR:</span>
                  <span className="w-2/3 text-gray-900">{model.cagr}%</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">CAC:</span>
                  <span className="w-2/3 text-gray-900">${model.cac}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">LTV:</span>
                  <span className="w-2/3 text-gray-900">${model.ltv}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Churn Rate:</span>
                  <span className="w-2/3 text-gray-900">{model.churnRate}%</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Clients:</span>
                  <span className="w-2/3 text-gray-900">{model.clients}</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Time to Market:</span>
                  <span className="w-2/3 text-gray-900">{model.time} months</span>
                </div>
                <div className="flex items-center gap-x-5">
                  <span className="w-1/3 font-medium text-gray-500">Profit:</span>
                  <span className="w-2/3 text-gray-900">${model.profit}</span>
                </div>
              </div>

              {model.patent && (
                <div className="flex flex-col gap-y-4 mt-4">
                  <Title size="md" className="mb-2">
                    Patent Information
                  </Title>
                  <div className="space-y-3">
                    <div className="flex flex-wrap items-center">
                      <span className="w-full sm:w-1/3 font-medium text-gray-500">Name:</span>
                      <span className="w-full sm:w-2/3 text-gray-900">{model.patent.name}</span>
                    </div>
                    <div className="flex flex-wrap items-center">
                      <span className="w-full sm:w-1/3 font-medium text-gray-500">Description:</span>
                      <span className="w-full sm:w-2/3 text-gray-900">{model.patent.description}</span>
                    </div>
                    <div className="flex flex-wrap items-center">
                      <span className="w-full sm:w-1/3 font-medium text-gray-500">Link:</span>
                      <span className="w-full sm:w-2/3 text-gray-900">
                        <a
                          href={model.patent.link}
                          className="text-blue-600 hover:underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {model.patent.link}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Documents */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                Documents
              </Title>
              {document.commercialRegistry ? (
                <div className="space-y-3">
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Name:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{document.commercialRegistry.name}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Description:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{document.commercialRegistry.description}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Link:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">
                      <a
                        href={document.commercialRegistry.link}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {document.commercialRegistry.link}
                      </a>
                    </span>
                  </div>
                </div>
              ) : (
                <p className="text-gray-600">No commercial registry information available.</p>
              )}
              {document.representativeID ? (
                <div className="mt-4 space-y-3">
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Name:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{document.representativeID.name}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Description:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{document.representativeID.description}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Link:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">
                      <a
                        href={document.representativeID.link}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {document.representativeID.link}
                      </a>
                    </span>
                  </div>
                </div>
              ) : (
                <p className="text-gray-600">No representative ID information available.</p>
              )}
              {document.formalizedCountry && (
                <div className="mt-4 flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Formalized Country:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{document.formalizedCountry}</span>
                </div>
              )}
            </div>
          </div>

          {/* Representative */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                Representative
              </Title>
              {representative && representative.length > 0 ? (
                representative.map((rep, index) => (
                  <div key={rep.idNumber} className={index > 0 ? "mt-6" : ""}>
                    <div className="space-y-3">
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">Name:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.name}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">ID Number:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.idNumber}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">Email:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.email}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">Phone:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.phone}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">Role:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.role}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">Country:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.country}</span>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <span className="w-full sm:w-1/3 font-medium text-gray-500">City:</span>
                        <span className="w-full sm:w-2/3 text-gray-900">{rep.city}</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No representative information available.</p>
              )}
            </div>
          </div>

          {/* Team */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                Team
              </Title>
              <div className="space-y-3">
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Founder:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{team?.founder || "N/A"}</span>
                </div>
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Full Team:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{team?.fullTeam || "N/A"}</span>
                </div>
                <div className="flex flex-wrap items-center">
                  <span className="w-full sm:w-1/3 font-medium text-gray-500">Work Team:</span>
                  <span className="w-full sm:w-2/3 text-gray-900">{team?.workTeam || "N/A"}</span>
                </div>
              </div>
              {team?.teamInfo && (
                <div className="mt-4 space-y-3">
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Team Name:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{team.teamInfo.name}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Description:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">{team.teamInfo.description}</span>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <span className="w-full sm:w-1/3 font-medium text-gray-500">Link:</span>
                    <span className="w-full sm:w-2/3 text-gray-900">
                      <a
                        href={team.teamInfo.link}
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {team.teamInfo.link}
                      </a>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Answers */}
          <div className="bg-white border-2 border-orange-200 rounded-lg overflow-hidden">
            <div className="flex flex-col gap-y-4 px-4 py-5 sm:p-6">
              <Title size="lg" className="mb-4">
                Answers
              </Title>
              <div className="space-y-6">
                {answers && answers.length > 0 ? (
                  answers.map((answer) => (
                    <div
                      key={answer.question}
                      className="rounded-lg border-2 border-orange-200 p-4"
                    >
                      <Highlight>
                        {answer.question}
                      </Highlight>
                      <p className="text-gray-600 leading-relaxed mt-4">{answer.answer || "No se ha proporcionado una respuesta."}</p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600 italic">No hay respuestas disponibles.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

