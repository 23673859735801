import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

function InputFile({ onChange, multiple, label }) {
    const inputId = `input-file-${Math.random().toString(36).substr(2, 9)}`;

    return (
        <div className="w-full">
            <label 
                htmlFor={inputId} 
                className="flex flex-col items-center justify-center gap-2 p-4 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-gray-400 transition-colors"
            >
                <FontAwesomeIcon icon={faCloudUploadAlt} className="text-gray-600 text-2xl" />
                <span className="text-sm text-gray-600">{label}</span>
                <input
                    id={inputId}
                    type="file"
                    className="hidden"
                    onChange={onChange}
                    multiple={multiple}
                />
            </label>
        </div>
    );
}

InputFile.propTypes = {
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    label: PropTypes.string,
};

InputFile.defaultProps = {
    multiple: false,
    label: 'Click or drop files here to upload',
};

export default InputFile;