/* eslint-disable no-nested-ternary */
import { faCalendar, faCalendarXmark, faClock, faFolderOpen, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useModal from '../../hooks/useModal';
import useSession from '../../hooks/useSession';
import getCompetitions from '../../services/getCompetitions';
import getDomain from '../../services/getDomain';
import getFinishedCompetitionDetails from '../../services/getFinishedCompetitionDetails';
import accessCamera from '../../utils/accessCamera';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';
import repeat from '../../utils/repeat';
import AlertBox from '../atoms/AlertBox';
import AccessCamera from '../modals/AccessCamera';
import FinishedCompetitionDetailsModal from '../modals/FinishedCompetitionDetailsModal';
import SkeletonModal from '../modals/SkeletonModal';
import StartCompetition from '../modals/StartCompetition';
import StartInnovationChallenge from '../modals/StartInnovationChallenge';
import Card from '../molecules/Card';
import Pager from '../molecules/Pager';
import CardInnovation from '../molecules/CardInnovation';
import Spinner from '../atoms/Spinner';
import validateCompanyDetails from '../../services/validateCompanyDetails';

const color = { 'not-started': 'blue', 'in-progress': 'green', finished: 'red' };

/**
 * Competitions organism creates a search engine and viewer for public/private/history/innovative competitions
 * @param {{
 *      visibility: 'all' | 'public' | 'private' | 'history';
 *      criteria: {
 *          name: string;
 *          status: ('not-started' | 'in-progress' | 'finished')[];
 *          categories: number[];
*           domain: string;
 *      }
 * }} props
 */
function Competitions({ visibility, criteria }) {
    const feedbackRef = useRef(null);

    const { session } = useSession();
    const { domain } = criteria;

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);

    const [isCompetitionLoading, setIsCompetitionLoading] = useState(false);

    const [competitionDetails, setCompetitionDetails] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);


    const competitionsQuery = useQuery({
        queryKey: ['competitions', visibility, page, criteria, session],
        queryFn: () => getCompetitions(visibility, page, criteria, session, i18next.language),
        enabled: visibility === 'public' || !!session,
        refetchOnMount: true,
    });

    
    const showInnovationDescription = domain === 'estratek.smartranks.co';

    const domainMutation = useMutation({
        mutationKey: ['competitions', 'rules'],
        mutationFn: (competition) => getDomain(competition, session, i18next.language),
    });

    const loadCompetitionDetails = async (guid) => {
        try {
            setLoadingDetails(true);
            const details = await getFinishedCompetitionDetails(guid, session, i18next.language);
            setCompetitionDetails(details);
        } finally {
            setLoadingDetails(false);
        }
    };

    useEffect(() => {
        if (competitionDetails && !loadingDetails) {
            const onCancel = () => {
                removeModal();
                setCompetitionDetails(null);
            };

            createModal(
                <FinishedCompetitionDetailsModal
                    competitionTitle={competitionDetails.competition.competition}
                    competitionDate={competitionDetails.competition.candidate.date}
                    competitionScore={competitionDetails.competition.score}
                    competitionCategories={competitionDetails.competition.categories}
                    onCancel={onCancel}
                />,
            );
        }
    }, [competitionDetails, loadingDetails, createModal, removeModal]);

    // Reset page to 0 when visibility changes
    useEffect(() => {
        setPage(0);
    }, [visibility]);

    const handleScrollToFeedback = () => {
        if (feedbackRef.current) {
            feedbackRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            feedbackRef.current.classList.add('animate-pulse');
            setTimeout(() => {
                feedbackRef.current.classList.remove('animate-pulse');
            }, 1000);
        }
    };

    const handleCompetition = async (guid, status, timeRemaining, startDate, endDate, duration) => {
        
        setIsCompetitionLoading(true);

        try {

            if (!session) {
                createFeedback('message', i18next.t('login-to-start'), true);
                navigate('/auth/sign-in');
                return;
            }

            if (startDate && new Date() < new Date(startDate)) {
                createFeedback('warn', i18next.t('competition-not-started-yet'));
                handleScrollToFeedback();
                return;
            }

            if (status === 'finished' && duration !== null) {
                createFeedback('warn', i18next.t('competition-is-over'));
                handleScrollToFeedback();
                return;
            }

            if (status === 'finished' && duration == null && !competitionDetails && !loadingDetails) {
                removeFeedback();
                createModal(<SkeletonModal />, true);
                loadCompetitionDetails(guid);
                return;
            }

            const domainRules = await domainMutation.mutateAsync(guid);
            const rules = Array.isArray(domainRules.setting)
                ? domainRules.setting
                : domainRules.setting.Rules || [];

            if (rules.includes('FORCECAM')) {
                try {
                    await accessCamera();
                } catch (_) {
                    createModal(<AccessCamera onCancel={() => removeModal()} />);
                    return;
                }
            }

            if (domainRules.domain === 'estratek.smartranks.co') {
                const onAccept = async () => {

                    setIsCompetitionLoading(true);

                    try {
                        const validationResponse = await validateCompanyDetails(session, i18next.language);
                        
                        if (!validationResponse.complete) {
                            removeModal();
                            navigate(`/competitions/innovation/register-company`);
                            return;
                        }
                        removeModal();
                        navigate(`/competitions/${visibility}/${guid}`);
                    } catch (error) {
                        createFeedback("error", 'Error validating company details');
                    } finally {
                        setIsCompetitionLoading(false);
                    }
                };

                const onCancel = () => {
                    removeModal();
                };
    
                createModal(
                    <StartInnovationChallenge 
                        onAccept={onAccept}
                        onCancel={onCancel} 
                        isLoading={isCompetitionLoading}
                    />
                );
                return;
            }

            if (status !== 'in-progress' && timeRemaining !== null) {
                const onAccept = () => {
                    removeModal();
                    navigate(`/competitions/${visibility}/${guid}`);
                };
                const onCancel = () => {
                    removeModal();
                };

                createModal(<StartCompetition time={timeRemaining} onAccept={onAccept} onCancel={onCancel} />, true);
                return;
            }

            navigate(`/competitions/${visibility}/${guid}`);
        } finally {
            setIsCompetitionLoading(false);
        }
    }

    return (
        <div className="w-full flex flex-col gap-5">

            <Spinner isLoading={isCompetitionLoading} message={i18next.t('loading')} type="info" />

            <div ref={feedbackRef} className="transition-opacity duration-500 ease-in-out">
                {feedbackElement}
            </div>

            {showInnovationDescription && (
                <AlertBox color="orange">
                    <p className="text-sm">
                        Los retos de innovación abierta son una forma dinámica y colaborativa de abordar problemas complejos mediante la participación de diversas comunidades, empresas, investigadores y emprendedores. Estos desafíos buscan soluciones disruptivas e innovadoras que combinen conocimiento técnico, creatividad, y un espíritu emprendedor. Participar en un reto de este tipo te permite no solo contribuir al avance en áreas críticas, sino también conectar con una red global de innovadores, recibir visibilidad y, en muchos casos, acceder a premios que impulsan tu proyecto al siguiente nivel.
                        <br />
                        <br />
                        Esta metodología fomenta el intercambio de ideas en un ecosistema abierto, promoviendo la co-creación y el desarrollo de propuestas que pueden ser aplicadas tanto a nivel empresarial como social. Los retos son oportunidades para aprender, crecer y transformar industrias al convertir ideas en realidades tangibles.
                    </p>
                </AlertBox>
            )}

            {competitionsQuery.isLoading ? (
                <div className="w-full h-min grid lg:grid-cols-2 grid-cols-1 gap-5">
                    {repeat(
                        parseInt(process.env.REACT_APP_COMPETITIONS_PER_PAGE, 10),
                        <Card isLoading title="" contents={[]} />,
                    )}
                </div>
            ) : !competitionsQuery.data ||
              !competitionsQuery.data.competitions ||
              competitionsQuery.data.competitions.length === 0 ? (
                <div className="w-full h-[70vh] flex flex-col gap-5 justify-center items-center">
                    <FontAwesomeIcon icon={faFolderOpen} size="5x" className="text-smgreen-dark" />
                    <p className="sm:text-2xl text-lg text-center">{i18next.t('no-competitions-found')}</p>
                </div>
            ) : (
                <div className="w-full h-min grid lg:grid-cols-2 grid-cols-1 gap-5">
                    {competitionsQuery.data.competitions.map((competition) => {
                        
                        const {
                            guid,
                            name,
                            status,
                            challenges,
                            categories,
                            duration,
                            timeRemaining,
                            startDate,
                            endDate,
                            owner,
                            prize,
                            prizeDate,
                            description,
                        } = competition;

                        if (owner.includes('Estratek')) {
                            return (
                                <CardInnovation
                                    key={`innovation:${guid}`}
                                    guid={guid}
                                    name={name}
                                    status={status && i18next.t(status)}
                                    categories={categories}
                                    startDate={startDate}
                                    endDate={endDate}
                                    owner={owner}
                                    prize={prize}
                                    prizeDate={prizeDate}
                                    description={description}
                                    onClick={() => handleCompetition(guid, status, timeRemaining, startDate, endDate, duration)}
                                />
                            );
                        }

                        const contents = [];

                        if (status === 'in-progress') {
                            contents.push({
                                alt: i18next.t('time-remaining'),
                                icon: faHourglassHalf,
                                text: timeRemaining ? formatTime(timeRemaining, 2) : i18next.t('unlimited'),
                            });
                        } else if (!owner.includes('Estratek')) {
                            contents.push({
                                alt: i18next.t('duration'),
                                icon: faClock,
                                text: duration ? formatTime(duration, 2) : i18next.t('unlimited'),
                            });
                        }

                        if (startDate) {
                            contents.push({
                                alt: i18next.t('start-date'),
                                icon: faCalendar,
                                text: formatDate(startDate),
                            });
                        }

                        if (endDate) {
                            contents.push({
                                alt: i18next.t('end-date'),
                                icon: faCalendarXmark,
                                text: formatDate(endDate),
                            });
                        }

                        if (prize) {
                            contents.push({
                                alt: i18next.t('prize'),
                                icon: faFolderOpen,
                                text: prize,
                            });
                        }
                        
                        if (prizeDate?.date) {
                            contents.push({
                                alt: i18next.t('prize-date'),
                                icon: faCalendar,
                                text: formatDate(prizeDate.date),
                            });
                        }

                        // Competition is public based on duration (ToDo: Esto hay que cambiarlo)
                        const isPublic = duration === null;

                        // Show the visual label only for finished competitions in the history
                        const showVisualLabel = status === 'finished' && visibility === 'history';

                        return (
                            <Card
                                key={`competition:${guid}`}
                                title={name}
                                tag={status && i18next.t(status)}
                                color={color[status]}
                                contents={contents}
                                tags={categories}
                                detail={`${challenges} ${i18next.t('challenges')}`}
                                onClick={() =>
                                    handleCompetition(guid, status, timeRemaining, startDate, endDate, duration)
                                }
                                isPublic={showVisualLabel ? isPublic : undefined}
                            />
                        );
                    })}
                </div>
            )}

            {competitionsQuery.data && competitionsQuery.data.competitions.length !== 0 && (
                <div className="w-full flex justify-center">
                    <Pager
                        isLoading={competitionsQuery.isLoading}
                        pages={competitionsQuery.data.pages || 5}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            )}
        </div>
    );
}

Competitions.propTypes = {
    visibility: PropTypes.oneOf(['all', 'public', 'private', 'history']).isRequired,
    criteria: PropTypes.shape({
        name: PropTypes.string.isRequired,
        status: PropTypes.arrayOf(PropTypes.oneOf(['not-started', 'in-progress', 'finished'])).isRequired,
        categories: PropTypes.arrayOf(PropTypes.number).isRequired,
        domain: PropTypes.string.isRequired,
    }).isRequired,
};

export default Competitions;
