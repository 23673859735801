/**
 * Calculates a SHA-256 hash from a given File.
 * Uses the Web Crypto API
 * 
 * @param {File} file - The file from which to calculate the hash.
 * @returns {Promise<string>} The SHA-256 hash as a hexadecimal string.
 */
export default async function calculateFileHash(file) {
    const arrayBuffer = await file.arrayBuffer();

    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
}
