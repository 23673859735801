/**
 * getInnovationResults fetches the innovation results for a competition
 * @method POST
 * @param {string} guid
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      guid: string;
*      email: string;
*      tradename: string;
*      score: number;
*      justification: string;
* }[]>}
*/
export default async function getInnovationResults(guid, jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${guid}/innovation/results`;
       const method = 'POST';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           Authorization: jwt,
       };
       const body = JSON.stringify({}); // Pending: Add body parameters if necessary (page, perPage, etc.)

       const response = await fetch(url, { method, headers, body });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}