import PropTypes from 'prop-types';

/**
 * TruncatedText atom displays a given text, but limited to a specified number of words.
 * @param {{
 *   text: string;
 *   maxWords: number;
 * }} props
 */
function TruncatedText({ text, maxWords }) {
    const words = text.trim().split(/\s+/);

    if (words.length <= maxWords) {
        return <p>{text}</p>;
    }

    const truncated = words.slice(0, maxWords).join(' ');
    return <p>{truncated}...</p>;
}   

TruncatedText.propTypes = {
    text: PropTypes.string.isRequired,
    maxWords: PropTypes.number,
};

TruncatedText.defaultProps = {
    maxWords: 100,
};

export default TruncatedText;