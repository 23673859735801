import { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import getDomainSettings from '../services/getDomainSettings';

const DomainSettingsContext = createContext();

function DomainSettingsProvider({ children }) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['domainSettings', i18next.language],
    queryFn: () => getDomainSettings(i18next.language),
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  // Extract raw settings from the API
  const rawSettings = useMemo(() => {
    if (!data || !data.setting) return {};
    return {
      primaryColor: data.setting.primaryColor ? data.setting.primaryColor[0] : null,
      secondaryColor: data.setting.secondaryColor ? data.setting.secondaryColor[0] : null,
      logo: data.setting.logo ? data.setting.logo[0] : null,
      banner: data.setting.banner ? data.setting.banner[0] : null,
      // Add other settings as needed.
    };
  }, [data]);

  // Read default colors from CSS variables defined in :root.
  // These variables are defined in the file index.css
  // as follows:
  // :root {
  //   --default-primary-color: #fed7aa;   /* Example: smorange DEFAULT */
  //   --default-secondary-color: #A6D8B6;  /* Example: smgreen DEFAULT */
  //   --primary-color: var(--default-primary-color);
  //   --secondary-color: var(--default-secondary-color);
  // }
  const defaultColors = useMemo(() => {
    if (typeof window !== 'undefined') {
      const computedStyles = getComputedStyle(document.documentElement);
      return {
        primaryColor:
          computedStyles.getPropertyValue('--default-primary-color').trim() || '#fed7aa',
        secondaryColor:
          computedStyles.getPropertyValue('--default-secondary-color').trim() || '#A6D8B6',
      };
    }
    return { primaryColor: '#fed7aa', secondaryColor: '#A6D8B6' };
  }, []);

  // Merge API settings with default values.
  // If the API does not return a color, the default value is used.
  const resolvedSettings = useMemo(() => ({
      ...rawSettings,
      primaryColor: rawSettings.primaryColor || defaultColors.primaryColor,
      secondaryColor: rawSettings.secondaryColor || defaultColors.secondaryColor,
    }), [rawSettings, defaultColors]);

  // Update the global CSS variables so that any style using
  // var(--primary-color) or var(--secondary-color) updates automatically.
  useEffect(() => {
    if (resolvedSettings.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', resolvedSettings.primaryColor);
    }
    if (resolvedSettings.secondaryColor) {
      document.documentElement.style.setProperty('--secondary-color', resolvedSettings.secondaryColor);
    }
  }, [resolvedSettings]);

  const contextValue = useMemo(
    () => ({ settings: resolvedSettings, isLoading, error }),
    [resolvedSettings, isLoading, error]
  );

  return (
    <DomainSettingsContext.Provider value={contextValue}>
      {children}
    </DomainSettingsContext.Provider>
  );
}

const useDomainSettings = () => {
  const context = useContext(DomainSettingsContext);
  if (!context) {
    throw new Error('useDomainSettings must be used within a DomainSettingsProvider');
  }
  return context;
};

DomainSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DomainSettingsProvider, useDomainSettings };