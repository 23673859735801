import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { useCallback, useRef, useState } from 'react';
import useFeedback from '../../hooks/useFeedback';
import useCountriesQuery from '../../hooks/useCountriesQuery';
// import useRecaptcha from '../../hooks/useRecaptcha'; // ToDo: falta esto
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Title from '../atoms/Title';
import registerCompany from '../../services/registerCompany';

const CURRENT_YEAR = new Date().getFullYear();
const MIN_YEAR = 1819;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
const PASSWORD_MIN_LENGTH = 8;

const SIZE_OPTIONS = {
    '1-10': '1-10',
    '10-50': '10-50',
    '50-100': '50-100',
    '100-500': '100-500',
    '500-1000': '500-1000',
    '+1000': '+1000',
};

const SIZE_OPTIONS_ORDER = ['1-10', '10-50', '50-100', '100-500', '500-1000', '+1000'];

const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) return i18next.t('email-required');
    if (!EMAIL_REGEX.test(trimmedEmail)) return i18next.t('email-regex');
    return null;
};

const validateYear = (year) => {
    const numYear = Number(year);
    if (!numYear) return i18next.t('year-required');
    if (numYear > CURRENT_YEAR || numYear < MIN_YEAR) {
        return i18next.t('year-not-allowed');
    }
    return null;
};

const validateUrl = (url) => {
    if (!url) return null; // Optional field
    const trimmedUrl = url.trim();
    if (!URL_REGEX.test(trimmedUrl)) return i18next.t('url-regex');
    return null;
};

// eslint-disable-next-line arrow-body-style
const validateSingleSpace = (value) => {
    // It returns true if it does not have more than one consecutive space
    return !/\s{2,}/.test(value);
};

/**
 * Company Register organism creates a form to create a company user with various details
 */
function CompanyRegister() {
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    // const recaptcha = useRecaptcha({ mode: 'register' });

    const feedbackRef = useRef(null);

    // Fetch countries from backend
    const { data: countries, isCountryListLoading } = useCountriesQuery();

    const [formData, setFormData] = useState({
        tradename: '',
        nit: '',
        email: '',
        password: '',
        repeatPassword: '',
        url: '',
        linkedin: '',
        address: '',
        phone: '',
        year: '',
        area: '',
        selectedCountry: '',
        size: '',
        representativeName: '',
        representativeIdentificationNumber: '',
        representativePhone: '',
        representativeEmail: '',
        role: '',
        representativeCountry: '',
        representativeCity: '',
        representativeLinkedIn: '',
    });
    
    const handleChange = useCallback((field) => (value) => {
        let newValue = value;
    
        if (['tradename', 'year', 'area', 'phone', 'address'].includes(field)) {
            // Remove extra spaces from the value
            if (validateSingleSpace(newValue)) {
                setFormData(prev => ({
                    ...prev,
                    [field]: newValue
                }));
            }
        } else if (['nit', 'email', 'url', 'linkedin', 'representativeEmail', 'representativeLinkedIn'].includes(field)) {
            // Remove spaces from the value
            newValue = newValue.replace(/\s/g, '').toLowerCase();
            setFormData(prev => ({
                ...prev,
                [field]: newValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [field]: newValue
            }));
        }
    }, []);


    const scrollToFeedback = () => {
        if (feedbackRef.current) {
            feedbackRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const validateForm = useCallback(() => {
        if (!formData.tradename || !formData.nit || !formData.area || !formData.selectedCountry || !formData.phone) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            scrollToFeedback();
            return false;
        }

        const emailError = validateEmail(formData.email);
        if (emailError) {
            createFeedback('warn', emailError);
            scrollToFeedback();
            return false;
        }

        if (!formData.password) {
            createFeedback('warn', i18next.t('password-required'));
            scrollToFeedback();
            return false;
        }

        if (formData.password.length < PASSWORD_MIN_LENGTH) {
            createFeedback('warn', i18next.t('password-regex'));
            scrollToFeedback();
            return false;
        }

        if (formData.password !== formData.repeatPassword) {
            createFeedback('warn', i18next.t('passwords-not-match'));
            scrollToFeedback();
            return false;
        }

        const yearError = validateYear(formData.year);
        if (yearError) {
            createFeedback('warn', yearError);
            scrollToFeedback();
            return false;
        }

        if (formData.url) {
            const urlError = validateUrl(formData.url);
            if (urlError) {
                createFeedback('warn', urlError);
                scrollToFeedback();
                return false;
            }
        }

        if (formData.linkedin && !formData.linkedin.includes('linkedin.com')) {
            createFeedback('warn', i18next.t('invalid-linkedin'));
            scrollToFeedback();
            return false;
        }

        if (!formData.size) {
            createFeedback('warn', i18next.t('size-required'));
            scrollToFeedback();
            return false;
        }

        return true;
    }, [formData, createFeedback]);

    // Register company
    const registerMutation = useMutation({
        mutationFn: () => registerCompany(
            formData.email,
            formData.password,
            formData.tradename,
            formData.nit,
            formData.url,
            formData.linkedin,
            formData.address,
            formData.phone,
            formData.year,
            formData.area,
            formData.selectedCountry,
            formData.city,
            formData.size,
            formData.representativeName,
            formData.representativeIdentificationNumber,
            formData.representativePhone,
            formData.representativeEmail,
            formData.role,
            formData.representativeCountry,
            formData.representativeCity,
            formData.representativeLinkedIn,
            i18next.language
        ),
        onMutate: removeFeedback,
        onError: (error) => {
            createFeedback(error.context, error.messages);
            scrollToFeedback();
        },
        onSuccess: (data) => {
            createFeedback(data.context, data.messages);
            setFormData({
                tradename: '',
                nit: '',
                email: '',
                password: '',
                repeatPassword: '',
                url: '',
                linkedin: '',
                address: '',
                phone: '',
                year: '',
                area: '',
                selectedCountry: null,
                size: '',
                representativeName: '',
                representativeIdentificationNumber: '',
                representativePhone: '',
                representativeEmail: '',
                role: '',
                representativeCountry: '',
                representativeCity: '',
                representativeLinkedIn: '',
            });

            scrollToFeedback();
        },
    });

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (validateForm()) {
            registerMutation.mutate();
        }
    }, [validateForm, registerMutation]);

    return (
        <div className="w-full flex flex-col gap-5">
            <Container>
                <div className="my-4" ref={feedbackRef}>
                    {feedbackElement}
                </div>
                <form className="w-full px-5 py-7 flex flex-col gap-7 items-center" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col gap-2 items-center">
                        <Title size="xl">{i18next.t('company-register')}</Title>
                        <Title size="md">{i18next.t('company-journey-start')}</Title>
                    </div>

                    <div className="w-full space-y-6">
                        {/* 0. Registro de usuario (datos de acceso) */}
                        <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                            <Title size="lg">Información de Registro</Title>
                            <div className="grid grid-cols-1 mt-3">
                                <Input
                                    value={formData.email}
                                    onType={handleChange('email')}
                                    label="Correo electrónico para el registro"
                                    labelColor="gray"
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                <Input
                                    value={formData.password}
                                    onType={handleChange('password')}
                                    type="password"
                                    placeholder="Contraseña"
                                    required
                                />
                                <Input
                                    value={formData.repeatPassword}
                                    onType={handleChange('repeatPassword')}
                                    type="password"
                                    placeholder="Repetir contraseña"
                                    required
                                />
                            </div>
                        </section>
                        {/* 1. DATOS DE QUIEN HACE LA INSCRIPCIÓN */}
                        <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                            <Title size="xl">Datos de Inscripción</Title>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
                                <Input
                                    value={formData.representativeName}
                                    onType={handleChange('representativeName')}
                                    label="Nombre de quien hace la inscripción"
                                    labelColor="gray"
                                />
                                <Input
                                    value={formData.representativeIdentificationNumber}
                                    onType={handleChange('representativeIdentificationNumber')}
                                    label="Documento de identificación"
                                    labelColor="gray"
                                />
                                <Input
                                    value={formData.role}
                                    onType={handleChange('role')}
                                    label="Rol en la startup"
                                    labelColor="gray"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
                                <Input
                                    value={formData.representativeEmail}
                                    onType={handleChange('representativeEmail')}
                                    label="Correo electrónico"
                                    labelColor="gray"
                                />
                                <Input
                                    value={formData.representativePhone}
                                    onType={handleChange('representativePhone')}
                                    label="Celular"
                                    labelColor="gray"
                                />
                                <Input
                                    value={formData.representativeLinkedIn}
                                    onType={handleChange('representativeLinkedIn')}
                                    label="Enlace perfil de Linkedin"
                                    labelColor="gray"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                <div className="mt-7">
                                    <Select
                                        color="white"
                                        isLoading={isCountryListLoading}
                                        selected={formData.representativeCountry}
                                        onSelect={handleChange('representativeCountry')}
                                        options={countries || {}}
                                        placeholder="País"
                                    />
                                </div>
                                <Input
                                    value={formData.representativeCity}
                                    onType={handleChange('representativeCity')}
                                    label="Ciudad"
                                    labelColor="gray"
                                />
                            </div>
                        </section>

                        {/* 2. INFORMACIÓN BÁSICA DE LA STARTUP */}
                        <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                            <Title size="xl">Información Básica de la Startup</Title>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
                                <Input
                                    value={formData.tradename}
                                    onType={handleChange('tradename')}
                                    label="Nombre de la startup"
                                    labelColor="gray"
                                    required
                                />
                                <Input
                                    value={formData.nit}
                                    onType={handleChange('nit')}
                                    label="NIT/Equivalente"
                                    labelColor="gray"
                                    required
                                />
                                <Input
                                    value={formData.phone}
                                    onType={handleChange('phone')}
                                    label="Teléfono"
                                    labelColor="gray"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                <div className="mt-7">
                                    <Select
                                        color="white"
                                        isLoading={isCountryListLoading}
                                        selected={formData.selectedCountry}
                                        onSelect={handleChange('selectedCountry')}
                                        options={countries || {}}
                                        placeholder="País de Origen"
                                        required
                                    />
                                </div>
                                <Input
                                    value={formData.city}
                                    onType={handleChange('city')}
                                    label="Ciudad de Origen"
                                    labelColor="gray"
                                    required
                                />
                                <Input
                                    value={formData.address}
                                    onType={handleChange('address')}
                                    label="Dirección"
                                    labelColor="gray"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                <Input
                                    value={formData.linkedin}
                                    onType={handleChange('linkedin')}
                                    label="LinkedIn de la Empresa"
                                    labelColor="gray"
                                />
                                <Input
                                    value={formData.year}
                                    onType={handleChange('year')}
                                    type="number"
                                    label="Año de constitución de la empresa"
                                    labelColor="gray"
                                    required
                                />
                                <Input
                                    value={formData.area}
                                    onType={handleChange('area')}
                                    label="Industria"
                                    labelColor="gray"
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-1">
                                <Input
                                    value={formData.url}
                                    onType={handleChange('url')}
                                    label="Sitio web"
                                    labelColor="gray"
                                    placeholder="https://smartranks.co"
                                />
                                <div className="mt-7">
                                    <Select
                                        color="white"
                                        isLoading={isCountryListLoading}
                                        selected={formData.size}
                                        onSelect={handleChange('size')}
                                        options={{ [null]: SIZE_OPTIONS }}
                                        order={SIZE_OPTIONS_ORDER}
                                        placeholder={i18next.t('company-size')}
                                        required
                                    />
                                </div>
                            </div>
                        </section>
                    </div>

                    <Button 
                        isLoading={registerMutation.isLoading} 
                        submit
                        color="orange" 
                        icon={faBuilding}
                    >
                        {i18next.t('sign-up')}
                    </Button>
                </form>
            </Container>
        </div>
    );
}

export default CompanyRegister;