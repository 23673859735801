import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/**
 * Button atom creates an iconized button with a loading state
 * @param {{
 *      isLoading: boolean;
 *      submit: boolean;
 *      color: 'white' | 'green' | 'blue' | 'orange' | 'red' | 'gray';
 *      circle: boolean;
 *      icon: object | undefined;
 *      onClick: (() => void) | undefined;
 *      children: string;
 *      title: string | undefined;
 *      disabled: boolean;  -- Disables the button and applies a "not-allowed" cursor if true
 * }} props
 */
function Button({ isLoading, submit, color, circle, icon, onClick, children, title, disabled }) {
    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                circle={circle}
                borderRadius={6}
                containerClassName={classNames(circle ? 'w-10 h-10 flex-shrink-0' : 'w-full min-w-[180px] h-10')}
            />
        );
    }

    return (
        <button
            type={submit ? 'submit' : 'button'}
            onClick={onClick}
            className={classNames(
                'min-w-fit flex gap-3 justify-center items-center shadow-md select-none outline-none active:translate-y-1 transition-transform duration-75',
                circle ? 'w-10 h-10 flex-shrink-0 rounded-full' : 'w-full h-min px-5 py-2.5 rounded-md',
                {
                    'bg-white text-black': color === 'white',
                    'bg-smgreen text-black': color === 'green',
                    'bg-smblue text-black': color === 'blue',
                    'bg-smorange text-black': color === 'orange',
                    'bg-smred text-black': color === 'red',
                    'bg-gray-200 text-black': color === 'gray',
                    'cursor-not-allowed': disabled,
                    'cursor-pointer': !disabled,
                },
            )}
            title={title}
            disabled={disabled}
        >
            {icon && <FontAwesomeIcon icon={icon} className="aspect-square" />}
            {children && <p className="text-sm font-semibold">{children}</p>}
        </button>
    );
}

Button.propTypes = {
    isLoading: PropTypes.bool,
    submit: PropTypes.bool,
    color: PropTypes.oneOf(['white', 'green', 'blue', 'orange', 'red', 'gray']).isRequired,
    circle: PropTypes.bool,
    icon: PropTypes.shape(),
    onClick: PropTypes.func,
    children: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    isLoading: false,
    submit: false,
    circle: false,
    icon: undefined,
    onClick: undefined,
    children: '',
    title: undefined,
    disabled: false,
};

export default Button;
