import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import repeat from '../../utils/repeat';
import Container from '../atoms/Container';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';

/**
 * Card molecule creates a clickable content viewer
 * @param {{
 *      isLoading: boolean;
 *      title: string;
 *      tag: string | null;
 *      color: 'green' | 'blue' | 'orange' | 'red' | undefined;
 *      contents: {
 *          icon: object;
 *          alt: string;
 *          text: string;
 *      }[];
 *      tags: string[] | null;
 *      detail: string | null;
 *      onClick: (() => void) | undefined;
 *      isPublic: boolean;
 * }} props
 */
function Card({ isLoading, title, tag, color, contents, tags, detail, onClick, isPublic }) {
    return (
        <Container onClick={onClick}>
            <div className="flex flex-col sm:gap-7 gap-5 sm:p-5 p-3">
                <div className="w-full flex gap-3 justify-between items-center">
                    <div className="w-full flex overflow-x-auto scrollbar-hidden whitespace-nowrap">
                        <Title isLoading={isLoading} size="lg">
                            {title}
                        </Title>
                    </div>

                    {tag !== null && (
                        <Tag isLoading={isLoading} color={color} size="md">
                            {tag}
                        </Tag>
                    )}

                    {isPublic !== undefined && (
                        <Tag isLoading={isLoading} color={isPublic ? 'blue' : 'orange'} size="sm">
                            {isPublic ? i18next.t('public') : i18next.t('private')}
                        </Tag>
                    )}
                </div>

                <div className="grid grid-cols-2 sm:gap-x-5 gap-x-4 sm:gap-y-3 gap-y-2">
                    {isLoading
                        ? repeat(
                              4,
                              <div className="flex sm:gap-3 gap-2">
                                  <Skeleton
                                      baseColor="#e4e4e7"
                                      highlightColor="#f4f4f5"
                                      height="100%"
                                      circle
                                      borderRadius={4}
                                      containerClassName="sm:w-5 w-4 sm:h-5 h-4"
                                  />
                                  <Skeleton
                                      baseColor="#e4e4e7"
                                      highlightColor="#f4f4f5"
                                      height="100%"
                                      borderRadius={4}
                                      containerClassName="flex-1 sm:h-5 h-4"
                                  />
                              </div>,
                          )
                        : contents.map((content) => (
                              <div
                                  key={`content:${content.alt}`}
                                  title={content.alt}
                                  className="flex gap-3 items-center text-smgray-dark"
                              >
                                  <FontAwesomeIcon icon={content.icon} className="sm:h-4 h-3.5" />
                                  <p className="sm:text-sm text-xs">{content.text}</p>
                              </div>
                          ))}
                </div>

                {(tags !== null || detail !== null) && (
                    <div className="flex sm:gap-5 gap-3 justify-between items-center">
                        {tags && tags.length > 0 && (
                            <div className="w-full flex gap-1 overflow-x-auto scrollbar-hidden">
                                {tags.map((tagname) => (
                                    <Tag isLoading={isLoading} key={`tag:${tagname}`} size="sm">
                                        {tagname}
                                    </Tag>
                                ))}
                            </div>
                        )}

                        {detail !== null && (
                            <div className={classNames(isLoading ? 'w-1/3' : 'min-w-fit w-min')}>
                                <Title isLoading={isLoading} size="sm">
                                    {detail}
                                </Title>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
}

Card.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    tag: PropTypes.string,
    color: PropTypes.oneOf(['green', 'blue', 'orange', 'red']),
    contents: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.object]),
            alt: PropTypes.string,
            text: PropTypes.string,
        }),
    ).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    detail: PropTypes.string,
    onClick: PropTypes.func,
    isPublic: PropTypes.bool,
};

Card.defaultProps = {
    isLoading: false,
    tag: '',
    color: undefined,
    tags: undefined,
    detail: '',
    onClick: undefined,
    isPublic: undefined,
};

export default Card;
