/**
 * registerCompanyInnovate creates additional innovative details for the company user
 * @method PUT
 * @param {string|number} formalizedCountry - The country where the company is formally registered.
 * @param {string} modelDescription - A description of the company's business model.
 * @param {string} product - A description of the company's product or service.
 * @param {string} difference - A description of what differentiates this company from others.
 * @param {boolean} creator - A boolean indicating if the user is the creator of the company.
 * @param {number} incomePrevious - The company's previous period income.
 * @param {number} incomeLast - The company's last period income.
 * @param {string} level - The company's maturity or development level.
 * @param {number} cagr - The company's Compound Annual Growth Rate.
 * @param {string|number} cac - The Customer Acquisition Cost metric for the company.
 * @param {string|number} ltv - The Lifetime Value (LTV) of the company's customers.
 * @param {string|number} churnRate - The churn rate metric for the company.
 * @param {number} clients - The number of clients the company has.
 * @param {string|number} time - A time-related metric (e.g., time in market or time per acquisition).
 * @param {string|number} profit - The company's profit figure.
 * 
 * @param {Object} commercialRegistry - An object representing the company's commercial registry document.
 * @param {string} commercialRegistry.hash - The hash of the commercial registry file.
 * @param {string} commercialRegistry.extension - The file extension of the commercial registry document.
 * 
 * @param {Object} representativeID - An object representing the representative's ID document.
 * @param {string} representativeID.hash - The hash of the representative ID file.
 * @param {string} representativeID.extension - The file extension of the representative ID document.
 * 
 * @param {Object} patent - An object representing the patent document.
 * @param {string} patent.hash - The hash of the patent file.
 * @param {string} patent.extension - The file extension of the patent document.
 * 
 * @param {Object} teamInfo - An object representing a file with team information.
 * @param {string} teamInfo.hash - The hash of the team information file.
 * @param {string} teamInfo.extension - The file extension of the team information document.
 * 
 * @param {string} founder - Information about the founder.
 * @param {number} fullTeam - Information about the full team.
 * @param {number} workTeam - Information about the working team or structure.
 * 
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
*      signaturePolicies?: Object;
* }>}
*/
export default async function registerCompanyInnovate(
   formalizedCountry,
   modelDescription,
   product,
   difference,
   creator,
   incomePrevious,
   incomeLast,
   level,
   cagr,
   cac,
   ltv,
   churnRate,
   clients,
   time,
   profit,
   commercialRegistry,
   representativeID,
   patent,
   teamInfo,
   founder,
   fullTeam,
   workTeam,
   jwt,
   language
) {
   try {
       const apiUrl = `${process.env.REACT_APP_APP_URL}/api/v1/users/complete/company`;
       const method = 'PUT';
       const body = {
           formalizedCountry,
           modelDescription,
           product,
           difference,
           creator,
           incomePrevious,
           incomeLast,
           level,
           cagr,
           cac,
           ltv,
           churnRate,
           clients,
           time,
           profit,
           commercialRegistry,
           representativeID,
           patent,
           teamInfo,
           founder,
           fullTeam,
           workTeam
       };

       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
            Authorization: jwt,
           'User-Agent': window.navigator.userAgent,
       };

       const response = await fetch(apiUrl, { method, headers, body: JSON.stringify(body) });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}