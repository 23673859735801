import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ActivateAccountPage from '../components/pages/ActivateAccountPage';
import AutoLoginPage from '../components/pages/AutoLoginPage';
import ChallengePage from '../components/pages/ChallengePage';
import ChallengeInnovationPage from '../components/pages/ChallengeInnovationPage';
import CompetitionsPage from '../components/pages/CompetitionsPage';
import ErrorPage from '../components/pages/ErrorPage';
import Home from '../components/pages/Home';
import LoginPage from '../components/pages/LoginPage';
import NotFoundPage from '../components/pages/NotFoundPage';
import ProfilePage from '../components/pages/ProfilePage';
import RecoverAccountPage from '../components/pages/RecoverAccountPage';
import RegisterPage from '../components/pages/RegisterPage';
import ResetPasswordPage from '../components/pages/ResetPasswordPage';

import ProtectedRoute from '../components/atoms/ProtectedRoutes';
import ProfileAboutForm from '../components/organisms/ProfileAboutForm';
import ProfilePasswordForm from '../components/organisms/ProfilePasswordForm';
import CompanyRegisterPage from '../components/pages/CompanyRegisterPage';
import RecruiterPage from '../components/pages/RecruiterPage';
import UserProfilePage from '../components/pages/UserProfilePage';
import Competition from '../v1/Competition'; // ! Remove on finish v2


import InnovationResultsPage from '../components/pages/InnovationResultsPage';

// Talent

import ProfileProfessionalForm from '../components/organisms/ProfileProfessionalForm';
import ProfileProfessionalHistory from '../components/organisms/ProfileProfessionalHistory';
import CompanyHomePage from '../components/pages/CompanyHomePage';
import CompanyInnovationResultsPage from '../components/pages/CompanyInnovationResultsPage';
import CompanyRegisterInnovate from '../components/organisms/CompanyRegisterInnovate';

// Admin

const ControlPanel = React.lazy(() => import('../components/pages/ControlPanel'));
const ControlPanelCategory = React.lazy(() => import('../components/organisms/ControlPanelCategory'));
const ControlPanelCompetition = React.lazy(() => import('../components/organisms/ControlPanelCompetition'));
const CompetitionCreatePage = React.lazy(() => import('../components/pages/CompetitionCreatePage'));
const CompetitionUpdatePage = React.lazy(() => import('../components/pages/CompetitionUpdatePage'));
const ControlPanelChallenge = React.lazy(() => import('../components/organisms/ControlPanelChallenge'));
const ControlPanelUser = React.lazy(() => import('../components/organisms/ControlPanelUser'));
const ControlPanelSkill = React.lazy(() => import('../components/organisms/ControlPanelSkill'));
const ControlPanelDomain = React.lazy(() => import('../components/organisms/ControlPanelDomain'));
const ControlPanelEstratek = React.lazy(() => import('../components/organisms/ControlPanelEstratek'));

export default createBrowserRouter([
    {
        path: '*',
        element: <NotFoundPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-in',
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-in/auto/:user/competitions/:competition',
        element: <AutoLoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-up',
        element: <RegisterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-up-company',
        element: <CompanyRegisterPage />,
        errorElement: <ErrorPage />,
    },
    // Innovation Register for Companies to participate in Innovation Challenges and are already registered
    {
        path: '/competitions/innovation/register-company',
        element: <CompanyRegisterInnovate />,
        errorElement: <ErrorPage />,
    },
    { 
        path: '/domain/home/company', 
        element: <CompanyHomePage />, 
        errorElement: <ErrorPage /> 
    },
    {
        path: '/auth/activate/:user',
        element: <ActivateAccountPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/recover',
        element: <RecoverAccountPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/recover/:user',
        element: <ResetPasswordPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility',
        element: <CompetitionsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility/:competition',
        element: <Competition />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility/:competition/challenges/:challenge',
        element: <ChallengePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility/:competition/challenges/:challenge/innovation',
        element: <ChallengeInnovationPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/profile',
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'about',
                element: <ProfileAboutForm />,
            },
            {
                path: 'security',
                element: <ProfilePasswordForm />,
            },
            {
                path: 'professional',
                element: <ProfileProfessionalForm />,
            },
            {
                path: 'history',
                element: <ProfileProfessionalHistory />,
            },
        ],
    },
    {
        path: '/profile/:competition',
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/profile/details/:guid',
        element: <UserProfilePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/recruiter',
        element: <RecruiterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/admin/panel',
        element: (
            <ProtectedRoute>
                <Suspense>
                    <ControlPanel />
                </Suspense>
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'categories',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelCategory />
                    </Suspense>
                ),
            },
            {
                path: 'competitions',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelCompetition />
                    </Suspense>
                ),
            },
            {
                path: 'challenges',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelChallenge />
                    </Suspense>
                ),
            },
            {
                path: 'users',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelUser />
                    </Suspense>
                ),
            },
            {
                path: 'skills',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelSkill />
                    </Suspense>
                ),
            },
            {
                path: 'domains',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelDomain />
                    </Suspense>
                ),
            },
            {
                path: 'estratek',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ControlPanelEstratek />
                    </Suspense>
                ),
            },
            {
                path: '/admin/panel/estratek/competitions/:guid',
                element: (
                    <Suspense fallback={<div>Loading Innovation Results...</div>}>
                        <InnovationResultsPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'estratek/competitions/:competitionGUID/users/:userGUID',
                element: (
                    <Suspense fallback={<div>Loading Company Details...</div>}>
                        <CompanyInnovationResultsPage />
                    </Suspense>
                ),
            },

        ],
    },

    {
        path: '/admin/panel/competitions/create',
        element: (
            <Suspense fallback={<div>Loading Create Competition...</div>}>
                <CompetitionCreatePage />
            </Suspense>
        ),
    },
    {
        path: '/admin/panel/competitions/update/:competition',
        element: (
            <Suspense fallback={<div>Loading Update Competition...</div>}>
                <CompetitionUpdatePage />
            </Suspense>
        ),
    },
]);
