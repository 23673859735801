export default async function validateCity(city) {
    try {
        const url = `https://nominatim.openstreetmap.org/search.php?q=${city}&format=json`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok || !data || data.length === 0) {
            const errorData = { controlled: true, data: { messages: 'Invalid city', context: 'error' } };
            throw errorData;
        }

        const validTypes = ['city', 'town', 'administrative'];
        const cityPlace = data.find(place => validTypes.includes(place.type));
        if (!cityPlace) {
            const errorData = { controlled: true, data: { messages: 'Not a valid city, town, or administrative area', context: 'error' } };
            throw errorData;
        }

        return { messages: 'Valid city', context: 'success' };
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}