/**
 * getDomain gets competition domain settings
 * @method GET
 * @param {string} competition
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      guid: string;
 *      name: string;
 *      domain: string;
 *      setting: {
 *          Rules: ('NOCOPY' | 'NOPASTE' | 'NOSELECTION' | 'FORCECAM' | 'FORCEPROFILE' | 'FORCETRANSLATE')[];
 *          [key: string]?: string;
 *      }
 * }>}
 */
export default async function getDomain(competition, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/domain/${competition}`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
