import { faBan, faQuestion, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * EducationDeleteModal allows the user to delete a education
 * @param {{
 *      institutionName: string;
 *      degree: string;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function EducationDeleteModal({ institutionName, degree, onAccept, onCancel }) {
    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex justify-center py-3">
                <FontAwesomeIcon icon={faQuestion} size="5x" className="text-smred-dark" />
            </div>
            <div className="text-center">
                <Title size="md">{i18next.t('education-confirm-delete')}</Title>
                <strong>{institutionName}</strong> - <strong>{degree}</strong>
            </div>
            <div className="w-full flex gap-5">
                <Button color="white" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="red" icon={faTrash} onClick={onAccept}>
                    { i18next.t('delete') }
                </Button>
            </div>
        </div>
    );
}

EducationDeleteModal.propTypes = {
    institutionName: PropTypes.string.isRequired,
    degree: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EducationDeleteModal;