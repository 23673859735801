import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useGoogleauth from '../../hooks/useGoogleauth';
import useRecaptcha from '../../hooks/useRecaptcha';
import useSession from '../../hooks/useSession';
import googleLogin from '../../services/googleLogin';
import login from '../../services/login';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Title from '../atoms/Title';

/**
 * Login organism creates a form to log in with email/password or Google
 */
function Login() {
    const { createSession } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const recaptcha = useRecaptcha({ mode: 'login' });
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();

    // Login user with email and password
    const loginMutation = useMutation({
        mutationFn: ({ email, password, recaptchaToken }) => login(email, password, recaptchaToken, i18next.language),
        onMutate: () => {
            removeFeedback();
        },
        onSuccess: (data) => {
            createSession(data.jwt);
            const userRole = data.role?.[0] || 'talent';

            if (userRole === 'talent') {
                navigate('/competitions/private');
            } else if (userRole === 'company') {
                navigate('/competitions/innovation');
            } else if(userRole === 'admin') {
                navigate('/admin/panel');
            } else {
                navigate('/competitions/private');
            }
        },
        onError: (error) => {
            if (error.status === 401) {
                createFeedback('error', i18next.t('invalid-credentials'));
                passwordRef.current.value = '';
                passwordRef.current.focus();
                return;
            }

            createFeedback('error', i18next.t('something-went-wrong'));
            emailRef.current.value = '';
            passwordRef.current.value = '';
        },
    });

    // Login user with Google
    const googleLoginMutation = useMutation({
        mutationFn: ({ googleToken, recaptchaToken }) => googleLogin(googleToken, recaptchaToken, i18next.language),
        onMutate: () => {
            removeFeedback();
        },
        onSuccess: (data) => {
            createSession(data.jwt);
            navigate('/competitions/private');
        },
        onError: (error) => {
            if (error.status === 401) {
                createFeedback('error', i18next.t('invalid-credentials'));
                passwordRef.current.value = '';
                passwordRef.current.focus();
                return;
            }

            createFeedback('error', i18next.t('something-went-wrong'));
            emailRef.current.value = '';
            passwordRef.current.value = '';
        },
    });

    /**
     * Run recaptcha for login
     * @param {React.FormEvent} e
     */
    const handleLogin = async (e) => {
        e.preventDefault();

        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;

        // Validate empty data
        if (!email || !password) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        const token = await recaptcha();
        loginMutation.mutate({ email, password, recaptchaToken: token });
    };

    /**
     * Run recaptcha for login with Google
     * @param {string} credential Google Token JWT
     */
    const handleGoogleLogin = async (credential) => {
        const token = await recaptcha();
        googleLoginMutation.mutate({ googleToken: credential, recaptchaToken: token });
    };

    // Create login with Google button
    const buttonRef = useGoogleauth({
        context: 'signin',
        width: 280,
        onSuccess: handleGoogleLogin,
    });

    return (
        <div className="w-full flex flex-col gap-5">
            {feedbackElement}
            <Container>
                <div className="w-full px-5 py-7 flex flex-col gap-7 items-center">
                    <div className="w-full flex flex-col gap-2 items-center">
                        <Title size="xl">{i18next.t('sign-in')}</Title>
                        <Title size="md">{i18next.t('welcome-to-smart-ranks')}</Title>
                    </div>

                    <p className="w-full text-center text-sm">
                        {i18next.t('policy-paragraph-1')}{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://smartalentit.com/politicas-de-privacidad-y-tratamiento-de-datos/"
                            className="text-blue-600 hover:underline"
                        >
                            {i18next.t('policy-paragraph-2')}
                        </a>
                    </p>

                    <div className="w-full flex flex-col gap-5">
                        <div className="w-full flex flex-col gap-3">
                            <div ref={buttonRef} className="w-full flex justify-center" />
                        </div>

                        <div className="w-full flex items-center select-none">
                            <hr className="w-full border border-zinc-300" />
                            <p className="mx-4 text-zinc-400 font-semibold uppercase">{i18next.t('or')}</p>
                            <hr className="w-full border border-zinc-300" />
                        </div>

                        <form onSubmit={handleLogin} className="w-full flex flex-col gap-3">
                            <Input
                                inputRef={emailRef}
                                color="gray"
                                type="email"
                                complete="username"
                                focus
                                placeholder={i18next.t('email')}
                            />
                            <Input
                                inputRef={passwordRef}
                                color="gray"
                                type="password"
                                complete="current-password"
                                placeholder={i18next.t('password')}
                            />

                            <Button
                                isLoading={loginMutation.isLoading || googleLoginMutation.isLoading}
                                submit
                                color="orange"
                                icon={faRightToBracket}
                            >
                                {i18next.t('sign-in')}
                            </Button>
                        </form>

                        <div className="w-full flex flex-col gap-3">
                            <div className="w-full flex justify-end">
                                <Link color="blue" to="/auth/recover">
                                    {i18next.t('forgot-password')}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex justify-center pb-1">
                        <Link color="blue" to="/auth/sign-up">
                            {i18next.t('join-smart-ranks')}
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Login;
