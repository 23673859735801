/**
 * validateInnovationAnswer validates innovation challenge answers
 * @method POST
 * @param {string} competition
 * @param {string} challenge
 * @param {Array} answers
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{ valid: number }>}
 */
export default async function validateInnovationAnswer(competition, challenge, answers, jwt, language) {
  try {
      const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/innovation/${competition}/challenges/${challenge}/validate`;
      const method = 'POST';
      const headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: jwt,
      };

      const response = await fetch(url, {
          method,
          headers,
          body: JSON.stringify({ answers }),
      });

      const data = await response.json();

      if (!response.ok) {
          const errorData = { controlled: true, data };
          throw errorData;
      }

      return data;
  } catch (error) {
      if (error.controlled) {
          throw error.data;
      }

      const errorData = { status: 500, messages: [error.message], context: 'error' };
      throw errorData;
  }
}