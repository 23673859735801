import { useMutation, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useCallback, useEffect, useRef, lazy, Suspense } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import getChallenge from '../../services/getChallenge';
import getCompetition from '../../services/getCompetition';
import recordEvents from '../../services/recordEvents';
import Countdown from '../atoms/Countdown';
import Challenges from '../organisms/Challenges';
import NavbarTemplate from '../templates/NavbarTemplate';

const Challenge = lazy(() => import('../organisms/Challenge'));

// import Challenge from '../organisms/Challenge';

/**
 * Challenge page shows the challenge and his interactions
 */
function ChallengePage() {
    const { visibility, competition, challenge } = useParams();
    const { session } = useSession();
    const { createFeedback } = useFeedback();
    const navigate = useNavigate();

    // Get competition view
    const competitionQuery = useQuery({
        queryKey: [session, 'competitions', competition],
        queryFn: () => getCompetition(competition, session, i18next.language),
        // refetchOnMount: true,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: 60000 * 5,
    });

    // Get challenge view
    const challengeQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges', challenge],
        queryFn: () => getChallenge(competition, challenge, session, i18next.language),
    });

    // Access camera
    useEffect(() => {
        if (competitionQuery.data?.name.toLowerCase().includes('sura')) {
            navigator.mediaDevices.getUserMedia({ video: true });
        }
    }, [competitionQuery.data]);

    // Record events
    const events = useRef([{ challenge, timing: 4 }]);
    const recordMutation = useMutation({
        mutationFn: () => recordEvents(competition, events.current, session, i18next.language),
        onMutate: () => {
            events.size = events.current.length;
        },
        onSuccess: () => {
            events.current = events.current.slice(events.size, events.current.length);
        },
    });

    // Run on record an event
    const handleEvent = useCallback(
        (event) => {
            if (competitionQuery.data?.name.toLowerCase().includes('sura')) {
                event.preventDefault();
            }

            if (event.type === 'keyup' && event.code !== 'F12') {
                return;
            }

            const types = { keyup: 1, copy: 2, cut: 2, paste: 3, focus: 4, blur: 5 };
            events.current.push({ challenge, timing: types[event.type] });
        },
        [challenge, competitionQuery.data],
    );

    // Add event listeners
    useEffect(() => {
        document.addEventListener('copy', handleEvent);
        document.addEventListener('cut', handleEvent);
        document.addEventListener('paste', handleEvent);
        document.addEventListener('keyup', handleEvent);
        window.addEventListener('focus', handleEvent);
        window.addEventListener('blur', handleEvent);

        return () => {
            document.removeEventListener('copy', handleEvent);
            document.removeEventListener('cut', handleEvent);
            document.removeEventListener('paste', handleEvent);
            document.removeEventListener('keyup', handleEvent);
            window.removeEventListener('focus', handleEvent);
            window.removeEventListener('blur', handleEvent);
        };
    }, [challenge, handleEvent]);

    // Send recorded envents
    useEffect(() => {
        const time = parseInt(process.env.REACT_APP_EVENT_INTERVAL, 10);
        const interval = setInterval(() => {
            if (events.current.length > 0) {
                recordMutation.mutate();
            }
        }, time);

        return () => {
            clearInterval(interval);
            if (events.current.length > 0) {
                recordMutation.mutate();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competition, challenge]);

    /**
     * Send to competition list on finish time
     */
    const handleFinish = () => {
        navigate('/competitions/private');
    };

    // Handle session
    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    // Finished competition
    if (competitionQuery.error?.status === 403) {
        createFeedback('error', i18next.t('competition-is-over'), true);
        return <Navigate to="/competitions/public" />;
    }

    // Build navbar routes
    const routes = [
        {
            link: `/competitions/${visibility}`,
            text: i18next.t('competitions'),
        },
        {
            link: `/competitions/${visibility}`,
            text: i18next.t(visibility),
        },
        {
            isLoading: competitionQuery.isLoading,
            link: `/competitions/${visibility}/${competition}`,
            text: competitionQuery.data?.name || '',
        },
        {
            link: `/competitions/${visibility}/${competition}`,
            text: i18next.t('challenges'),
        },
        {
            isLoading: challengeQuery.isLoading,
            text: challengeQuery.data?.name || '',
        },
    ];

    return (
        <NavbarTemplate routes={routes} screen>
            <div className="w-full flex flex-row sm:gap-10 gap-5">
                <div className="xl:w-2/3 w-full">
                    <Suspense fallback={<div>Loading Challenge...</div>}>
                        <Challenge />
                    </Suspense>
                </div>
                <div className="w-1/3 xl:flex hidden flex-col gap-10">
                    {competitionQuery.data?.timeRemaining && (
                        <div className="mx-auto">
                            <Countdown
                                size="lg"
                                time={competitionQuery.data.timeRemaining}
                                autoStart
                                onFinish={handleFinish}
                            />
                        </div>
                    )}

                    <Challenges compact />
                </div>

                {/* <div className="w-1/3 xl:flex hidden flex-col gap-10">
                    {competitionQuery.data?.timeRemaining && (
                        <div className="mx-auto">
                            <Countdown
                                size="lg"
                                time={competitionQuery.data.timeRemaining}
                                autoStart
                                onFinish={handleFinish}
                            />
                        </div>
                    )}

                    <Challenges compact />
                </div> */}
            </div>
        </NavbarTemplate>
    );
}

export default ChallengePage;
