/**
 * getDomainSettings retrieves the domain settings for a given subdomain.
 *
 *  @param {string} language - The language for localization.
 *  @returns {Promise<{
 *    primaryColor: string,
 *    secondaryColor: string,
 *    logo: string,
 *    banner: string,
 *    [key: string]: any
 * }>}
 */
export default async function getDomainSettings(language) {
  try {
    const url = `${process.env.REACT_APP_APP_URL}/api/v1/domains/settings`;
    const method = 'GET';
    const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': language,
    };

    const response = await fetch(url, { method, headers });
    const data = await response.json();

    if (!response.ok) {
        const errorData = { controlled: true, data };
        throw errorData;
    }

    return data;
  } catch (error) {
    if (error.controlled) {
        throw error.data;
    }

    const errorData = { status: 500, messages: [error.message], context: 'error' };
    throw errorData;
  }
}