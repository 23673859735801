import { useState } from 'react';
import Toast from '../components/atoms/Toast';

/**
 * useToast hook provides functionality for showing and hiding toasts
 * @returns {{
 *      toastElement: JSX.Element;
 *      createToast: (message: string, type: 'success' | 'error' | 'info' | 'warning', duration?: number) => void;
 * }}
 */
function useToast() {
    const [toastData, setToastData] = useState(null);

    const createToast = (type, message, duration = 5000) => {
        setToastData({ message, type, duration });
    };

    const toastElement = toastData ? (
        <Toast
            message={toastData.message}
            type={toastData.type}
            duration={toastData.duration}
            onClose={() => setToastData(null)}
        />
    ) : null;

    return { toastElement, createToast };
}

export default useToast;