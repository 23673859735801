import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState } from 'react';

/**
 * AlertBox atom creates a customizable alert box
 * @param {{
 *      children: React.ReactNode;
 *      className?: string;
 *      dismissible?: boolean;
 *      color?: 'blue' | 'green' | 'orange' | 'red' | 'yellow' | 'purple' | 'gray';
 * }} props
 */
function AlertBox({ children, className, dismissible = true, color = 'blue' }) {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;

    const colorClasses = {
        blue: 'bg-smblue/30 border-smblue-dark text-smblue-darker',
        green: 'bg-smgreen/30 border-smgreen-dark text-smgreen-darker',
        orange: 'bg-smorange/30 border-smorange-dark text-smorange-darker',
        red: 'bg-smred/30 border-smred-dark text-smred-darker',
        yellow: 'bg-smyellow/30 border-smyellow-dark text-smyellow-darker',
        purple: 'bg-smpurple/30 border-smpurple-dark text-smpurple-darker',
        gray: 'bg-smgray/30 border-smgray-dark text-smgray-darker',
    };

    return (
        <div
            className={classNames(
                'flex items-start p-4 border-l-4 rounded-md shadow-md',
                colorClasses[color],
                className
            )}
        >
            <div className="flex-1">{children}</div>
            {dismissible && (
                <button
                    type="button"
                    onClick={() => setIsVisible(false)}
                    className="ml-4 text-sm font-bold hover:underline text-current"
                >
                    ×
                </button>
            )}
        </div>
    );
}

AlertBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    dismissible: PropTypes.bool,
    color: PropTypes.oneOf(['blue', 'green', 'orange', 'red', 'yellow', 'purple', 'gray']),
};

AlertBox.defaultProps = {
    className: '',
    dismissible: true,
    color: 'blue',
};

export default AlertBox;